import React, { useEffect, useState } from 'react'
import Header from "../../layout/Header"
import Footer from "../../layout/Footer"
import Slidar from "../../components/Slider"
import "../payment/payment.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import baseUrl from '../../../baseurl/BaseUrl';
import { useSelector } from 'react-redux';
import { Loader } from "react-overlay-loader";
import StripeCheckout from 'react-stripe-checkout';
import { SliderData } from '../../utils/sliderData'
import { updateForm, AmountData } from '../../../redux/actions/counterActions';
import { useDispatch } from 'react-redux';
import { height } from '@mui/system'
// import { SliderData } from '../../utils/sliderData';

export default function CustomerPayment() {
    const location = useLocation();
    const [services, setservices] = useState([]);
    const [Ids, setIds] = useState("");
    const [item, setitem] = useState([])

    //   const Con = (amount) => {
    //     return Number(amount).toFixed(2)
    //   }

    //   const Consubamount = (amount) => {
    //     return Number(amount).toFixed(2)
    //   }
    const Token = localStorage.getItem("Token")
    const [loader, setLoader] = useState(false);
    const store = useSelector(store => store)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    // const reduxFormData1 = store.counter.form.state
    const reduxFormData2 = store.counter.form
    console.log("form data", store.counter)

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('reservationid');
        console.log("myparam", myParam)
        setIds(myParam)

    }, [])


    const onToken = (token) => {
        PaymentExecute(token.id,)
    }


    const handlePayPal = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append("amount", "0");
        formData.append("return_url", `${window.location.origin}/PaymentSuccess?id=${Ids}`);
        formData.append("cancel_url", `${window.location.origin}/PaymentFailed`);
        formData.append("reservationid", Ids);

        var requestOptions = {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + Token
            },
            body: formData,
        };
        fetch(`${baseUrl.baseUrl}webapi/paypalPayCreation`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                // dispatch(updateForm({ ...reduxFormData2, method: "paypal" }))
                if (result.status === true) {
                    console.log("result paypal",result)

                    window.location.href = result.data
                } 
                // else if (result.message === "Unauthorized") {
                //     return window.location.href = "https://valet-userside.vercel.app/login"
                // } 
                else {

                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });
    }

    const paymentStripe = () => {
        let a = document.getElementById('paymentmethod')
        a.children[0].click()
    }

    const PaymentExecute = (token) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("tokenid", token);
        formData.append("reservationid", Ids);

        var requestOptions = {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + Token
            },
            body: formData,
        };
        fetch(`${baseUrl.baseUrl}webapi/stripePayExecute`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status === false) {
                    navigate("/PaymentFailed")

                } else {
                    Swal.fire({
                        title: "success",
                        text: result.message,
                        icon: "success",
                        confirmButtonColor: "#03bafe",
                    }).then(() => {
                        console.log("resultdata",result.data)
                        console.log("success payment")
                        navigate('/PaymentSuccess', { state: { data: result.data } })
                        // navigate('/PaymentSuccess', state{ itemId: 86,otherParam: 'anything you want here', });
                        // navigate("/PaymentSuccess")
                    })

                }
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });
    }



    const titleCase = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return (
        <div>
            {loader ? <Loader fullPage loading /> : null}
            <Header />
            <Slidar data={SliderData.home} />
            <section className="ftco-section">
                <div className="container mt-4">
                    <div className="row text-center mt-5">
                        <div className='col-md-12'>
                            <div className=" text-center heading-section ">
                                <h2 className="title">PAYMENT METHOD</h2>
                            </div>
                            <div className="row text-center mt-5">
                                <div className='col-md-12'>
                                    <article className="card">
                                        <div className="card-body">
                                            <div className='row'>

                                                <h4>Choose payment method below</h4>
                                                <div className='col-md-6' >
                                                    <article className="card hov">
                                                        <div className="card-body">
                                                            <div className="type" onClick={() => paymentStripe()}>
                                                                <div className="logo paystripe">
                                                                    <i className="fa fa-credit-card" />
                                                                </div>
                                                                <div className="text" >
                                                                    <p>Pay with Credit Card</p>
                                                                    <div id='paymentmethod' className='d-none'>
                                                                        <StripeCheckout
                                                                            token={onToken}
                                                                            stripeKey="pk_test_hWK9CCVSwmaNFT7tlIbxHo5s00GntIdMdu"
                                                                            name='payment'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </article>
                                                </div>
                                                <div className='col-md-6' >
                                                    <article className="card hov">
                                                        <div className="card-body">

                                                            <div className="type" onClick={()=>handlePayPal()} >
                                                                <div className="logo paypal">
                                                                    <i className="fa fa-paypal" />
                                                                </div>
                                                                <div className="text"  >
                                                                    <p>Pay with PayPal</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </article>

                                                </div>

                                            </div>

                                            {/* old card word */}
                                            {/* <div className="payment-type">
                    <h4>Choose payment method below</h4>
                    <div className="types flex " style={{ justifyContent: "space-evenly" }}>
                      <div className="type" onClick={() => paymentStripe()}>
                        <div className="logo">
                          <i className="fa fa-credit-card" />
                        </div>
                        <div className="text" >
                          <p>Pay with Credit Card</p>
                          <div id='paymentmethod' className='d-none'>
                            <StripeCheckout
                              token={onToken}
                              stripeKey="pk_test_hWK9CCVSwmaNFT7tlIbxHo5s00GntIdMdu"
                              name='payment'
                            />
                          </div>
                        </div>
                      </div>
                      <div className="type" onClick={() => handlePayPal()}>
                        <div className="logo">
                          <i className="fa fa-paypal" />
                        </div>
                        <div className="text"  >
                          <p>Pay with PayPal</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                                        </div>
                                    </article>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}
