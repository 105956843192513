import React, { useEffect, useState } from 'react'
// import Header2 from "../layout/Header2";
import Header3 from "../layout/Header3";
import { Button } from 'react-bootstrap'
// import Slider2 from '../components/slider2'
import Slider3 from '../components/slider3'
import RatingDef2 from "../components/Rating2";
import img4 from "./carright.png"
import img5 from "./booktransfer.png"
import "./card.css"
import newlogo from "../valetuser/newlogo.png"
import google from "../valetuser/google.png"
import howitwline22 from './Line22.png'

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BiPhoneCall, BiMessageDetail } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";
import { BiMap } from "react-icons/bi";
import { FiMapPin } from "react-icons/fi";
import { useNavigate, NavLink, Link } from 'react-router-dom';

import './Home2css.css'
import ServicelogSlider from '../components/ServicelogSlider';
import Review from '../components/Review';
import Swal from "sweetalert2";
import baseUrl from '../../baseurl/BaseUrl'
import 'swiper/swiper-bundle.min.css'
// swiper core styles
import 'swiper/swiper.min.css'
import "../components/TestonmialSlider.css";
import RatingNewtab from '../components/RatingNewtab';

// 
const Home2 = () => {

    const navigate = useNavigate()

    const Token = localStorage.getItem("Token")
    const Guestlogin = localStorage.getItem("guest")
    console.log("Guestlogin", Guestlogin)


    const dateObj = new Date()
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate
    if (month < 10) {

        if (day < 10) {

            newdate = year + "-" + 0 + month + "-" + 0 + day;

        } else {
            newdate = year + "-" + 0 + month + "-" + day;
        }

    } else {
        if (day < 10) {
            newdate = year + "-" + month + "-" + 0 + day;

        } else {
            newdate = year + "-" + month + "-" + day;
        }
    }



    const [startdate, setstartdate] = useState(newdate);
    const [enddate, setenddate] = useState(newdate);
    const [data, setData] = useState([])
    const [service, setservice] = useState([])
    const [serv, setServ] = useState("")
    const [loader, setLoader] = useState(false);


    useEffect(() => {

        GetServices()

    }, [])


    const GetServices = () => {
        // setLoader(true);
        var requestOptions = {
            method: 'GET',
            // headers: {
            //     Authorization: "Bearer " + Token
            // }
        };
        setLoader(true)
        fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=superadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("service creation", result)
                    setData(result.category)
                    setservice(result.services)

                } else {
                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }
            }).catch(error => {
                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });
    }


    const Paasingdata = () => {
        navigate('/Reserve?status=true', { state: { service: serv, startdate: startdate, enddate: enddate } });
        // {navigate("/Reserve"),{state:{service:serv,startdate:startdate,enddate:enddate}}}}
    }

    const handleDateChange = (date) => {
        // Handle the date change logic here
        console.log(date);
    };
    const [data2, setData2] = useState([])
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [contact, setContact] = useState("")
    const [profile, setProfile] = useState("")

    const Navigate = useNavigate()

    useEffect(() => {

        GetServices2()
        upfunction()

    }, [])





    const GetServices2 = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=customer`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("result in home3", result)
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("result.data", result.data)

                    setData2(result.data)
                    setFname(result.data.fname)
                    setLname(result.data.lname)
                    setContact(result.data.contact)
                    setEmail(result.data.email)
                    setProfile(result.data.profile)
                    setAddress(result.data.address)



                }
                // else {
                //     setLoader(false)
                //     Swal.fire({
                //         title: "opps",
                //         text: result.message,
                //         icon: "error"
                //     })



                // }
            }).catch(function (e) {
                setLoader(false)
                Swal.fire({
                    title: "opps!",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    const testinomialarray = [
        {
            name: "Clark kent",
            Review: "It was a great Experience!",
            description: "I have used your service for some years and always found it reliable and reasonable.Recently, two of your employees did a little extra for me.  When I was brought home on Sat., Jan. 20, a book slipped out of a side pocket of my bag and was left in the trunk.  When I realized what must have happened, I phoned the dispatcher on Monday morning.  Within an hour, she called to say that the driver had the book and that he would return it when in the area.I had it later in the day.Thanks to both people for their help."

        },
        {
            name: "Mark denr",
            Review: "Always Found It Reliable And Reasonable",
            description: "I have trusted Airflight Services on several occasions over the past 7 years to get me to the airport with no hitches or worries on my end. I do not travel a lot, but I do recognize and appreciate a good transportation service when I find it. Taxi provides excellent service with friendly interactions – and their fleet appears to be regularly maintained, which is something that I look for in public transportation. Thank you for providing this much-needed service!"

        },
        {
            name: "Drake tren",
            Review: "I Find Their Service High Class",
            description: "As a person who likes to travel a lot, I have used many taxi companies and other community services but, unlike any other, Airflight has by far been the best. When the car arrived in front of my doorstep, I would expect a regular car that one might find on a common cab, but I ended up with a nice Lincoln SUV."

        }
    ]

    const [counter, setcounter] = useState(-1)

    const [desc, setdesc] = useState()
    const [review, setreview] = useState()



    const upfunction = () => {


        if (counter < testinomialarray.length - 1) {
            setcounter(counter + 1)
            const incremntdata = testinomialarray.filter((a, index) => (counter + 1) == index)


            setdesc(incremntdata[0].description)
            setreview(incremntdata[0].Review)



        }


    }

    const downfunction = () => {

        if (counter > 0) {

            setcounter(counter - 1)
            const incremntdata = testinomialarray.filter((a, index) => (counter - 1) == index)

            console.log("decrement", incremntdata)

            setdesc(incremntdata[0].description)
            setreview(incremntdata[0].Review)


            console.log("RIGHT")

        }




        // if(counter )
        // { 
        //     setcounter(counter - 1 )
        //     const incremntdata =testinomialarray.filter((a,index)=>counter == index )

        //     console.log("decrement",incremntdata)

        //     setdesc(incremntdata[0].description)
        //     setreview(incremntdata[0].Review)


        //     console.log("RIGHT")
        // }

    }






    return (
        <>
            {/* start */}

            {
                Token == null && Guestlogin == null ?

                    <div className='bluediv'  >
                        <div className="bs-example px-3 py-3">



                            <div className="clearfix fss">
                                <Button onClick={() => navigate("/Register")} className='  float-right ml-2 ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Register Now
                                </Button>{' '}
                                <Button onClick={() => navigate("/Login")} className='float-right ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Sign In
                                </Button>{' '}
                            </div>


                        </div>
                    </div>

                    :
                    Token == null && Guestlogin == "guest" ?

                        <div className='bluediv' style={{ height: "64px" }} >
                            <div className="bs-example px-3 py-3">

                                <div className="clearfix fss">

                                    <h3 className='float-right  mx-5 ' >
                                        {/* <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" /> */}
                                        {/* <img src={profile ? profile: '/assets/images/person_1.jpg'} className='img-fluid' width="60%" style={{ border: "5px solid rgb(3 156 213)" }} alt={""}/> */}

                                    </h3>
                                    <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome as Guest</h3>




                                </div>


                            </div>
                        </div> :
                        <div className='bluediv' style={{ height: "64px" }} >
                            <div className="bs-example px-3 py-3">



                                <div className="clearfix fss">

                                    <h3 className='float-right  mx-5 ' >
                                        <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" />
                                        {/* <img src={profile ? profile: '/assets/images/person_1.jpg'} className='img-fluid' width="60%" style={{ border: "5px solid rgb(3 156 213)" }} alt={""}/> */}

                                    </h3>
                                    <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome {fname}</h3>




                                </div>


                            </div>
                        </div>

            }

            {/* end */}




            <Header3 />


            <Slider3 />



            {/* <div className='d-flex justify-content-center' >
                <div className='Rectangle1414 shadow-lg'>
                    <div className='row  mt-4' >

                        <div className='col-sm-3 rectanglecol-m-3 ' >
                            <div className="form-group selectss">
                                <label className='arrival ab' style={{ fontSize: "20px", padding: "0px 0px 0px 70px" }} htmlFor="">Select</label>
                                <div className='' style={{ display: "flex" }}>

                                    <i className=' icoon' style={{ padding: "0px 10px 0px 15px" }}><FiMapPin size={30} /></i>

                                    <select value={serv} onChange={(e) => setServ(e.target.value)} className='w-100 py-1 selects form-select' style={{ border: "none" }} name="" id="">
                                        <option selected>Select service...</option>
                                        {service.map((v, i) => {

                                            if (v.category_name != "Additional services") {
                                                console.log("service map if", v.id)


                                                return <option value={v.id} >{v.servicename}</option>
                                            }
                                        })}

                                    </select>





                                </div>
                            </div>
                        </div>

                        <div className='col-sm-3 rectanglecol-m-3  ' >

                            <div className="form-group date">
                                <label className='arrival bc' style={{ fontSize: "20px", marginBottom: 0, padding: "0px 0px 0px 55px" }} htmlFor="">Departure</label>
                                <div className='date' style={{ display: "flex" }}>
                                    <i style={{ fontSize: "27px", padding: "10px 10px 0px 10px" }} className='fa fa-calendar '></i>
                                    <input value={startdate}
                                        onChange={(e) => setstartdate(e.target.value)}
                                        type="date" className='selects form-control' />
                                    <span style={{ fontWeight: 700, color: "#959595", fontSize: "30px" }}>-</span>
                                </div>

                            </div>
                        </div>

                        <div className='col-sm-3 rectanglecol-m-3 ' >

                            <div className="form-group date"  >
                                <label className='arrival cd' style={{ fontSize: "20px", marginBottom: 0, padding: "0px 0px 0px 26px" }} htmlFor="">Arrival</label>
                                <div className='date cdc' style={{ margin: "0px 14px 0px 14px" }}>
                                    <input value={enddate}
                                        onChange={(e) => setenddate(e.target.value)}
                                        type="date" className='selects form-control' />
                                </div>
                            </div>
                        </div>


                        <div className='col-sm-3 rectanglecol-m-3 mt-1' >
                            <Button disabled={serv == ""} onClick={() => Paasingdata()} className='bookbtn text-white' size="lg">
                                Book Now
                            </Button>{' '}

                        </div>

                    </div>

                </div>
            </div> */}




            {/* we meet section */}
            <div style={{ background: "#1E1E1E;" }} className="">
                <div className=''  >

                    {/* 1st section  */}
                    <div className=' mt-5' >
                        <div className='row'>
                            <div className='col-md-1' ></div>
                            <div className='col-md-5 mt-4' >

                                <p className='mt-2 wemeeth3 text-center' style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "26px", lineHeight: "34px", letterSpacing: "0.5rem" }}>100% CONTACTLESS <br /> VALET PARKING</p>
                                {/* <h3 className='mt-2 wemeeth3'> VALET PARKING</h3> */}
                                <h1 className='mt-2 wemeeth1'>LAX Curbside Terminal Pick Up</h1>
                                <p className='mt-4 wemeetp'>Looking for hassle-free airport parking? Look no further than LAX Airport Valet Services! Our curbside terminal pick-up service is just what you need to start your travels off on the right foot. For just $39.95, our expert team will wait for you at the curb, ready to take your vehicle to our secure parking facility. And when you return, we'll have your car waiting for you, freshly washed and ready to hit the road. So why stress about parking when you can let LAX Airport Valet Services take care of it for you? Book now and experience the convenience and peace of mind that come with valet parking.

                                </p>





                            </div>
                            <div className='col-md-5 offset-md-1 text-end'  >
                                <div className='' >
                                    <img src={img4} className="img-fluid carimg" style={{ width: "70%" }} />
                                </div>


                            </div>
                        </div>
                        {/* 1st line  */}
                        <div className='row d-flex justify-content-center'>
                            {/* <div className='col-md-2 ' ></div> */}
                            <div className='col-6 mt-2'  >
                                <img src={howitwline22} className="img-fluid " style={{ width: "100%" }} />
                            </div>

                            {/* <div className='col-md-2' ></div> */}
                        </div>

                        {/* 2nd section  */}
                        <div className='row reversecol'>
                            <div className='col-md-5  mt-4'  >
                                <div>
                                    <img src={img5} className="img-fluid carimg" style={{ width: "70%" }} />
                                </div>


                            </div>
                            <div className='col-md-6 mt-4 text-end' >

                                <h3 className='mt-2 wemeeth3 text-center' style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "26px", lineHeight: "34px", letterSpacing: "0.5rem" }} >100% CONTACTLESS <br /> VALET PARKING</h3>
                                {/* <h3 className='mt-2 wemeeth3 text-end' style={{ color: "#4A4A4A" }} > VALET PARKING</h3> */}
                                <h1 className='mt-4 wemeeth1 '>LAX Curbside Office Pick Up</h1>
                                <p className='mt-4 wemeetp text-end' >Looking for hassle-free airport parking? Look no further than LAX Airport Valet Services! Our curbside terminal pick-up service is just what you need to start your travels off on the right foot. For just $39.95, our expert team will wait for you at the curb, ready to take your vehicle to our secure parking facility. And when you return, we'll have your car waiting for you, freshly washed and ready to hit the road. So why stress about parking when you can let LAX Airport Valet Services take care of it for you? Book now and experience the convenience and peace of mind that come with valet parking.

                                </p>




                            </div>
                            <div className='col-md-1' ></div>


                        </div>

                        {/* 3nd section  */}
                        <div className='row'>
                            <div className='col-md-1' ></div>

                            <div className='col-md-10'>
                                <div className='row'>
                                    <div className='col-md-6'>

                                        <h3 className='mt-2 wemeeth3 text-center' style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "26px", lineHeight: "34px", letterSpacing: "0.5rem" }}>EXPERIENCE THE LUXURY <br /> OF CONVENIENCE WITH</h3>
                                        {/* <h3 className=' wemeeth3 ' style={{ color: "#4A4A4A" }} > </h3> */}
                                        <h1 className='mt-2 wemeeth1 '>LAX Airport Valet Parking Services.</h1>
                                        {/* <h1 className=' wemeeth1 '> Valet Parking</h1>
                                        <h1 className=' wemeeth1 '> Services.</h1> */}

                                    </div>
                                </div>


                                <p className='mt-2 wemeetp '>LAX Airport Valet is dedicated to providing top-notch airport valet parking services to enhance the customer experience. Our professional and courteous valet attendants ensure that each customer's vehicle is parked safely and securely in a designated area. In contrast, customers can enjoy their time without the stress of finding a parking spot. At LAX, expediency is the ultimate luxury, and we strive to provide our customers with unparalleled ease through our valet parking services. Whether you are dining at a restaurant, attending a special event, or staying at a hotel, LAX airport valet parking services will provide you with a seamless and hassle-free parking experience. Trust LAX to take care of your airport parking needs and elevate your experience to the next level.


                                </p>

                            </div>


                        </div>
                        <div className='row d-flex justify-content-center'>
                            {/* <div className='col-md-2' ></div> */}

                            <div className='col-6 mt-2'  >
                                <img src={howitwline22} className="img-fluid " style={{ width: "100%" }} />

                            </div>

                            {/* <div className='col-md-2' ></div> */}
                        </div>




                        {/* how it work part start  */}
                        <div className='row'>
                            <div className='col-md-1 ' ></div>
                            <div className='col-md-10  mt-4 mb-10 howitworkcol'   >
                                <p className='hereitwork' >Here Is How <br /> It Works.</p>
                            </div>
                            <div className='col-md-1 ' ></div>
                        </div>

                        {/*drop-off-procedure  */}
                        <div className='row'>
                            <div className='col-md-1 ' ></div>
                            <div class="col-md-10 mt-4 " >
                                <p class="reservation2 " style={{ lineHeight: "32px" }}>DROP-OFF PROCEDURE </p>
                            </div>
                            <div className='col-md-1 ' ></div>

                        </div>


                        {/* drop off  */}


                        <div className="container-fluid serviceBox1"  >
                            <div className="row">
                                {/* <div className='col-md-1 howitworkcol' ></div> */}

                                <div class="col-md-4 col-sm-6">
                                    <div class="serviceBox">

                                        <div class="service-icon">

                                            <span className="steps">STEP 1</span>
                                        </div>
                                        <div className=''>
                                            <h3 class="title text-start">Greet And Check-In Vehicle</h3>
                                            <p class="description text-start">Introduce yourself and explain the process Inspect the vehicle for damage and document it Provide the customer with a claim check</p>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="serviceBox ">
                                        <div class="service-icon">
                                            <span className="steps">STEP 2</span>
                                        </div>
                                        <h3 class="title text-start">Park The Vehicle</h3>
                                        <p class="description text-start">Carefully park your vehicle in the designated area Ensure It’ 's positioned safely and not blocking others</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="serviceBox ">
                                        <div class="service-icon">
                                            <span className="steps">STEP 3</span>
                                        </div>
                                        <h3 class="title text-start">Retrieve The Vehicle And Check-Out</h3>
                                        <p class="description text-start">Introduce yourself and explain the process Inspect the vehicle for damage and document it Provide the customer with a claim check</p>
                                    </div>
                                </div>
                                {/* <div className='col-md-1 howitworkcol' ></div> */}

                            </div>
                        </div>

                        {/* pick up  */}


                        <div className='row'>
                            <div className='col-md-1 howitworkcol' ></div>
                            <div class="col-md-10  " >
                                <p class="reservation2 " style={{ marginTop: "6%", lineHeight: "32px" }}>PICK UP PROCEDURE </p>
                            </div>
                            <div className='col-md-1 howitworkcol' ></div>

                        </div>

                        <div className="container-fluid serviceBox1" >
                            <div class="row">
                                {/* <div className='col-md-1 howitworkcol' ></div> */}

                                <div class="col-md-4 col-sm-6">
                                    <div class="serviceBox">
                                        <div class="service-icon">
                                            <span className="steps">STEP 1</span>
                                        </div>
                                        <h3 class="title text-start">Customer Requests Pick-Up</h3>
                                        <p class="description text-start">The customer presents a claim check Requests pick-up of the vehicle</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="serviceBox ">
                                        <div class="service-icon">
                                            <span className="steps">STEP 2</span>
                                        </div>
                                        <h3 class="title text-start">Retrieve and Inspect the Vehicle</h3>
                                        <p class="description text-start">The valet retrieves the vehicle from the designated parking area Inspects the vehicle for any new damage</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="serviceBox ">
                                        <div class="service-icon">
                                            <span className="steps">STEP 3</span>
                                        </div>
                                        <h3 class="title text-start">Return the Vehicle to the Customer</h3>
                                        <p class="description text-start">The valet returns the vehicle to the customer Thanks to them for their business and wishes them well.</p>
                                    </div>
                                </div>
                                {/* <div className='col-md-1 howitworkcol' ></div> */}

                            </div>
                        </div>



                        {/* blue line */}
                        <div className='row d-flex justify-content-center'>
                            {/* <div className='col-md-2' ></div> */}

                            <div className='col-6' style={{ marginTop: "5%" }} >
                                <img src={howitwline22} className="img-fluid " style={{ width: "100%" }} />

                            </div>

                            {/* <div className='col-md-2' ></div> */}
                        </div>

                        {/*  */}

                    </div>



                    {/* 2nd section  */}
                    {/* servics log */}
                    <div className=' serviclog' style={{ margin: "20px" }}>


                        <div className='row' style={{ marginTop: "5%" }}>
                            {/* <div className='col-md-1' ></div> */}
                            <div className='col-md-12 '>

                                <ServicelogSlider />
                            </div>
                            {/* <div className='col-md-1' ></div> */}
                        </div>
                    </div>
                    {/* Reversation */}


                    <RatingDef2 />





                    {/* pricing */}

                    <div className='pricingsection  py-5' id="reviews" style={{ margin: "20px" }}>

                        <section className="ftco-section2" style={{ padding: "6em 0" }} >



                            <div className='row  ' >
                                <div className='col-md-4 text-center' >
                                    <p className='rateheading' >Parking <br />Rates</p>
                                </div>
                                <div className='col-md-4' >
                                    <p className='rateprice'>$39.95<spna className="day">/Day</spna></p>
                                    {/* <p>LAX Valet Service Offers Valet Parking <br /> Directly From Your Terminal. Our Personal <br /> Valet Driver Will Meet You Curbside At LAX. */}

                                    <p className='ratedescription'>Whether you book your parking service in person or online, our flexible services come at the same affordable rate. So why wait? Book with us today and enjoy hassle-free airport parking!</p>
                                </div>
                                <div className='col-md-4' >
                                    {/* <h5>Premium Curbside <br /> Valet Parking Service
                                    </h5> */}
                                    <p className='servicename'>Curbside Valet Parking <br />Service
                                    </p>
                                    <div className='py-3 btnlst' >


                                        <Button onClick={() => navigate("/Reserve")} variant="" className='lastbtn' size="lg">
                                            Book Now
                                        </Button>
                                    </div>

                                </div>

                            </div>

                            {/* <hr className='hrclass my-4' /> */}




                        </section>

                    </div>

                    {/* testinomial */}
                    <div className='testinomial py-5 ' >
                        <div className='row' >

                            <div className='col-md-6 testypad testypadd' >
                                {/* <h5 >Testimonials</h5> */}
                                <p className='mt-2 wemeeth3 testinomialh ' >TESTIMONIALS</p>

                                {/* <h5 >Testimonials</h5> */}
                                <h1 className='star5' >5 Star Valet <br />  Service</h1>

                            </div>


                            <RatingNewtab />

                        </div >



                    </div >



                    < Review />



                    <div style={{ width: "100%" }}>
                        <iframe style={{ width: "100%", height: "300px", frameborder: "0" }} src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=8921%20south%20Sepulveda%20blvd,%20Los%20Angeles,California%2090045+(valet%20services)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe>
                    </div>


                    <div className=' ' style={{ backgroundColor: "#1F1D1D", boxShadow: "0 -5px 5px -5px #333" }}>

                        <section className="ftco-section2" style={{ padding: "3em 0" }} >


                            {/* <ServicelogSlider /> */}
                            <div className='row ml mb-5 d-flex justify-content-around' >
                                <div className='col-md-2' >
                                    {/* <h1 className='mb-5' style={{ color: "#9F9F9F" }} >Car Valet</h1> */}

                                    <h1 className="mb-5" >
                                        <Link to="https://www.laxairportvaletservices.com/" className="mb-5">
                                            <img src={newlogo} className="img-fluid" width="40%" height="120px" alt={""} />
                                            {/* <img src="./assets/images/Airport Valet-02.png" className="img-fluid" width="40%" height="150px" alt={""} /> */}
                                        </Link>
                                    </h1>
                                    <img src={google} className="img-fluid" width="40%" height="100px" alt={""} />


                                    {/* <AiOutlineTwitter className='mx-2' style={{ fontSize: "20px", background: "#828282", }} /> */}

                                </div>
                                <div className='col-md-2' >

                                    <ul>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/" className="footerli" > Home </NavLink> </li>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/Reserve" className="footerli" > Reservations </NavLink> </li>
                                        {Token != null ?   <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="UserData" className="footerli" > History </NavLink> </li>:""}
                                        {/* <li className='py-2' style={{ color: "#A5A5A5" }} >Reservations</li>
                                        <li className='py-2' style={{ color: "#A5A5A5" }} >History</li> */}
                                    </ul>
                                </div>
                                <div className='col-md-2' >

                                    <ul>
                                        {/* <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} >
                                            <a href='#reviews' className='footerli' >Reviews</a>
                                        </li> */}
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/contact" className="footerli" > Contact </NavLink> </li>
                                        {Token != null ? <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/Profile" className="footerli" > Profile </NavLink> </li> : ""}

                                        <a href='https://www.facebook.com/people/Airport-Valet-of-LAX/100087052763482/' target={"_blank"}><FaFacebookF className='mx-1' style={{ fontSize: "20px", color: "white" }} /></a>

                                        <a href='https://www.instagram.com/laxairportvaletservices/' target={"_blank"}><FaInstagram className='mx-1' style={{ fontSize: "20px", color: "white" }} /></a>
                                        {/* <li className='py-2' style={{ color: "#A5A5A5" }} >Contact</li>
                                        <li className='py-2' style={{ color: "#A5A5A5" }} >Blogs</li> */}
                                    </ul>
                                </div>
                                <div className='col-md-5' >
                                    <ul>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiMap size={25} />  LAX Airport Valet Services 8923 South Sepulveda<br className='mx-3' /> Blvd LA, CA 90045</li>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiPhoneCall size={25} /><a href='tel:+1 (310 800-1075)' style={{ color: "white" }}>(310) 216-6110.</a></li>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiPhoneCall size={25} /><a href='tel:+1 (800) 328-2538' style={{ color: "white" }}>(800) 328-2538.</a></li>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiMessageDetail size={25} /><a onClick={() => window.open("https://wa.me/13108001075")} href="" style={{ color: "white" }}>(310) 800-1075.</a></li>
                                        <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", fontFamily: "Poppins" }}>< MdOutlineMail size={25} /><a href='mailto:Contact@laxairportvaletservices.com' style={{ color: "white" }}> Contact@laxairportvaletservices.com </a></li>
                                    </ul>
                                </div>


                            </div>


                            <div className='row ml mt-5' >
                                <div className='col-md-7' >
                                    <p className="copyright"  >Copyright © 2023 LAX Airport valet Services - All Rights Reserved.

                                    </p>
                                </div>
                                {/* <div className='col-md-3' >
                                    <p style={{ color: "#A5A5A5" }}>created by <span style={{ color: "#32A9E0" }} >xtreme Technologies</span> </p>

                                </div> */}
                                <div className='col-md-4 ' >
                                    <p style={{ color: "#8C8C8C" }}>Created By <spna className="xtreme">xtreme Technologies</spna></p>

                                </div>



                            </div>

                        </section>


                    </div>




                </div >


            </div >

        </>

    )
}

export default Home2