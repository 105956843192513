import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


import { Button } from 'react-bootstrap';
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
// swiper core styles
import 'swiper/swiper.min.css'
import { useNavigate } from "react-router-dom";
// modules styles
// import 'swiper/components/navigation/navigation.min.css'
// import 'swiper/components/pagination/pagination.min.css'


// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";


// import required modules
import { Parallax, Pagination, Navigation, Autoplay } from "swiper";


import "./sliderstyle.css";

// import required modules
// import { EffectFade, Navigation, Pagination,Autoplay } from "swiper";


export default function SLider({data}) {

  const navigate = useNavigate();


  return (
    <>
      <section >
        <Swiper
      
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          // direction={"vertical"}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Parallax, Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >

          {data.map((e,i) => (
            <SwiperSlide key={i} className="swiper-fixed-width-100 ">
              <div
                slot="container-start"
                className="parallax-bg"
                style={{ backgroundImage: e.url}}
                data-swiper-parallax="-23%"
              >
                <div className="ahmed" style={{marginTop:"15%",marginLeft:"4%"}}>
                <div className="titlevalet" data-swiper-parallax="-300">
                Valet car can be experience
                </div>
                {/* <div className="subtitle" data-swiper-parallax="-200">
            Subtitle
          </div> */}
                <div className="text" style={{ maxWidth: "400px" }} data-swiper-parallax="-100">
                  <p>
                  
                  Find your best match
                  </p>
                  <Button onClick={()=>navigate("/Reserve")} className="mr-4" variant="outline-info">Reserve Your Parking Now</Button>
                  {/* <Button style={{ backgroundCOlor: "rgba(3, 156, 213)" }}>Track My Car</Button> */}
                </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* <button type="button" className="btn m-5 btn-primary" data-toggle="modal" data-target="#exampleModalRight">
    Right Side Drawer 
  </button> */}
        </Swiper>
        
        


        
      </section>
    </>
  );


}


