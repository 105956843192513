import { auth, FORM, AMOUNT } from '../actions/counterActions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'counter',
    storage: storage,
};

const counterReducer = (state = { form: {}, amount: {} }, action) => {
    switch (action.type) {
        case FORM:
            return { ...state, form: action.payload };
        case AMOUNT:
            return {
                ...state,
                amount: action.payload

            };
        case auth:
            // console.log("auth is call in reducer", action.payload, "type", action.type)
            return { ...state, obj: action.payload };
        default:
            return { ...state };
    }
};

export default persistReducer(persistConfig, counterReducer);


