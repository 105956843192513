import React, { useEffect, useState } from "react";
import RightSidbar from "../components/RightSidbar2";
import { Link, useLocation } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Headercss3.css";
// import "./sliderstyle.css";
import newlogo from "../valetuser/newlogo.png"

export default function Header2() {
    const [ShowHide, setShowHide] = useState("sticky-top")

    const [bg1, setbg1] = useState("bg-dark1")
    const [widthlogo, setWidthlogo] = useState("-65px")
    const [logosize, setLogosize] = useState("80%")
    const [Activeclass, setActiveclass] = useState('')

    const location = useLocation()
    const { pathname } = location
    console.log("widthlogo", widthlogo, pathname, Activeclass)

    console.log("pathname==>", pathname)

    useEffect(() => {
        if (pathname == "/Payment" || pathname == "/ReservationForm") {
            setWidthlogo("0px")

        }
        setActiveclass(pathname)

        window.scroll(0, 0)

    }, [])

    const Token = localStorage.getItem("Token")
    const Guestlogin = localStorage.getItem("guest")
    console.log("Token", Token)
    window.addEventListener("scroll", (event) => {
        let scroll = window.pageYOffset
        if (scroll > 200) {
            console.log("scroll", scroll)
            setShowHide("fixed-top")
            setbg1("bg-dark2")
            setWidthlogo("0px")
            setLogosize("50%")

        }
        else {
            if (pathname == "/Payment" || pathname == "/ReservationForm") {
                setWidthlogo("0px")

            } else {

                setWidthlogo("-65px")
            }
            setShowHide("sticky-top")
            setbg1("bg-dark1")
            setLogosize("80%")



        }
        // console.log("scroll", scroll)
    });
    window.addEventListener("resize", (event) => {
        // console.log("window",window.innerWidth)
        if (window.innerWidth <= 1200) {
            // setTop("-67px")
        } else {
            // setTop("0px")
        }
    })

    const [ab, setab] = useState(true)


    const closefunction = () => {
        setab(false)
    }

    const logout = () => {
        localStorage.clear()
    }


    return (
        <>
            {/* {
            ab ? <div class="alert  bg-danger alert-dismissible" style={{ marginBottom: "0px", color: "#FFFFFF", textAlign: "center", fontWeight: "700" }}>
            <button type="button" onClick={closefunction} class="close" data-dismiss="alert">&times;</button>
            <Button className="close" onClick={ahmed} >x</Button>
            Call 15 minutes before arrival at your departing terminal.
            <AiOutlineClose className="close" style={{color:"#000"}} onClick={ahmed} />
        </div>
        : ""

        } */}

            <section className="" style={{ position: "absolute", width: "100%" }}>

                <div className="container-fluid">


                    <div className="row g-0" >
                        {/* <div className={window.innerWidth == 768 ? "col-xl-5" : "col-md-5" }> */}
                        {/* "navbar navbar-expand-lg navbar-dark bg-dark  sticky-top" */}
                        <div className="col-lg-12" >
                            <nav className={`navbar navbar-expand-lg navbar-dark py-0 ${bg1} ${ShowHide}`}   >
                                <div className="row widthclass" style={{ marginTop: widthlogo }} >
                                    <div className="col-md-12">
                                        <Link to="/" className="navbar-brand">
                                            <img src={newlogo} className="img-fluid sliderlogo" style={{ width: logosize }} alt={""} />
                                        </Link>


                                    </div>
                                </div>
                                {/* <Link to="/" className="navbar-brand "><img src={newlogo} className="img-fluid sliderlogo" width="70%" height="100px" alt={""} /></Link>         */}
                                {/* <a className="navbar-brand" href="#">Navbar</a> */}

                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" />
                                </button>

                                <div className="collapse navbar-collapse justify-content-around" id="navbarSupportedContent">
                                    <ul className="navbar-nav  ">
                                        <li className="mx-4">
                                            <Link to="/" className={Activeclass == "/" ? " saf nav-link " : "nav-link "}>Home</Link>
                                        </li>
                                        <li className=" mx-4"><Link to="/Reserve" className={Activeclass == "/Reserve" ? " saf nav-link " : "nav-link "}>Reservations</Link></li>
                                        {Token != null ?
                                            <li className=" mx-4"><Link to="/UserData" className={Activeclass == "/UserData" ? " saf nav-link " : "nav-link "}>History</Link></li>
                                            : ""}

                                        {Token != null ?
                                            <li className=" mx-4"><Link to="/Profile" className={Activeclass == "/Profile" ? " saf nav-link " : "nav-link "}>Profile</Link></li>
                                            : ""}
                                        {/* <li className=" mx-3 active">
                                            <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                                        </li>
                                        <li className=" mx-3">
                                            <a className="nav-link" href="#">Link</a>
                                        </li> */}
                                        {/* <li className=" mx-3 dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Dropdown
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="dropdown-item" href="#">Action</a>
                                                <a className="dropdown-item" href="#">Another action</a>
                                                <div className="dropdown-divider" />
                                                <a className="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </li> */}
                                        <li className=" mx-4">
                                            <Link to="/contact" className={Activeclass == "/contact" ? " saf nav-link " : "nav-link "}>Contact Us</Link>
                                        </li>
                                        {Token != null || Guestlogin == "guest" ?
                                            <li className=" mx-4">

                                                <Link className={Activeclass == "/contact" ? " saf nav-link " : "nav-link "} to="/Login" onClick={() => logout()}>Logout</Link>
                                            </li> : ""}
                                        {/* <li className=" mx-3">
                                            <a className="nav-link disabled" href="#">Disabled</a>
                                        </li> */}
                                        {/* <li className="">
                                     <li className="nav-link mt-2"><i className="fa fa-bars nav-link" data-toggle="modal" data-target="#exampleModalRight"></i></li>
                                        </li> */}
                                    </ul>
                                    {/* <i className="fa fa-bars nav-link" data-toggle="modal" data-target="#exampleModalRight"></i> */}
                                    <i>
                                        <div class="dropdown">
                                            {/* <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" >
                                            <i class="fa fa-bars nav-link"></i>
                                        </a> */}

                                            <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">

                                                {/* <div class="notification-heading"
                                            ><h4 class="menu-title">Notifications</h4>
                                            <h4 class="menu-title pull-right">View all
                                            <i class="glyphicon glyphicon-circle-arrow-right"></i></h4>
                                            </div> */}
                                                <li class="divider"></li>
                                                {/* <div class="notifications-wrapper">
                                                <div style={{ overFlowY: "scroll", marginTop: "30px" }}>
                                                    <div className="drawer">
                                                        <ul>
                                                            <li className="lis"><GiHamburgerMenu /></li>

                                                            {Token == null ? <li ><Link to="/Register" className="lis" >Registration</Link></li> : ""}
                                                            {Token == null ? <li ><Link className="lis" to="/Login">Login</Link></li> : ""}

                                                            {Token != null ? <li ><Link className="lis" to="/Profile">Profile</Link></li> : ""}
                                                        
                                                            {Token != null ? <li ><Link className="lis" to="/Login" onClick={() => logout()}>Logout</Link></li> : ""}


                                                        </ul>
                                                    </div>
                                                </div>

                                            </div> */}

                                            </ul>

                                        </div></i>
                                    {/* <form className="form-inline my-2 my-lg-0">
                                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                                        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                                    </form> */}
                                    {/* <li className=" ">
                                     
                                        <i className="fa fa-bars nav-link" data-toggle="modal" data-target="#exampleModalRight"></i>
                                        </li>
                                         */}


                                </div>
                            </nav>
                        </div>


                        <RightSidbar />
                    </div>
                </div>
            </section>

        </>
    )
}