import React,{useState} from 'react'
// import "../../../../src/App.css"
import "./time.css"
import TimePicker from 'react-time-picker';

export default function TimeSet() {
    const [value, setValue] = useState('10:00');
    
    const onChange = (time) => {
      console.log("time",time)
      setValue(time);
    };

  return (
    <div >

    {/* <h1>Time</h1> */}

    <div>
    <TimePicker  onChange={onChange} value={value} disableClock = {true}/>
  </div>
    {/* <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </header> */}
  </div>
  )
}
