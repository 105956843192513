import React from 'react'
import "./style.css"
import Header from "../../layout/Header"
import Footer from "../../layout/Footer"
import SLider from '../../components/Slider'
import { SliderData } from '../../utils/sliderData'

export default function Pricing() {
  return (
    <>
    <Header/>
    <SLider data={SliderData.home}/>
    <section className="pricing-section">
    <div className="container">
      <div className="sec-title text-center">
        {/* <span className="title">Get plan</span> */}
        <h2>PRICING PLANS COLLECTION</h2>
      </div>
      <div className="outer-box">
        <div className="row">
          {/* Pricing Block */}
          <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
            <div className="inner-box">
              <div className="icon-box">
                <div className="icon-outer"><i className="fas fa-paper-plane" /></div>
              </div>
              <div className="price-box">
                <div className="title"> Parking Services</div>
                <h4 className="price">$35.99</h4>
              </div>
              <ul className="features">
                <li className="true">MONTHLY PLAN</li>
                <li className="true">Avalibility Timming</li>
                <li className="true">Lorem Lpsum</li>
                <li className="false">Lorem Lpsum</li>
                <li className="false">Lorem Lpsum</li>
              </ul>
              <div className="btn-box">
                <a href="https://codepen.io/anupkumar92" className="theme-btn">BUY plan</a>
              </div>
            </div>
          </div>
          {/* Pricing Block */}
          <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
            <div className="inner-box">
              <div className="icon-box">
                <div className="icon-outer"><i className="fas fa-paper-plane" /></div>
              </div>
              <div className="price-box">
                <div className="title">VIP Services</div>
                <h4 className="price">$99.99</h4>
              </div>
              <ul className="features">
              <li className="true">MONTHLY PLAN</li>
                <li className="true">Avalibility Timming</li>
                <li className="true">Lorem Lpsum</li>
                <li className="false">Lorem Lpsum</li>
                <li className="false">Lorem Lpsum</li>
              </ul>
              <div className="btn-box">
                <a href="https://codepen.io/anupkumar92" className="theme-btn">BUY plan</a>
              </div>
            </div>
          </div>
          {/* Pricing Block */}
          <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
            <div className="inner-box">
              <div className="icon-box">
                <div className="icon-outer"><i className="fas fa-paper-plane" /></div>
              </div>
              <div className="price-box">
                <div className="title">Additional Services</div>
                <h4 className="price">$199.99</h4>
              </div>
              <ul className="features">
              <li className="true">MONTHLY PLAN</li>
                <li className="true">Avalibility Timming</li>
                <li className="true">Lorem Lpsum</li>
                <li className="false">Lorem Lpsum</li>
                <li className="false">Lorem Lpsum</li>
              </ul>
              <div className="btn-box">
                <a href="https://codepen.io/anupkumar92" className="theme-btn">BUY plan</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer/>
  </>
  )
}
