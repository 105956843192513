export default {

    // baseUrl:"http://192.168.0.122:8005/"
    // baseUrl: "https://api.valet-parking.ga/",
    // baseUrl: "http://54.160.133.112:8005/",
    // image_url:"https://projectwallet.pythonanywhere.com/"
    // baseUrl: "https://betavalet.ml/",
    // baseUrl: "http://192.168.137.109:8000/"
    // baseUrl: " https://apivallaycarparking.newhnh.ml/"
    // http://157.245.135.48:8008/
    // baseUrl: "https://apivallaycarparking.newhnh.ml/"
    // baseUrl: "http://192.168.0.122:8005/",
    // baseUrl: "https://api.tradehubintl.com/"
    baseUrl: "https://api.hnhsofttechsolutions.com/"



}