import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Parallax } from "swiper";
import { useNavigate, useLocation } from 'react-router-dom';
import sliderone from '../valetuser/family-airport-Recovered2.jpg'
import { Button } from 'react-bootstrap';
import { Player, BigPlayButton } from 'video-react';
import "./sliderstyle3.css";
import bg from "../valetuser/backslider2.jpg"
import { Navigate } from 'react-router-dom';
// import required modules
// import { Parallax, Pagination, Autoplay } from "swiper";

const slider3 = ({ data }) => {
    const Navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    console.log("pathname slider", pathname)
    const vidRef = useRef();

    // var vid = document.getElementById("myVideo");

    // const playVid = () => {

    //     var vid = document.getElementById("myVideo");
    //     var controlBtn = document.getElementById('play-pause');

    //     if (vid.paused) {
    //         vid.play()
    //         controlBtn.className = "pause";
    //     }

    //     else {
    //         vid.pause();
    //         controlBtn.className = "play";
    //     }


    // }

    // useEffect(() => {

    //     vidRef.current.play(); 
    // },[]);

    //  const pauseVid =() => {
    //         var vid = document.getElementById("myVideo");
    //         vid.pause();
    //     }


    return (
        <>

        

            <Swiper
                direction={'vertical'}
                slidesPerView={1}


                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                speed={600}
                parallax={true}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Parallax, Pagination, Navigation]}
                className="mySwiper"
            >
                <div
                    slot="container-start"
                    className="parallax-bg"
                    style={{
                        backgroundImage: `url(${bg})`
                    }}
                    data-swiper-parallax="-23%"
                ></div>
                <SwiperSlide>
                    {pathname == "/ReservationForm" || pathname == "/Reserve" ?

                        <div className="information">
                            Submit Your Information
                        </div> :

                        <>

                            <div className="title col-md-5">
                                LAX Airport <br /> Valet Services
                            </div>
                            <div className="subtitle col-md-5">
                                “At Your Departing Terminal Or Main Office Valet Pick Up”
                                Our Certified Professional Valet Drivers Are Ready To Valet
                                Your Vehicle And Simplify Your LAX Parking Needs !!.
                            </div>
                            <div className="subtitle col-md-5">

                                <Button className='' onClick={() => Navigate("/Reserve")} style={{ width: "130px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Book Now
                                </Button>
                            </div>
                        </>
                    }

                </SwiperSlide>
                <SwiperSlide>
                    {pathname == "/ReservationForm" || pathname == "/Reserve" ?

                        <div className="information">
                            Submit Your Information
                        </div> :

                        <>

                            <div className="title col-md-5" >
                                Lax Main Office <br /> Valet Pick Up
                            </div>
                            <div className="subtitle col-md-5">
                                We Are Pleased To Offer Our Esteemed Patrons The Office
                                Curbside Valet Pickup Service, A Convenient And Efficient Solution For Your Transportation Needs.
                                The Cost For This Esteemed  Service Is A Modest $39.99.
                            </div>
                            <div className="subtitle col-md-5">

                                <Button className='' onClick={() => Navigate("/Reserve")} style={{ width: "130px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Book Now
                                </Button>
                            </div>
                        </>
                    }

                </SwiperSlide>
                <SwiperSlide>
                    {pathname == "/ReservationForm" || pathname == "/Reserve" ?

                        <div className="information">
                            Submit Your Information
                        </div> :

                        <>

                            <div className="title col-md-5">

                                Lax Terminal<br /> Valet Pick-Up
                            </div>
                            <div className="subtitle col-md-5">
                                We Are Honored To Present Our Distinguished Clientele With
                                The Curbside Terminal Valet Service, An Epitome Of Travel Convenience.
                                This Unparalleled Service Is Available At The Modest Cost Of $39.95,
                                Ensuring A Seamless And Effortless Journey.
                            </div>
                            <div className="subtitle col-md-5">

                                <Button className='' onClick={() => Navigate("/Reserve")} style={{ width: "130px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Book Now
                                </Button>
                            </div>
                        </>
                    }

                </SwiperSlide>
                {/* <SwiperSlide>
                    {pathname == "/ReservationForm" || pathname == "/Reserve" ?

                        <div className="information">
                            Submit Your Information
                        </div> :

                        <>

                            <div className="title">
                                LAX Airport <br /> Valet Services
                            </div>
                            <div className="subtitle">
                                Find your best match
                            </div>
                            <div className="subtitle">

                                <Button className='' onClick={() => Navigate("/Reserve")} style={{ width: "130px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Book Now
                                </Button>
                            </div>
                        </>
                    }

                </SwiperSlide>
                <SwiperSlide>
                    {pathname == "/ReservationForm" || pathname == "/Reserve" ?

                        <div className="information">
                            Submit Your Information
                        </div> :

                        <>

                            <div className="title">
                                LAX Airport <br /> Valet Services
                            </div>
                            <div className="subtitle">
                                Find your best match
                            </div>
                            <div className="subtitle">

                                <Button className='' onClick={() => Navigate("/Reserve")} style={{ width: "130px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Book Now
                                </Button>
                            </div>
                        </>
                    }

                </SwiperSlide> */}
            </Swiper>


        </>
    )
}

export default slider3