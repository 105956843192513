import React, { useEffect, useState } from 'react'
// import Header2 from "../../layout/Header2"
import Header3 from "../../layout/Header3"
import Footer from "../../layout/Footer"
// import Slidar2 from "../../components/slider2"
import Slidar3 from "../../components/slider3"
import $ from 'jquery'
import { SliderData } from '../../utils/sliderData'
import baseUrl from '../../../baseurl/BaseUrl'
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap'



export default function Profile() {
  const [data, setData] = useState([])
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [contact, setContact] = useState("")
  const [profile, setProfile] = useState("")
  // console.log("profile",profile)
  const navigate = useNavigate()


  const [loader, setLoader] = useState(false);


  const Token = localStorage.getItem("Token")

  useEffect(() => {
    if (Token != null) {

      GetServices()
    }
  }, [])



  const GetServices = () => {
    setLoader(true);
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + Token
      }
    };
    fetch(`${baseUrl.baseUrl}webapi/profile?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status && result.status != "False") {
          console.log("result.data", result.data)

          setData(result.data)
          setFname(result.data.fname)
          setLname(result.data.lname)
          setContact(result.data.contact)
          setEmail(result.data.email)
          setProfile(result.data.profile)
          setAddress(result.data.address)



        } else {


          if (result.message == "Unauthorized") {
            navigate("/Login")
          } else {


            Swal.fire({
              title: "opps",
              text: result.message,
              icon: "error"
            })
          }
        }
      }).catch(function (e) {
        setLoader(false)
        Swal.fire({
          title: "opps!",
          text: e.message,
          icon: "error"
        })
      })

  }



  const ProfileUpdate = () => {
    setLoader(true);
    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("address", address);
    formData.append("contact", contact);


    var requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: "Bearer " + Token
      },
      body: formData,
    };
    fetch(`${baseUrl.baseUrl}webapi/profile?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status && result.status != "False") {
          console.log("result.data", result)

          Swal.fire({
            title: "success",
            text: result.message,
            icon: "success"
          })


        } else {


          if (result.message == "Unauthorized") {
            navigate("/Login")
          } else {


            Swal.fire({
              title: "opps",
              text: result.message,
              icon: "error"
            })
          }
        }
      }).catch(function (e) {
        setLoader(false)
        Swal.fire({
          title: "opps!",
          text: e.message,
          icon: "error"
        })
      })
  }

  $(".modal-backdrop").hide()
  $("body").removeClass("modal-open")



  return (
    <div>
      {/* <Header2 /> */}
      {

        Token == null ?

          <div className='bluediv'  >
            <div className="bs-example px-3 py-3">



              <div className="clearfix fss">
                <Button onClick={() => navigate("/Register")} className='  float-right ml-2 ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                  Register Now
                </Button>{' '}
                <Button onClick={() => navigate("/Login")} className='float-right ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                  Sign In
                </Button>{' '}
              </div>


            </div>
          </div>

          :
          <div className='bluediv' style={{ height: "64px" }} >
            <div className="bs-example px-3 py-3">



              <div className="clearfix fss">
                {/* <Button onClick={() => navigate("/Register")} className='  float-right ml-2 ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
Register Now baloch
</Button>{' '}
<Button onClick={() => navigate("/Login")} className='float-right ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
Sign In
</Button>{' '} */}
                <h3 className='float-right  mx-5 ' >
                  <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" />
                  {/* <img src={profile ? profile: '/assets/images/person_1.jpg'} className='img-fluid' width="60%" style={{ border: "5px solid rgb(3 156 213)" }} alt={""}/> */}

                </h3>
                <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome {fname}</h3>




              </div>


            </div>
          </div>

      }
      <Header3 />
      {/* <Slidar2 data={SliderData.home} /> */}
      <Slidar3 />
      {loader ? <Loader fullPage loading /> : null}

      <section className="ftco-section">
        <div className="container mt-4">
          <div className=" text-center heading-section ">
            <h2 className="title">PROFILE</h2>
          </div>
          <div className="row ">
            <div className='col-md-6 text-center'>
              <img src={profile ? profile : '/assets/images/person_1.jpg'} className='img-fluid' width="60%" style={{ border: "5px solid rgb(3 156 213)" }} alt={""} />
              {/* <h5 className='mt-2'>{ data.fname +" "+  data.lname}</h5> */}
              <h6>{fname + " " + lname}</h6>
              {/* <h6> {data.id}</h6> */}
              <h6>{data.email ? data.email : ""}</h6>

            </div>
            <div className='col-md-6 text-center'>
              <form action="#" className=" p-3 contact-form">
                <div className=" text-center heading-section ">
                  {/* <h3 className="title">Get started absolutely free</h3> */}
                </div>
                <div className="form-group">
                  <input onChange={(e) => setFname(e.target.value)} type="text" value={fname} className="form-control" placeholder="First Name" style={{ border: "2px solid grey" }} />
                </div>
                <div className="form-group">
                  <input onChange={(e) => setLname(e.target.value)} type="text" value={lname} className="form-control" placeholder="Last Name" style={{ border: "2px solid grey" }} />
                </div>
                <div className="form-group">
                  <input  type="email" value={email} className="form-control" placeholder="Email " style={{ border: "2px solid grey" }} disabled />
                </div>
                <div className="form-group">
                  <input onChange={(e) => setContact(e.target.value)} type="text" value={contact} className="form-control" placeholder="Contact Number" style={{ border: "2px solid grey" }} />
                </div>
                <div className="form-group">
                  <input onChange={(e) => setAddress(e.target.value)} type="text" className="form-control" placeholder="Address" value={address} style={{ border: "2px solid grey" }} />
                </div>
                <div className="form-group">
                  <button onClick={() => ProfileUpdate()} type="button" className=" btn-lg btn-block" style={{ backgroundColor: "#1089ff", color: "white", border: "solid 2px #1089ff" }}>Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}