import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Loader } from "react-overlay-loader";
import Swal from "sweetalert2";
import 'react-overlay-loader/styles.css'
import baseUrl from '../../../baseurl/BaseUrl'
import { useNavigate } from "react-router-dom";
import $ from 'jquery'
import cardimg from "../valetpic.png"
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate()

  $(".modal-backdrop").hide()
  $("body").removeClass("modal-open")


  // HIDE SHOW Password 

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const pasword = values.password

  console.log("password value", password)

  const Token = localStorage.getItem("Token")
  const Guestlogin = localStorage.getItem("guest")
  useEffect(() => {
    if (Token != null || Guestlogin != null) {
      Navigate("/")
    } else {

    }

  }, [])

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const LoginasGuest = () => {
    localStorage.setItem("guest", "guest")
    window.location.href = "/Reserve"
  }

  const Login = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", pasword);

    var requestOptions = {
      method: 'POST',
      // headers: {
      //     // Authorization: "Bearer " + Token
      // }
      body: formData,
    };
    fetch(`${baseUrl.baseUrl}webapi/customerlogin`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status) {
          setPassword("")
          setEmail("")
          // console.log("get register==========>", result);
          localStorage.setItem("Token", result.token)
          localStorage.setItem("Refresh", "refresh")

          localStorage.setItem("UserData", JSON.stringify(result.data))
          // Navigate("/Reserve")

          Swal.fire({
            title: "success",
            text: result.message,
            icon: "success",
            confirmButtonColor: "#03bafe",
          })
          // Navigate("/Reserve")

          window.location.href = "/"

        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }

  return (
    <>
      {loader ? <Loader fullPage loading /> : null}
      <section >
        <div className="container ">
          <div className="row " >
            <div className="col-md-5 ">
              <Card className='align-items-center d-flex justify-content-center' style={{ minHeight: "30rem", padding: "20px 30px 25px 20px" }}>
                <img src='assets/images/newlogo.png' className="img-fluid" width="25%" alt={""} />
                <Card.Body>
                  {/* <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle> */}
                  <Card.Text>
                    <h3 style={{ fontSize: "2rem" }}>Hi, Welcome Back</h3>
                    <img src={cardimg} className="img-fluid" style={{ width: "80%" }} alt={""} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6  " style={{ marginTop: "6%" }} >
              <form action="#" className=" p-3 contact-form">
                <div className="  heading-section mb-5 ">
                  <h3 className="title">Sign in to Valet Services</h3>
                  <h6 className="">Enter your details below.</h6>
                </div>
                <div className="form-group mb-2">
                  <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email address" />
                </div>
                <div class="input-group mb-2">
                  <input type={values.showPassword ? "text" : "password"}
                    class="form-control" onChange={handlePasswordChange("password")}
                    value={values.password}
                    placeholder="Password"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" onClick={handleClickShowPassword}><i className="fa fa-eye"> </i></span>
                  </div>
                  {/* <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" /> */}
                </div>
                <div className="form-group mb-2">
                  <label className="form-remember"></label><label className='pull-right'><Link className="form-recovery " to="/ForgetPassword" >Forgot Password?</Link></label>
                </div>
                <div className="form-group mb-2">
                  <button type="button" className=" btn-lg btn-block" onClick={() => Login()} style={{ backgroundColor: "#1089ff", color: "white", border: "solid 2px #1089ff" }}>Login</button>
                </div>
                <div className="form-group mb-2">
                  <button type="button" className=" btn-lg btn-block" onClick={() => LoginasGuest()} style={{ backgroundColor: "#1089ff", color: "white", border: "solid 2px #1089ff" }}>Login as Guest</button>
                </div>
                <div className="form-group text-center" >
                  <h5 className="title">Don't have an account? <Link to="/Register">Get Registered</Link></h5>
                </div>

                <div className="form-group text-center" >
                  <h5 className="title">Back to <Link to="/">Home</Link></h5>
                </div>
              </form>
            </div>
          </div>
          <div>
          </div>
        </div>
      </section>
    </>
  )
}