import React, { useEffect, useState } from 'react'
import Header from "../../layout/Header"
import Footer from "../../layout/Footer"
import Slidar from "../../components/Slider"
import "./card.css"
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import baseUrl from '../../../baseurl/BaseUrl';
import { Loader } from "react-overlay-loader";
import { Routes, Route, useParams } from 'react-router-dom';
import GetUrlid from '../geturlid/GetUrlid'






export default function IdCard() {
  const Navigate = useNavigate()
  const Id = GetUrlid()


  const [loader, setLoader] = useState(false)
  const [Data, setData] = useState([])


  useEffect(() => {
    GetInfo()
  }, [])
  const GetInfo = () => {
    setLoader(true);
    var requestOptions = {
      method: 'GET',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
    };
    fetch(`${baseUrl.baseUrl}qrcode?cid=${Id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status && result.status != "False") {
          setData(result.data)
        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
          Navigate("/")
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }

  return (
    <div>
      {loader ? <Loader fullPage loading /> : null}

      <section className="ftco-section" style={{ padding: "18%", height:"100vh", backgroundColor:"rgba(3, 156, 213)" }}>
        <div className="container mt-4">
          <div className="row text-center mt-5">
            <div className='col-md-12'>


              <div className="wrapper">
                <div className='row'>
                  <div className='col-md-12'>

                    <img src="./assets/images/newlogo.png" className="img-fluid" width="100%" height="100px" />
                  </div>
                </div>

                <div className="idcard">
                  <img src={Data.img} alt="Employee_ID" width="20px" />
                  <div className="info">
                    <p>{Data.fname}</p>
                    <p>{Data.email}</p>

                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
      </section>

    </div>
  )
}
