import React from "react";
import { Link, NavLink } from "react-router-dom"
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import newlogo from "../valetuser/newlogo.png"
import google from "../valetuser/google.png"
import { BiPhoneCall } from "react-icons/bi";
import { BiMessageDetail } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";
import { BiMap } from "react-icons/bi";
export default function Footer() {

    const Token = localStorage.getItem("Token")
    return (
        <div>

            <div className=' ' style={{ backgroundColor: "#1F1D1D", boxShadow: "0 -5px 5px -5px #333" }}>

                <section className="ftco-section2" style={{ padding: "3em 0" }} >


                    {/* <ServicelogSlider /> */}
                    <div className='row ml mb-5' >
                        <div className='col-md-2' >
                            {/* <h1 className='mb-5' style={{ color: "#9F9F9F" }} >Car Valet</h1> */}

                            <h1 className="mb-5" >
                                <Link to="https://www.laxairportvaletservices.com/" className="mb-5">
                                    <img src={newlogo} className="img-fluid" width="40%" height="120px" alt={""} />
                                    {/* <img src="./assets/images/Airport Valet-02.png" className="img-fluid" width="40%" height="150px" alt={""} /> */}
                                </Link>
                            </h1>
                            <img src={google} className="img-fluid" width="40%" height="100px" alt={""} />


                            {/* <AiOutlineTwitter className='mx-2' style={{ fontSize: "20px", background: "#828282", }} /> */}

                        </div>
                        <div className='col-md-2' >

                            <ul>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/" className="footerli" > Home </NavLink> </li>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/Reserve" className="footerli" > Reservations </NavLink> </li>

                                {Token != null ?  <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/UserData" className="footerli" > History </NavLink> </li>:""}
                                {/* <li className='py-2' style={{ color: "#A5A5A5" }} >Reservations</li>
                <li className='py-2' style={{ color: "#A5A5A5" }} >History</li> */}
                            </ul>
                        </div>
                        <div className='col-md-2' >

                            <ul>
                                {/* <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} >
                                    <a href='#reviews' className='footerli' >Reviews</a>
                                </li> */}
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/contact" className="footerli" > Contact </NavLink> </li>
                                {Token != null ? <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5" }} ><NavLink to="/Profile" className="footerli" > Profile </NavLink> </li> : ""}

                                <a href='https://www.facebook.com/people/Airport-Valet-of-LAX/100087052763482/' target={"_blank"}><FaFacebookF className='mx-1' style={{ fontSize: "20px", color: "white" }} /></a>

                                <a href='https://www.instagram.com/laxairportvaletservices/' target={"_blank"}><FaInstagram className='mx-1' style={{ fontSize: "20px", color: "white" }} /></a>
                                {/* <li className='py-2' style={{ color: "#A5A5A5" }} >Contact</li>
                <li className='py-2' style={{ color: "#A5A5A5" }} >Blogs</li> */}
                            </ul>
                        </div>
                        <div className='col-md-5' >
                            <ul>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiMap size={25} />  LAX Airport Valet Services 8923 South Sepulveda<br className='mx-3' /> Blvd LA, CA 90045</li>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiPhoneCall size={25} /><a href='tel:+1 (310 800-1075)' style={{ color: "white" }}>(310) 216-6110.</a></li>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiPhoneCall size={25} /><a href='tel:+1 (800) 328-2538' style={{ color: "white" }}>(800) 328-2538.</a></li>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", }}><BiMessageDetail size={25} /><a onClick={()=>window.open("https://wa.me/13108001075")} href="" style={{ color: "white" }}>(310) 800-1075.</a></li>
                                <li className='py-2 addresscallmessage' style={{ color: "#A5A5A5", fontFamily: "Poppins" }}>< MdOutlineMail size={25} /><a href='mailto:Contact@laxairportvaletservices.com' style={{ color: "white" }}> Contact@laxairportvaletservices.com </a></li>
                            </ul>
                        </div>


                    </div>


                    <div className='row ml mt-5' >
                        <div className='col-md-7' >
                            <p className="copyright" style={{ color: "#8C8C8C", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", lineHeight: "30.1px", letterSpacing: "0.1rem" }} >Copyright © 2023 LAX Airport valet Services - All Rights Reserved.

                            </p>
                        </div>
                        {/* <div className='col-md-3' >
            <p style={{ color: "#A5A5A5" }}>created by <span style={{ color: "#32A9E0" }} >xtreme Technologies</span> </p>

        </div> */}
                        <div className='col-md-4 ' >
                            <p style={{ color: "#8C8C8C" }}>Created By <spna className="xtreme">xtreme Technologies</spna></p>

                        </div>



                    </div>

                </section>


            </div>
        </div>
    )
}