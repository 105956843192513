export const SliderData = {
    home: [
        { url: "url('/assets/images/bgsliderimg.jpeg')" },
        { url: "url('/assets/images/bgsliderimg.jpeg')" },
        { url: "url('/assets/images/bgsliderimg.jpeg')" }
    ],
    reservation: [
        { url: "url('/assets/images/reservationBanner1.jpg')" },
        { url: "url('/assets/images/reservationBanner2.jpg')" },
        { url: "url('/assets/images/reservationBanner3.jpg')" },
    ],
    contact: [
        { url: "url('/assets/images/contactBanner1.jpg')" },
        { url: "url('/assets/images/contactBanner2.jpg')" },
        { url: "url('/assets/images/contactBanner3.jpg')" }
    ],
}