import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import baseUrl from '../../../baseurl/BaseUrl';
import "./paymentresponse.css"
import newlogo from "../../valetuser/newlogo.png"
import html2pdf from 'html2pdf.js';
export default function PaymentResponse() {
  const [searchParams] = useSearchParams();
  const store = useSelector(store => store)
  const [Ids, setIds] = useState([]);



  console.log("store.counter.amount.state.fname", store.counter.amount.state.fname, store.counter.form.state.fnames, store.counter.form.pdfcontent)
  console.log("store.counter", store.counter.form.payslip)


  const Navigate = useNavigate()
  const [finalamount, seFinalamount] = useState(store.counter.form.totalamount);
  const [pdf, setPdf] = useState(store.counter.form);

  const location = useLocation();

  // //("totalamount form", store.counter.form.totalamount)
  // //("location",location.state)
  // const finalamount = location.state.totalamount

  const Guestlogin = localStorage.getItem("guest")


  // //("finalamount", finalamount)



  const Navi = () => {
    Navigate("/")
  }
  const Con = (amount) => {
    return Number(amount).toFixed(2)
  }

  useEffect(() => {

    // seFinalamount(store.counter.form.state.totalamout)
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params.status != "true") {
      // seFinalamount(store.counter.form.totalamount)
      PaymentExecute()




    } else {

      seFinalamount(location.state.totalamount)
    }




  }, []);

  const Token = localStorage.getItem("Token")
  const pdflocal = localStorage.getItem("PdfContent")
  console.log("pdflocal",pdflocal)




  // paypal 
  const PaymentExecute = async (amount) => {
    // Blob URL

    fetch(pdflocal)
    .then(response => response.blob())
    .then(blobData => {
      console.log("blobData",blobData)
    // setLoader(true);
    const formData = new FormData();
    if (store.counter.amount.state.promo_id) {

      formData.append("promo_code", store.counter.amount.state.promo_id);
    }
    formData.append("waiver_form",blobData );
    formData.append("startdate", store.counter.amount.state.startdate);
    formData.append("enddate", store.counter.amount.state.enddate);
    formData.append("starttime", store.counter.form.state.departure_time + ":00");
    formData.append("endtime", store.counter.form.state.return_time + ":00");
    // {finalamount == " " ?  formData.append("totalamout",amount):
    //  formData.append("totalamout",finalamount)}
    formData.append("totalamout", finalamount);
    formData.append("name", store.counter.form.state.fnames);
    formData.append("lastname", store.counter.form.state.lnames);
    formData.append("countrycode", store.counter.form.state.countrycode);
    formData.append("phone", store.counter.form.state.phonenumber);
    if (store.counter.form.state.alphonenumber != "") {

      formData.append("alternatephone", store.counter.form.state.alphonenumber);
    }

    formData.append("vehicleColor", store.counter.form.state.vehicleColor);
    formData.append("vehicleMake", store.counter.form.state.vehicleMake);
    formData.append("vehicleModel", store.counter.form.state.vehicleModel);
    formData.append("licensePlate", store.counter.form.state.licensePlate);
    formData.append("flighNumber", store.counter.form.state.flightno);

    formData.append("departure_airline", store.counter.form.state.departure_airline);
    formData.append("departure_airline_time", store.counter.form.state.departure_time + ":00");
    formData.append("return_airline", store.counter.form.state.return_airline);
    formData.append("return_airline_time", store.counter.form.state.return_time + ":00");
    formData.append("customerpay", searchParams.get('paymentId'));
    formData.append("customer", store.counter.amount.state.id);
    formData.append("service", store.counter.amount.state.serviceId);
    formData.append("email", store.counter.form.state.email);
    formData.append("paytype", store.counter.form.method);
    formData.append("additional", JSON.stringify(store.counter.amount.state.additionalid));
    formData.append("media", JSON.stringify(store.counter.form.state.S3images));
    formData.append("evcharge", store.counter.form.evamount);
    formData.append("gas", store.counter.form.gasamount);
    formData.append("parking", store.counter.form.parking);
    formData.append("transaction_fee", store.counter.form.transectionfee);
    formData.append("credit_card_fee", store.counter.form.cardcharge);


 

    var requestOptions;

    if (Token !== null && Guestlogin == null) {
      requestOptions = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + Token
        },
        body: formData,
      };
    } else if (Token == null && Guestlogin == "guest") {
      requestOptions = {
        method: 'POST',
        // headers: {
        //   Authorization: "Bearer " + Token
        // },
        body: formData,
      };
    }
  
    if (Token !== null && Guestlogin == null) {


      fetch(`${baseUrl.baseUrl}webapi/customerbooking?role=customer`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // setLoader(false);
          if (result.status === false) {
            Navigate("/PaymentFailed")


          } else {
            Swal.fire({
              title: "success",
              text: result.message,
              icon: "success",
              confirmButtonColor: "#03bafe",
            })
            
            
          }
          
        }).catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });
        
    } else if (Token == null && Guestlogin == "guest") {
      fetch(`${baseUrl.baseUrl}webapi/guest_customerbooking`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // setLoader(false);
          if (result.status === false) {
            Navigate("/PaymentFailed")


          } else {
            Swal.fire({
              title: "success",
              text: result.message,
              icon: "success",
              confirmButtonColor: "#03bafe",
            })
          }
        }).catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });
        



    }
  });
  }
  
  


  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  

  const handleDownload = () => {
    const pdf = store.counter.form.payslip
    fetch(pdf)
      .then((response) => response.blob())
      .then((blob) => {
        // Blob data ka URL create karein
        const pdfURL = window.URL.createObjectURL(new Blob([blob]));

        // Download link create karein
        const a = document.createElement('a');
        a.href = pdfURL;
        a.download = 'BookingInvoice.pdf'; // PDF file ka naam
        document.body.appendChild(a);

        // Download button par click karein
        a.click();

        // URL object revoke karein
        window.URL.revokeObjectURL(pdfURL);
      })
      .catch((error) => console.error('PDF download error:', error));
  };


  return (
    <>

      <div className='container' id='my-component' style={{ marginTop: "5%" }}>
        <div className='row justify-content-center' >
          <div className='col-md-6' >
            <div className="">
              <div className="card" >
                <span className="card__success"><i className="fa fa-check" /></span>
                <div className='row text-center'>
                  <div className="col-md-12">
                    <img src={newlogo} width="30%" height="80px" alt={""} />
                  </div>
                </div>
                <h1 className="card__msg mt-2">Booking Successful </h1>
                <h2 className="card__submsg">Thank you for your booking</h2>
                <div className="card__body">
                  <div className="row">
                    <div className='col-6 text-left'>
                      <p>Name</p>
                      <p>Email</p>
                      <p>Start Date</p>
                      <p>End Date</p>
                      <p>Start Time</p>
                      <p>End Time</p>
                    </div>
                    <div className='col-6  text-right'>
                      <p className="">{store.counter.form.state.fnames + " " + store.counter.form.state.lnames}</p>
                      <p className="">{store.counter.form.state.email}</p>
                      <p className="">{store.counter.amount.state.startdate}</p>
                      <p className="">{store.counter.amount.state.enddate}</p>
                      <p className="">{tConvert(store.counter.form.state.departure_time)}</p>
                      <p className="">{tConvert(store.counter.form.state.return_time)}</p>
                    </div>
                  </div>
                  <span>Your Total Amount  <h1 className="card__price"><span>$</span>{Con(finalamount)}<span></span></h1></span>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>

      <div className='col-md-12 text-center'>
        <button onClick={() => Navi()} style={{ backgroundCOlor: "rgba(3, 156, 213)" }} type="button" className="btn btn-primary btn-lg">Back to Dashboard</button>
        {/* <p>To print your invoice click here</p> */}
        <div className="form-group text-center mt-2" >
          <p className="title">To print your invoice <Link to="#" onClick={handleDownload}> click here</Link></p>
        </div>
      </div>

    </>
  )
}
