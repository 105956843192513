import React, { useState } from 'react'
import html2pdf from 'html2pdf.js';
import Document from './Viewver';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AmountData, updateForm } from '../../../redux/actions/counterActions';
import { useSelector } from 'react-redux';
const Pdf = () => {
    const location = useLocation();
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const store = useSelector(store => store)
    const [PdfContent, setPdfContent] = useState(null);
    const [active, setActive] = useState(false);
    const [trimmedData, setTrimmedData] = useState(null);
    const [name, setName] = useState("");

    console.log("PdfContent", PdfContent)
    const reduxFormData2 = store.counter.form


    const DataMove = () => {
        console.log("Daata move", URL.createObjectURL(PdfContent))
        dispatch(updateForm({ ...reduxFormData2, pdfcontent: URL.createObjectURL(PdfContent) }))

        localStorage.setItem("PdfContent",  URL.createObjectURL(PdfContent))

        Navigate('/Payment', { state: { services: location.state.services, selectedId: location.state.selectedId, pdfcontent: PdfContent } })

    }
    const convertToPdf = async () => {
        setActive(true)
        const element = document.getElementById('my-component');

        const options = {
            margin: 0,
            filename: 'Liability Waiver',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' },
        };

        // Generate the PDF content and store it in a variable
        const pdfBlobData = await html2pdf().from(element).set(options).outputPdf('blob');
        console.log("pdfBlobData", pdfBlobData)


        // Set the PDF content using the state setter function
        setPdfContent(pdfBlobData);

        // Create a download link for the PDF and trigger the download
        const url = window.URL.createObjectURL(pdfBlobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Liability Waiver.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url); // Release the object URL
        document.body.removeChild(a);

    };

    console.log("pdf", trimmedData, name)



    const handleTrimmedDataChange = (data, name) => {
        console.log("data name", data, name)
        setTrimmedData(data);
        setName(name);

    };

    const handleNameChange = (name) => {
        setName(name);
    };

    return (
        <>
            <div className="container-fluid ps-4">
                <div className="row">
                    <div className='col-lg-12' id="my-component">
                        <Document style={{ height: '500mm' }}
                            onTrimmedDataChange={handleTrimmedDataChange}
                            onNameChange={handleNameChange}
                        />
                    </div>


                    <p className='text-center'> <button type="button" disabled={trimmedData == null && name == ""} className="btn btn-primary w-25"
                        onClick={convertToPdf}>Save as PDF</button></p>

                    <p className='text-center'> <button type="button" disabled={active == false} className="btn btn-primary w-25"
                        onClick={() => DataMove()}>Continue to Receipt</button></p>
                </div>
            </div>
        </>
    )
}

export default Pdf;