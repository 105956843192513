import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import "./pen.css"
import { useSelector } from 'react-redux';
export default function Viewver(props) {
    const [trimmedData, setTrimmedData] = useState(null);
    const [name, setname] = useState("");
    console.log("props", props)
    const sigPad = useRef(null);

    const clear = () => {
        sigPad.current.clear();
        setTrimmedData(false)
        props.onTrimmedDataChange(null); // Pass null to parent
        props.onNameChange("")

    };

    const Saves = () => {
        if (sigPad.current) {
            const data = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            setTrimmedData(data);
            props.onTrimmedDataChange(data, name); // Pass data to parent
            props.onNameChange(name)

        }
    };



    return (
        <div>
            <div className='container' >
                <div className="row mt-3">
                    <h3 className=' '>Release Of Liability</h3>
                    <p className='  text-muted'>Signing the foregoing constitutes an agreement between the vehicle owner and a Airport Valet. The management is not responsible for loss or damage to any vehicle as a result of fire, theft, ordinary or gross negligence, or otherwise, unless ita shall appear that the loss or damage was caused by willful act or misappropriation on the part of management or its employees, we are limited to liability up to $250. Management is not responsible for articles left in the vehicle, please remove all a valuables from your vehicle. All claims for adjustment must be presented before the vehicle leaves the facility, and employees are not authorized to change the terms of this agreement. Please enter your legal name as it appears on your passport.</p>
                    {/* <p className=' text-start'>Sep 23, 2023</p> */}
                    <div className='row mt-2'>
                        {/* <div className='col-md-12'> */}
                            {/* <div className='d-flex'> */}
                                <div className='col-md-2'>
                                    <h4 className='text-start '>Full Name</h4>

                                </div>
                                <div className='col-md-10 '>
                                    <input value={name} onChange={(e) => setname(e.target.value)} type="text" style={{ border: "1px solid black" }} className="form-control " id="formGroupExampleInput" placeholder="Enter Full Name" />
                                </div>
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                    <div className='row'>
                        <div className='col-md-12 py-2' style={{ marginTop: "6%" }}>

                            <div className='text-center'>
                                {trimmedData ? (
                                    <>
                                        <img className='sigImage  image-fluid' style={{ objectFit: 'contain' }} height='100px' src={trimmedData} alt="Signature" />
                                    </>
                                ) : null}

                                <div className='row'>


                                    <div className='col-md-6 d-flex justify-content-start mb-3'>
                                        <button type="button" className="btn btn-light fs-3 outline rounded-0 " onClick={clear}>x</button>
                                    </div>
                                    <div className='col-md-6 d-flex justify-content-end mb-3'>
                                        <button type="button" className="btn btn-outline-secondary pt-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Click to Sign</button>
                                    </div>
                                    <hr />
                                </div>
                                <span>
                                    <h5 className='text-start'>Signature</h5>
                                </span>


                            </div>
                            {/* </div> */}
                        </div>
                    </div>

                    <div className="form-check text-start ">
                        <input className="form-check-input p-2 mt-1 " type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label fw-bold" for="flexCheckDefault">
                            Electronic Signature Consent
                        </label>
                    </div>
                    <p className='pt-2  text-muted'>By checking here, you acknowledge you have read and understand the above terms, and are consenting to the use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement to use an electronic signature with us for any documents will continue until such time as you notify us in writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your consent. You should always make sure that we have a current email address in order to contact you regarding any changes, if necessary.</p>



                    {/* <div className="row text-center ">

                        <div className='col-md-12 '>
                            <div className='table-responsive-md'>
                                <table class="table  table-hover " style={{ borderCollapse: "collapse", textAlign: "left", border: "1px solid #ddd", fontSize: "1em", width: "100%" }}>
                                    <tfoot>
                                        <tr  >
                                            <td colspan="2">{servicename.toUpperCase()}</td>
                                            <td>${Consubamount(subtotal)}</td>
                                        </tr>
                                        <tr  >
                                            <td colspan="2">LAX CITY TAX 10% (10%): </td>
                                            <td>${citytax}</td>
                                        </tr>
                                        <tr  >
                                            <td colspan="2">TRANSACTION FEE: </td>
                                            <td>${transectionfee}</td>
                                        </tr>
                                        <tr  >
                                            <td colspan="2">CREDIT CARD FEES  (3.5%):	 </td>
                                            <td>{cardcharge}%</td>
                                        </tr>
                                        <tr  >
                                            <td colspan="2">PARKING FEES: </td>
                                            <td>${parking}</td>
                                        </tr>
                                        <tr  >
                                            <td colspan="2">DISCOUNT: </td>
                                            <td>${Con(discount)}</td>

                                        </tr>
                                        <tr  >
                                            <td colspan="2"><strong>Total (USD):</strong> </td>
                                            <td><strong>${Con(totalamount)}</strong></td>

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <hr />
                        </div>

                    </div> */}
                    {/* <hr /> */}
                    {/* <div className="row" >
                        <div className="col-md-6">
                            <div className="text-start">
                                <p><strong>Your Company Name </strong></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-end">
                                <p>   <strong>Phone Number</strong></p>
                            </div>
                        </div>


                    </div> */}


                </div>

            </div>
            {/* <div className="row" style={{ backgroundColor: "#32A9E0", color: "white" }}>
                <div className="col-md-6">
                    <div className="text-start">
                        <p><strong>Hnh Tech Soft Solutions </strong></p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="text-end">
                        <p>   <strong>0300-2223334</strong></p>
                    </div>
                </div>


            </div> */}

            {/* <!-- Modal --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="text-center" id="staticBackdropLabel">Signature</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                <div className='pencolor'>
                                    <SignatureCanvas penColor='black'

                                        canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
                                        ref={sigPad}
                                    />
                                </div>


                            </div>
                            <button type="button" className="btn btn-secondary rounded-0 w-50" data-bs-dismiss="modal" onClick={clear}>clear</button>
                            <button type="button" className="btn btn-success rounded-0 w-50" data-bs-dismiss="modal" onClick={Saves}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
