import React, { useState, useEffect } from "react";
import baseUrl from '../../baseurl/BaseUrl'
import Swal from "sweetalert2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Rating from '@mui/material/Rating';
// swiper bundle styles
// import 'swiper/swiper-bundle.min.css'
// swiper core styles
// import 'swiper/swiper.min.css'
import "./rating2.css";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function RatingDef2() {


  const settings = {
    dots: false,
    infinite: false,
    // Autoplay:true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    // Arrow: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }


  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([])
  const [servicedata, setservice] = useState([])

  const [reservation, setReservation] = useState([])


  useEffect(() => {

    GetReservation()
  }, [])


  const GetReservation = () => {

    // setLoader(true);
    var requestOptions = {
      method: 'GET',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
    };
    fetch(`${baseUrl.baseUrl}webapi/recentReservation`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status) {
          // setData(result.category)
          setReservation(result.data)


        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });

  }




  return (
    <>
      
        {/* <div className=""> */}

          {/* <div className="row justify-content-center ">
            <div className="col-md-7 text-center heading-section "> */}
          <div className="row ml-5 ">
            <div className="col-md-7 ">
              {/* <span className="subheading">Services</span> */}
              {/* <h2 className="mb-5 mt-5">RESERVATIONS </h2> */}
              <h2 className="reservation22">RESERVATIONS </h2>
            </div>
          </div>

          {/* safdar work */}
          {/* <div className="row mx-2"> */}
          <div className="row mx-3">
            <div className='col-md-12  mt-3'>


              <Slider {...settings}>

                {
                  reservation.map((data) => {
                    return (
                      <>
                        {/* <div> */}

                          <div className="item-reservation">
                            <div className="testimony-wrap reservation rounded text-center  py-4   p-3 mb-5 bgmycss  rounded  ">
                              <div className="row" style={{marginTop:"4%"}}>
                                <div className="col-md-12">

                                  {/* <p ><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />{data.servicename}</p> */}

                                  <p >
                                    <img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="85px" height="60px" />
                                    <span className="reservation-span abc text-start"  >
                                      {data.servicename}
                                    </span>                                   
                                       </p>
                                       <p className="text-left">
                                  <span className="text-left reservationp">Reservation Time : {tConvert(data.departure_airline_time)}</span>
                                  <br/>
                                  <span className="text-left reservationp" >Reservation Date : {data.departure_airline} </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                        {/* </div> */}

                      </>
                    )
                  })

                }




             


              </Slider>






            </div>

          </div>



          {/* safdar end */}

        {/* </div> */}
      


    </>
  )
}