import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import baseUrl from '../../../baseurl/BaseUrl';
import "../paymentresponse/paymentresponse.css"
import html2pdf from 'html2pdf.js';

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams();

  const [Ids, setIds] = useState("");
  const [data, setData] = useState([]);
  const [locationdata, setLocationdata] = useState([]);


  const Navigate = useNavigate()
  const location = useLocation();
  const [loader, setLoader] = useState(false);



  console.log("locationpaypal",location)



  const Navi = () => {
    Navigate("/")
  }

  useEffect(() => {

    if (location.state == null) {
      // paypal
      const urlParams = new URLSearchParams(window.location.search);
      const myId = urlParams.get('id');
      const mypaymentId = urlParams.get('paymentId');
      paypalPayExecute(myId, mypaymentId)
      console.log("paypal",myId, mypaymentId)

    }
    else {
      // stripe 
      setLocationdata(location.state.data)

    }


  }, []);

  const Token = localStorage.getItem("Token")


  const paypalPayExecute = (Ids, mypaymentId) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("paymentId", mypaymentId);
    formData.append("id", Ids);
    var requestOptions = {
      method: 'POST',
      headers: {
        // Authorization: "Bearer " + Token
      },
      body: formData,
    };
    fetch(`${baseUrl.baseUrl}webapi/paypalPayExecute`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);

        if (result.status === true) {
          console.log("result paypal", result)
          setData(result.data)
          Swal.fire({
            title: "success",
            text: result.message,
            icon: "success",
            confirmButtonColor: "#03bafe",
          });

        }

        else {

          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const convertToPdf = async () => {

    const element = document.getElementById('my-component');

    const options = {
      margin: 0,
      filename: 'Booking Receipt',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' },
    };

    // Generate the PDF content and store it in a variable
    const pdfBlobData = await html2pdf().from(element).set(options).outputPdf('blob');
    console.log("pdfBlobData", pdfBlobData)



    // Set the PDF content using the state setter function


    // Create a download link for the PDF and trigger the download
    const url = window.URL.createObjectURL(pdfBlobData);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Receipt.pdf';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url); // Release the object URL
    document.body.removeChild(a);

  };

  const Con = (amount) => {
    return Number(amount).toFixed(2)
  }

  return (
    <>

      <div className='container' id='my-component'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="bg">
              <div className="card">
                <span className="card__success"><i className="fa fa-check" /></span>
                <div className='row text-center'>
                  <div className="col-md-12">
                    <img src='assets/images/logo1.png' width="60%" height="100px" alt={""} />
                  </div>
                </div>
                <h1 className="card__msg mt-2">Booking Successful </h1>
                <h2 className="card__submsg">Thank you for your booking</h2>
                <div className="card__body">
                  <div className="row">
                    <div className='col-md-6 text-left'>
                      <p>Name</p>
                      <p>Email</p>
                      <p>Start Date</p>
                      <p>End Date</p>
                      <p>Start Time</p>
                      <p>End Time</p>
                    </div>
                    <div className='col-md-6 text-right'>
                      <p className="">{locationdata.name ? locationdata.name : data.name}</p>
                      <p className="">{locationdata.email ? locationdata.email : data.email}</p>
                      <p className="">{locationdata.startdate ? locationdata.startdate : data.startdate}</p>
                      <p className="">{locationdata.enddate ? locationdata.enddate : data.enddate}</p>
                      <p>{locationdata.starttime ? locationdata.starttime : data.starttime}</p>
                      <p>{locationdata.endtime ? locationdata.endtime : data.endtime}</p>


                    </div>
                  </div>
                  <span>Your Total Amount  <h1 className="card__price"><span>$</span>{locationdata.totalamout ? Con(locationdata.totalamout) : Con(data.totalamout)}<span></span></h1></span>



                </div>



              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='col-md-12 text-center '>
        <button onClick={() => Navi()} style={{ backgroundCOlor: "rgba(3, 156, 213)" }} type="button" className="btn btn-primary btn-lg">Back to Dashboard</button>
        <div className="form-group text-center mt-2" >
          <p className="title">To print your invoice <Link to="#" onClick={convertToPdf}> click here</Link></p>
        </div>
      </div>

    </>
  )
}
