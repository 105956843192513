import React from 'react'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import Header from "../../layout/Header"
import Footer from "../../layout/Footer"
import Slidar from "../../components/Slider"
import { ArrowDownward, RemoveRedEye } from "@material-ui/icons"
import { SliderData } from '../../utils/sliderData'


export default function Reservation() {
    return (
        <div>
            <Header />
            <Slidar data={SliderData.reservation}/>
            <section className="ftco-section">
                <div className="container-fluid mt-4">
                    <div className=" text-center heading-section ">
                        <h2 className="title">RESERVATIONS</h2>
                    </div>
                    <div className="row ">

                        <MaterialTable
                            // style={{
                            //     textAlign:"initial",
                            //     flexDirection:"column"
                            // }}
                            style={{
                                // backgroundColor: '#3FD2C7',
                                // color: '#FFF',
                                fontSize: "18px",
                                textAlign: "initial",
                                flexDirection: "column"
                            }}

                            icons={{
                                Check: Check,
                                DetailPanel: ChevronRight,
                                // Delete: DeleteOutline,
                                Export: SaveAlt,
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                ThirdStateCheck: Remove,
                                Add: Add,
                                SortArrow: ArrowDownward,
                                Clear: Clear,
                                Edit: Edit,
                                ViewColumn: ViewColumn,
                                ResetSearch: Clear
                            }}

                            title=""
                            columns={[
                                { title: 'Name Of Service', field: 'name' },
                                { title: 'Date', field: 'surname' },
                                { title: 'Time', field: 'birthYear', type: 'numeric' },

                                // {
                                //     title: 'Cancel',
                                //     field: 'birthCity',

                                // },
                            ]}
                            data={[
                                { name: 'Safdar', surname: 'Apr 30,2022', birthYear: "12:30pm", birthCity: "Cancel" },
                                { name: 'Safdar', surname: 'Apr 30,2022', birthYear: "12:30pm", birthCity: "Cancel" },
                                { name: 'Safdar', surname: 'Apr 30,2022', birthYear: "12:30pm", birthCity: "Cancel" },
                                { name: 'Safdar', surname: 'Apr 30,2022', birthYear: "12:30pm", birthCity: "Cancel" },


                            ]}
                            actions={[
                                {
                                    icon: RemoveRedEye,
                                    tooltip: 'Save User',
                                    onClick: (event, rowData) => alert("You saved " + rowData.name)
                                }
                            ]}
                            options={{
                                headerStyle: {
                                    backgroundColor: 'rgb(3, 156, 213)',
                                    color: '#FFF',
                                    fontSize: "22px",
                                    textAlign: "left"
                                },
                                actionsColumnIndex: -1
                            }}
                        />
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    )
}
