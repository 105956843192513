import React from "react";
// Import Swiper React components
import "./map.css"
import { Button } from 'react-bootstrap';
import { MdCall } from "react-icons/md";

import './services.css'

export default function Review() {
  return (
    <>
      {/* <h2 className="text-center" style={{ fontSize: "40px", fontWeight: "600" }}>CONTACT US</h2> */}
      <section className="ftco-section" style={{ backgroundImage: "url('/assets/images/Group 606.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover", marginTop: "30px" }}>
        <div className="mt-4">
          <div className="row d-flex justify-content-center mb-5" style={{margin:"20px"}}>
           
            <div className="col-md-5 mt-5">
              {/* <div className="row mb-5">
                <div className="responsive-map">

                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.4470659808608!2d-118.3963054432202!3d33.95534692651024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b0d24d6283df%3A0xb6ac1732df7306e5!2s8923%20S%20Sepulveda%20Blvd%2C%20Los%20Angeles%2C%20CA%2090045%2C%20USA!5e0!3m2!1sen!2s!4v1667459888084!5m2!1sen!2s" height={600} frameBorder={0} style={{ border: 0 }} allowFullScreen referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div> */}
              <h1 className="letgettouch" >Let’s <br /> Get in Touch</h1>
              <Button  onClick={()=>window.open("https://wa.me/13108001075")} variant="outline-dark"  style={{ color: "#000", fontWeight: "400px", borderRadius: "15px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="lg">
              <MdCall size={30}  /> Message Us </Button>{' '}
              {/* <Button variant="btn btn-primary btn-lg btn-block" style={{ color: "#000", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="lg">
                    Submit
                  </Button>{' '} */}
            </div>
            <div className="col-md-7  mt-4 ">
              <div className="row">
                <div className="col-md-6 mb-4" >
                  <input type="text" className="lineinput " placeholder=" Name " style={{ height: "135%" }} />
                </div>
                <div className="col-md-6 mb-4" >
                  <input type="email" className="lineinput" placeholder=" Phone no" style={{ height: "135%" }} />
                </div>
                <div className="col-md-6 mb-4" >
                  <input type="email" className="lineinput" placeholder=" Email " style={{ height: "135%" }} />
                </div>
                <div className="col-md-6 mb-4 form-group" >
                  <select id="inputState" className="lineinput" placeholder=" Enquiry Type" style={{ height: "135%" }}   >
                    <option selected>Enquiry Type</option>
                    <option>one</option>
                    <option>two</option>
                  </select>
                  {/* <input type="select" className="form-control lineinput input-lg" placeholder=" Enquiry Type" style={{ height: "135%" }} /> */}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 mb-4 " >
                {/* style={{ height: "135%",width:"552px" }}  */}
                  <textarea className="lineinput abcc" placeholder=" Message" rows={3} />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 text-center" >
                  {/* <Button className="mx-4" style={{ backgroundCOlor: "#46e9fb" }}>Submit</Button> */}
                  <Button variant="btn btn-primary btn-lg btn-block" style={{ color: "#000", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="lg">
                    Submit
                  </Button>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}