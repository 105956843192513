import './App.css';
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import 'video-react/dist/video-react.css';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import {
  Routes,
  Route,
  BrowserRouter
} from 'react-router-dom';
import SLider from './pages/components/Slider';
import Home from './pages/valetuser/Home';
import ReserveParking from './pages/components/ReserveParking';
import RightSidbar from './pages/components/RightSidbar';
import Register from './pages/auth/register/Register';
import Login from './pages/auth/login/Login';
import Reservation from './pages/components/reservation/Reservation';
import TrackCar from './pages/components/trackcar/TrackCar';
import Points from './pages/components/points/Points';
import Profile from './pages/components/profile/Profile';

import Pricing from './pages/components/pricing/Pricing';
import Reserve from './pages/components/reserve/Reserve';
import Payment from './pages/components/payment/Payment';
import ReservationForm from './pages/components/ReservationForm/ReservationForm';
import PaymentResponse from './pages/components/paymentresponse/PaymentResponse';
import PaymentFailed from './pages/components/paymentfailed/PaymentFailed';
import ContactUs from './pages/components/contacUs/contactUs';
import UserData from './pages/components/userdata/UserData';
import ForgetPassword from './pages/auth/forgetpassword/ForgetPassword';
import VerificationCode from './pages/auth/VerificationCode/VerificationCode';
import NewPassword from './pages/auth/NewPassword/NewPassword';
import IdCard from "./pages/components/idcard/IdCard"
import CustomerPayment from './pages/components/customerpayment/CustomerPayment';
import PaymentSuccess from './pages/components/customerpaymentsuccess/PaymentSuccess';
import Home2 from './pages/valetuser/Home2';
// import PaymentSuccess from './pages/components/customerpaymentsuccess/PaymentSuccess';
import TimeSet from './pages/components/timeset/TimeSet';
import Pdf from "./pages/components/viewver/Pdf"


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/VerificationCode" element={<VerificationCode />} />
        <Route path="/NewPassword" element={<NewPassword />} />
        <Route path="/SLider" element={<SLider />} />
        {/* <Route path="/" element={<Home />} /> */}

        <Route path="/" element={<Home2 />} />
        <Route path="/ReserveParking" element={<ReserveParking />} />
        <Route path="/RightSidbar" element={<RightSidbar />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Reservation" element={<Reservation />} />
        <Route path="/TrackCar" element={<TrackCar />} />
        <Route path="/Points" element={<Points />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Reserve" element={<Reserve />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/ReservationForm" element={<ReservationForm />} />
        <Route path="/PaymentResponse" element={<PaymentResponse />} />
        <Route path="/PaymentFailed" element={<PaymentFailed />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/UserData" element={<UserData />} />
        <Route path="/IdCard" element={<IdCard />} />
        <Route path="/CustomerPayment" element={<CustomerPayment />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/TimeSet" element={<TimeSet />} />
        <Route path="/Pdf" element={<Pdf />} />






      </Routes>
    </BrowserRouter>
  );
}

export default App;
