import { useState } from 'react';
import React from 'react'
import { Card } from 'react-bootstrap'
import { Loader } from "react-overlay-loader";
import Swal from "sweetalert2";
import 'react-overlay-loader/styles.css'
import baseUrl from '../../../baseurl/BaseUrl'
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, Link } from "react-router-dom";
import $ from 'jquery'
import Img from "./Profile.png"
import { Padding } from '@mui/icons-material';
import bgimg from "../Image42.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));

export default function Register() {
  $(".modal-backdrop").hide()
  $("body").removeClass("modal-open")

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [Selectedprofile, setSelectedprofile] = useState("")
  // const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
  const [profileimage, setprofileimage] = useState(Img)
  const Navigate = useNavigate()
// console.log("Selectedprofile",Selectedprofile)
// console.log("profileimage",profileimage)

  // FOR PROFILE UPLOAD CODE
  const handleInputFileChange = (event, func) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    // console.log(file);
    const filesize = file.size
    const filesizeround = Math.round((filesize / 1024))
    if (filesizeround > 1024) {
    
      Swal.fire({
        title: "Opps",
        text: "plz select your image less then and equals to 1mb",
        icon: "warning"
      });
    } else {
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setSelectedprofile(reader.result);
        // setUpdateThumbnail(file);
      };
      func(event.target.files[0])
    }
  }


  const Register = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("fname", lname);
    formData.append("lname", lname);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("contact", contact);
    formData.append("profile", profileimage);

    var requestOptions = {
      method: 'POST',
      // headers: {
      //     // Authorization: "Bearer " + Token
      // }
      body: formData,
    };
    fetch(`${baseUrl.baseUrl}webapi/customerRegister`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status) {
          setFname("")
          setLname("")
          setAddress("")
          setContact("")
          setPassword("")
          setEmail("")
          // setSelectedprofile(null)
          Navigate("/Login")
          // console.log("get register==========>", result);
          Swal.fire({
            title: "success",
            text: result.message,
            icon: "success",
            confirmButtonColor: "#03bafe",
          })
        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }

  return (
    <>
      {/* ... */}
      {loader? <Loader fullPage loading /> : null}
      <section >
        <div className="container">
          {/* <div className=" text-right heading-section  mt-4">
          <p className="title">Already have an account? <a href='#'>Login</a></p>
        </div> */}
          {/* <div className="row " style={{ marginBlock: "10%" }}> */}
          <div className="row">
            <div className="col-md-6" >
              <Card className='align-items-center d-flex justify-content-center' style={{ minHeight: "30rem", padding:"20px 30px 25px 20px"}} >
                {/* <Card.Header> */}
                {/* </Card.Header> */}
                  <img src='assets/images/newlogo.png' className="img-fluid" width="25%" alt={""}/>
                <Card.Body>
                  <Card.Text>
                    <h3 style={{ fontSize: "2rem" }}>Manage the job more effectively with Valet</h3>
                  <img  src={bgimg} className="img-fluid" style={{width:"90%"}} alt={""}/>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-6" >
              <form action="#" className=" contact-form">

                <div className=" text-center heading-section ">
                  <h3 className="title">Get started absolutely free</h3>
                </div>

                <div className="form-row text-center " style={{ display: "block" }}>


                  <div className="row">
                    <div className="col-md-12 rounded-circle">
                      <center>
                        <div className="upload_photo_main photo rounded-circle" >
                          <input
                            onChange={(e) => handleInputFileChange(e, setprofileimage)}

                            accept="image/*"
                            className={classes.input}
                            id="icon-button-file"
                            type="file"

                          />
                          <label htmlFor="icon-button-file">
                            <IconButton
                              color="#414141"
                              aria-label="upload picture"
                              component="span"
                              className="upload_photo_main "
                            >
                              <img src={Selectedprofile ? Selectedprofile : profileimage} style={{ minWidth: "130px", maxWidth: "130px", borderRadius: "20px" }} className="rounded-circle upload_photo_main" alt={""}/>
                            </IconButton>

                          </label>


                        </div>

                      </center>
                    </div>
                  </div>


                </div>

                <div className="form-group mb-2">
                  <input type="text" value={fname} onChange={(e) => setFname(e.target.value)} className="form-control" placeholder="First Name" />
                </div>

                <div className="form-group mb-2">
                  <input type="text" value={lname} onChange={(e) => setLname(e.target.value)} className="form-control" placeholder="last Name" />
                </div>
                <div className="form-group mb-2">
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
                </div>
                {/* <div className="form-group">
                  <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control" placeholder="Address" />
                </div> */}

                <div className="form-group mb-2">
                  <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
                </div>

                <div className="form-group mb-2">
                  <input type="text" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control" placeholder="Contact number" />
                </div>



                <div className="form-group mb-2">

                  <button type="button" className=" btn-lg btn-block" onClick={() => Register()} style={{ backgroundColor: "#1089ff", color: "white", border: "solid 2px #1089ff" }}>Register</button>
                </div>

                <div className="form-group text-center">
                  <h5 className="title">Already have an account? <Link to="/Login">Login</Link></h5>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}
