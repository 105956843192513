import React, { useState, useEffect } from 'react'

// import Header2 from "../../layout/Header2"
import Header3 from "../../layout/Header3"
import Footer from "../../layout/Footer"
import Slidar3 from "../../components/slider3"
// import Slidar2 from "../../components/slider2"
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import { ArrowDownward } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import baseUrl from '../../../baseurl/BaseUrl'
import { useNavigate } from "react-router-dom";
import { Loader } from "react-overlay-loader";
import Rating from '@mui/material/Rating';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { MdPending } from 'react-icons/md';





export default function UserData() {
    const [GetReservations, setGetReservations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [value, setValue] = useState("");
    const [id, setId] = useState("")
    const [review, setReview] = useState("");
    const [star, setStar] = useState("");
    const [reason, setReason] = useState("");
    const [fname, setFname] = useState("")
    const [profile, setProfile] = useState("")
    const [data2, setData2] = useState([])

        ;


    const navigate = useNavigate()
    // Add MOdal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }
    const Token = localStorage.getItem("Token")


    useEffect(() => {
        if (Token != null) {

            GetServices()
            GetServices2()
        }

    }, []);

    const GetServices = () => {

        // setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        setLoader(true)
        fetch(`${baseUrl.baseUrl}webapi/customerbooking`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {

                    console.log("resultdata", result.data)
                    setGetReservations(result.data);
                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/Login")
                    } else {
                        Swal.fire({
                            title: "Oops",
                            text: result.message,
                            icon: "error",
                            confirmButtonColor: "#03bafe",
                        });
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });



    }


    // GETsERVICES
    const GetServices2 = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=customer`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("result in home3", result)
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("result.data", result.data)

                    setData2(result.data)
                    setFname(result.data.fname)
                    setProfile(result.data.profile)




                }
                // else {
                //     setLoader(false)
                //     Swal.fire({
                //         title: "opps",
                //         text: result.message,
                //         icon: "error"
                //     })



                // }
            }).catch(function (e) {
                setLoader(false)
                Swal.fire({
                    title: "opps!",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    const Ratting = () => {


        setLoader(true)
        if (value == "" || review == "") {
            console.log("IFFF")
            setLoader(false)
            Swal.fire({
                title: "Oops",
                text: "All keys are required",
                icon: "error",
                confirmButtonColor: "#03bafe",
            });

        }
        else {

            setLoader(true)

            const form = new FormData()
            form.append("id", id)
            form.append("stars", value)
            form.append("review", review)
            var requestOptions = {
                method: 'POST',

                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/rating?role=customer `, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status) {
                        GetServices()
                        handleClose()
                        setReview("")
                        setValue("")
                        Swal.fire({
                            title: "success",
                            text: result.message,
                            icon: "success",
                            confirmButtonColor: "#03bafe",
                        });


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/Login")
                        } else {
                            setShow(true)
                            Swal.fire({
                                title: "Oops",
                                text: result.message,
                                icon: "error",
                                confirmButtonColor: "#03bafe",
                            });


                        }
                    }
                })
                .catch(error => {
                    setShow(true)
                    setLoader(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error,
                        confirmButtonColor: "#03bafe",
                    })

                });

        }

    }

    const handleDownload = (url) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                // Blob data ka URL create karein
                const pdfURL = window.URL.createObjectURL(new Blob([blob]));

                // Download link create karein
                const a = document.createElement('a');
                a.href = pdfURL;
                a.download = 'BookingInvoice.pdf'; // PDF file ka naam
                document.body.appendChild(a);

                // Download button par click karein
                a.click();

                // URL object revoke karein
                window.URL.revokeObjectURL(pdfURL);
            })
            .catch((error) => console.error('PDF download error:', error));
    };




    return (
        <div>
            {/* <Header2 /> */}
            {

                Token == null ?

                    <div className='bluediv'  >
                        <div className="bs-example px-3 py-3">



                            <div className="clearfix fss">
                                <Button onClick={() => navigate("/Register")} className='  float-right ml-2 ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Register Now
                                </Button>{' '}
                                <Button onClick={() => navigate("/Login")} className='float-right ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                    Sign In
                                </Button>{' '}
                            </div>


                        </div>
                    </div>

                    :
                    <div className='bluediv' style={{ height: "64px" }} >
                        <div className="bs-example px-3 py-3">



                            <div className="clearfix fss">

                                <h3 className='float-right  mx-5 ' >
                                    <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" />
                                </h3>
                                <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome {fname}</h3>




                            </div>


                        </div>
                    </div>

            }
            <Header3 />

            <Slidar3 />
            {loader ? <Loader fullPage loading /> : null}



            <section className="ftco-section">
                <div className="container mt-4">
                    <div className=" text-center heading-section ">
                        <h2 className="title">Customer Reservations</h2>
                    </div>
                    <div className="row text-center mt-5">

                        <MaterialTable

                            title=""
                            icons={{
                                Check: Check,
                                DetailPanel: ChevronRight,
                                // Delete: DeleteOutline,
                                Export: SaveAlt,
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                ThirdStateCheck: Remove,
                                Add: Add,
                                SortArrow: ArrowDownward,
                                Clear: Clear,
                                Edit: Edit,
                                ViewColumn: ViewColumn,
                                ResetSearch: Clear
                            }}
                            columns={[
                                { title: 'Customer ', field: 'customername' },
                                { title: 'Pickup ', field: 'pickupridername', render: rowData => rowData.pickupridername == null ? "-" : rowData.pickupridername },
                                { title: 'Drop ', field: 'dropridername', render: rowData => rowData.dropridername == null ? "-" : rowData.dropridername },
                                { title: 'Service ', field: 'servicename' },
                                { title: 'Departure ', field: 'departure_airline' },
                                { title: 'Return ', field: 'return_airline' },
                                { title: 'Start ', field: 'departure_airline_time', render: rowData => tConvert(rowData.departure_airline_time) },
                                { title: 'End  ', field: 'return_airline_time', render: rowData => tConvert(rowData.return_airline_time) },
                                { title: ' Amount', field: 'totalamout', render: rowData => ("$" + rowData.totalamout.toFixed(2)) },
                                {
                                    title: 'Slip', render: (rowData) =>


                                        <>
                                            {rowData.payslip != null ?
                                                <Button type='button' onClick={() => handleDownload(rowData.payslip)} style={{ backgroundColor: "green", color: "white" }}  >Download</Button> :
                                                <Button type='button' style={{ backgroundColor: "green", color: "white" }}  ><MdPending /></Button>
                                            }

                                        </>

                                },

                            ]}
                            data={GetReservations}
                            // actions={[

                            //     rowData => ({
                            //         hidden: rowData.status != "pending",
                            //         icon: AutorenewIcon,
                            //         tooltip: 'Pending ',
                            //         onClick: (event, rowData) => {
                            //             console.log("rowDATA HIATORY pending", rowData)


                            //         }
                            //     }),


                            //     rowData => ({
                            //         hidden: rowData.status != "accept",
                            //         icon: AccessTimeIcon,
                            //         tooltip: 'Accept ',
                            //         onClick: (event, rowData) => {
                            //             console.log("rowDATA HIATORY accept", rowData)


                            //         }
                            //     }),





                            // ]}

                            options={{
                                headerStyle: {
                                    backgroundColor: '#1d95d2',
                                    color: '#FFF'
                                },
                                actionsColumnIndex: -1,
                                exportButton: true
                            }}
                        />


                    </div>
                </div>
            </section>

            <Footer />

            {/* RATING MODAL  */}




            <Modal show={show} onHide={handleClose} dialogClassName="border-radius-2" >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(3,156,213)", }}>
                    <Modal.Title style={{ color: "white" }}>Rating</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>

                        <div className='col-md-12 text-center'>
                            {/* <Typography component="legend">Controlled</Typography> */}
                            {star == 0 ? <Rating
                                name="simple-controlled"
                                value={value}
                                size="large"
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                            /> : <Rating
                                name="simple-controlled"
                                value={star}
                                size="large"
                                readOnly
                            //   onChange={(event, newValue) => {
                            //     setValue(newValue);
                            //   }}
                            />}



                        </div>

                        <div className='col-md-12'>
                            <p style={{ fontWeight: "900" }} >Give Your Feedback</p>
                            {reason == "" ?
                                <textarea value={review} className="form-control" onChange={(e) => setReview(e.target.value)} id="w3review" name="w3review" rows="6" cols="55"></textarea> :
                                <textarea value={reason} className="form-control" id="w3review" name="w3review" rows="6" cols="55"></textarea>

                            }
                        </div>

                    </div>



                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "rgba(3,156,213)" }}>
                    {/* <Button style={{ color: "black", backgroundColor: "white" }} variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                    {star == 0 ? <Button variant="secondary" style={{ color: "black", backgroundColor: "white" }} onClick={() => Ratting()}>
                        Submit
                    </Button> : null}

                </Modal.Footer>
            </Modal>


        </div>
    )
}
