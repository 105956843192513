import React, { useState, useEffect } from "react";
import baseUrl from '../../baseurl/BaseUrl'
import Swal from "sweetalert2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import Rating from '@mui/material/Rating';
// swiper bundle styles
// import 'swiper/swiper-bundle.min.css'
// swiper core styles
// import 'swiper/swiper.min.css'
import "./rating2.css";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from '@mui/material/Rating';
import { BsFillArrowLeftSquareFill,BsFillArrowRightSquareFill } from 'react-icons/bs';
export default function RatingNewtab() {
    const [loader, setLoader] = useState(false);

    const testinomialarray = [
        {
            name: "Clark kent",
            Review: "It was a great Experience!",
            description: "I have used your service for some years and always found it reliable and reasonable.Recently, two of your employees did a little extra for me.  When I was brought home on Sat., Jan. 20, a book slipped out of a side pocket of my bag and was left in the trunk.  When I realized what must have happened, I phoned the dispatcher on Monday morning.  Within an hour, she called to say that the driver had the book and that he would return it when in the area.I had it later in the day.Thanks to both people for their help."

        },
        {
            name: "Mark Denr",
            Review: "Always Found It Reliable And Reasonable",
            description: "I highly recommend LAX valet parking to anyone looking for a stress-free travel experience. Their staff was courteous and helpful, and I appreciated the peace of mind that came with knowing my car was secure and well taken care of while I was out of town."

        },
        {
            name: "Drake Tren",
            Review: "I Find Their Service High Class",
            description: "As a person who likes to travel a lot, I have used many taxi companies and other community services but, unlike any other, Airflight has by far been the best. When the car arrived in front of my doorstep, I would expect a regular car that one might find on a common cab, but I ended up with a nice Lincoln SUV."

        },
        {
            name: "John",
            Review: "I Find Their Cool ",
            description: "I have trusted Airflight Services on several occasions over the past 7 years to get me to the airport with no hitches or worries on my end. I do not travel a lot, but I do recognize and appreciate a good transportation service when I find it. Taxi provides excellent service with friendly interactions – and their fleet appears to be regularly maintained, which is something that I look for in public transportation. Thank you for providing this much-needed service!"

        },
      
    ]
    const [reservation, setReservation] = useState([])
    const [desc, setdesc] = useState(testinomialarray[1].description)
    const [review, setreview] = useState(testinomialarray[1 ].Review)
    const [name, setName] = useState(testinomialarray[1 ].name)
    const [checkindex, setCheckindex] = useState(1)

console.log("checkindex",checkindex)
    const [counter, setcounter] = useState(-1)


    const settings = {
        dots: false,
        infinite: false,
        // Autoplay:true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        // Arrow: true,
        nextArrow: <SampleNextArrow  />,
        prevArrow: <SamplePrevArrow />
    };

    const ReviewShow = (index) => {
        console.log("check index", index, testinomialarray[index])

        setdesc(testinomialarray[index].description)
        setreview(testinomialarray[index].Review)
        setName(testinomialarray[index].name)

    }
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
       

        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            />

        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;

        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "green" }}
                onClick={onClick}
            />
        );
    }




    const handlePrevArrowClick = () => {
        console.log("prev arrow clicked!");
        if (checkindex > 0){
        ReviewShow(checkindex -1)
    setCheckindex(checkindex-1)
   
      }};

      const handleNextArrowClick = () => {
        console.log("Next arrow clicked!");
        if (checkindex < 3){
            ReviewShow(checkindex +1)
            setCheckindex(checkindex+1)      
        }
      };
      
      
    

    useEffect(() => {

        GetReservation()
    }, [])


    const GetReservation = () => {

        // setLoader(true);
        var requestOptions = {
            method: 'GET',
            // headers: {
            //     Authorization: "Bearer " + Token
            // }
        };
        fetch(`${baseUrl.baseUrl}webapi/recentReservation`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    // setData(result.category)
                    setReservation(result.data)


                } else {
                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }
            }).catch(error => {
                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });

    }





    return (
        <>
            <section className="ftco-section">

                {/* safdar work */}
                {/* <div className="row mx-2"> */}
                <div className="row mx-3">
                    <div className='col-md-12  mt-3'>


                        <Slider {...settings} style={{display:"none"}}>

                            {
                                testinomialarray.map((data, index) => {
                                    return (
                                        <>
                                          
                                            {/* <div> */}

                                            <div className="item-reservation" onClick={() => ReviewShow(index)}  >
                                                <div className="testimony-wrap rating  text-center  py-4  p-3 mb-5 bgmycss    ">
                                                    <div className="row">
                                                        <div className="col-md-12">

                                                            {/* <p ><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />{data.servicename}</p> */}

                                                            <p >
                                                                <img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />
                                                                <span className="reservation-span abcpr"  >
                                                                    {data.name}
                                                                    <p className="text-center reservationpr">Sales Manager   </p>
                                                                </span>
                                                            </p>
                                                            {/* <p className="text-center reservationp">Salas Manager   </p> */}

                                                            {/* <p className="text-left reservationp" >Reservation Date : {data.departure_airline} </p> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            {/* </div> */}

                                        </>
                                    )
                                })

                            }







                        </Slider>






                    </div>
                    <div className="row">
                        <div className='col-md-2 icon-left' >
                            <BsFillArrowLeftSquareFill size={50} className="mx-2 abb" onClick={handlePrevArrowClick} style={{marginTop:"60%"}}/>
                            <spna className="showicon" style={{display:"none"}}>
                        <BsFillArrowRightSquareFill size={50}  onClick={handleNextArrowClick} /></spna>

                        </div>
                        <div className="col-md-8 text-center">
                            <h5>{name}</h5>
                            <p className='reviewheading' >{review}</p>
                            <Rating className='wemeetp mx-3' name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                            <p className='reviewdescription' >{desc}</p>

                        </div>
                        <div className='col-md-2 text-right icon-right' >
                        <BsFillArrowRightSquareFill size={50}  onClick={handleNextArrowClick} style={{marginTop:"60%"}}/>

                        </div>




                    </div>


                </div>



                {/* safdar end */}

                {/* </div> */}
            </section>


        </>
    )
}