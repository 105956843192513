import React from 'react'
import Header from "../../layout/Header"
import Footer from "../../layout/Footer"
import Slidar from "../../components/Slider"
import { SliderData } from '../../utils/sliderData';


export default function TrackCar() {
  return (
    <div>
      <Header />
      <Slidar data={SliderData.home} />

      <section className="ftco-section">
        <div className="container-fluid mt-2">

          <div className="responsive-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2822.7806761080233!2d-93.29138368446431!3d44.96844997909819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b32b6ee2c87c91%3A0xc20dff2748d2bd92!2sWalker+Art+Center!5e0!3m2!1sen!2sus!4v1514524647889" width={700} height={500} frameBorder={0} style={{ border: 0 }} allowFullScreen />
          </div>
          <div className='container text-center mt-4'>
            <div className='row'>
              <div className='col-md-3 '>
                < div className="form-group">
                  <input type="text" className="form-control" placeholder="Licence Plate" style={{ border: "3px solid grey" }} />
                </div>


              </div>
              <div className='col-md-4'>
                <button style={{ backgroundCOlor: "rgba(3, 156, 213)" }} type="button" classname="btn btn-primary btn-lg">Track My Car</button>

                {/* <Button style={{ backgroundCOlor: "rgba(3, 156, 213)" }}>Track My Car</Button> */}

              </div>

            </div>
          </div>
        </div>
      </section>

      < Footer />
    </div>
  )
}
