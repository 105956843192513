import React, { useState } from "react";
import RightSidbar from "../components/RightSidbar";
import { Link } from 'react-router-dom';

import "./Headercss.css";
// import "./sliderstyle.css";

export default function Header() {
	const [ShowHide, setShowHide] = useState("d-none")
	// const [Top, setTop] = useState("0px")
	
	const Token = localStorage.getItem("Token")
	console.log("Token",Token)
	window.addEventListener("scroll", (event) => {
		let scroll = window.pageYOffset

		 console.log("scroll",scroll)
		if (scroll > 300) {
			setShowHide("d-block")
		} else {
			setShowHide("d-none")
		}
		// console.log("scroll", scroll)
	});
	window.addEventListener("resize",(event)=>{
		// console.log("window",window.innerWidth)
		if(window.innerWidth <= 1200){
			// setTop("-67px")
		}else{
			// setTop("0px")
		}
	})
	return (
		<>
	 <div class="alert  alert-warning alert-dismissible text-center" style={{marginBottom:"0px",color:"#FFFFFF"}}>
    <button type="button" class="close" data-dismiss="alert">&times;</button>
      Call 15 minutes before arrival at your departing terminal.
  </div>
			<section className="" style={{ position: "absolute" , width:"100%"}}>
				
				<div className="container-fluid">
				<div className="row">
					<div className="col-xl-5">
						<nav className="navbar  navbar-expand-lg navbar-dark ftco_navbar  ftco-navbar-light" id="ftco-navbar"  >
							<Link className="navbar-brand" to="/"><img src="./assets/images/logo2.png" className="img-fluid" width="40%" height="100px" alt={""}/></Link>
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
								<span className="oi oi-menu"></span> Menu
							</button>
						</nav>
					</div>
					<div className="col-xl-7">
						<nav className="navbar navbar-expand-lg navbar-dark ftco_navbar  ftco-navbar-light" id="ftco-navbar" style={{ backgroundColor: "rgb(3 156 213 / 62%)"}} >
							{/* <div className="container"> */}
							<Link to="/" className={`${ShowHide} navbar-brand`}><img src="./assets/images/logo2.png" className="img-fluid" width="40%" height="100px" alt={""}/></Link>
							{/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
								<span className="oi oi-menu"></span> Menu
							</button> */}
							<div className="collapse navbar-collapse" id="ftco-nav">
								<ul className="navbar-nav ml-auto">
									<li className="nav-item active"><Link to="/" className="nav-link">Home</Link></li>
									<li className="nav-item"><Link to="/Reserve" className="nav-link">Reservations</Link></li>
									{/* <li className="nav-item"><Link to="/Pricing" className="nav-link">Services</Link></li> */}
									{/* <li className="nav-item"><Link to="/Points" className="nav-link">Points</Link></li> */}
									<li className="nav-item"><Link to="/UserData" className="nav-link">History</Link></li>

									{Token != null? <li className="nav-item"><Link to="/Profile" className="nav-link">Profile</Link></li>: "" }
									
									<li className="nav-item"><Link to="/contact" className="nav-link">Contact Us</Link></li>
									<li className="nav-item mt-2"><i className="fa fa-bars nav-link" data-toggle="modal" data-target="#exampleModalRight"></i></li>
									{/* <li className="nav-item"><span className="nav-link fa-regular fa-slidebar" data-toggle="modal" data-target="#exampleModalRight">Sidbar</span><i classname="fa-regular fa-sidebar"></i></li> */}
								</ul>
								{/* </div> */}
							</div>
						</nav>
					</div>
					<RightSidbar />
				</div>
				</div>
			</section>

		</>
	)
}