import React, { useState } from 'react'
import { Loader } from "react-overlay-loader";
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import 'react-overlay-loader/styles.css'
import baseUrl from '../../../baseurl/BaseUrl'
import { useNavigate } from "react-router-dom";
import $ from 'jquery'
import cardimg from "../valetpic.png"
const ForgetPassword = () => {

    const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [id, setmyid] = useState('')
    const Navigate = useNavigate()

    const SendEmail = () => {

        var formdata = new FormData();
        formdata.append("email", email);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                Accept: 'application/json'
            },
            redirect: 'follow'
        };

        setLoader(true)

        fetch(`${baseUrl.baseUrl}webapi/forgotPasswordlinkSend?role=customer`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false)
                console.log("result", result)
                if (result['status'] === true) {
                    Swal.fire({
                        title: "success",
                        text: result.message,
                        icon: "success",
                        confirmButtonColor: "#03bafe",
                    })
                    setmyid(result.id)
                    Navigate("/VerificationCode", { state: { userid: result.id } })
                }
                else {
                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }

            }




            )
            .catch(error => {
                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });

    }

    return (
        <>
            {loader ? <Loader fullPage loading /> : null}
            <section className='abcd' style={{ backgroundImage: "url('/assets/images/register.jpeg')", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "cover", position: "absolute", width: "100%" }}>
                <div className="container ">
                    <div className="row " style={{ marginBlock: "10%" }}>
                        <div className="col-md-5 ">
                            <Card className='align-items-center d-flex justify-content-center' style={{ minHeight: "30rem", padding: "20px 30px 25px 20px" }}>
                                <img src='assets/images/newlogo.png' className="img-fluid" width="25%" alt={""} />
                                <Card.Body>

                                    {/* <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle> */}
                                    <Card.Text>
                                        <h3 style={{ fontSize: "2rem" }}>Hi, Welcome Back</h3>
                                        <img src={cardimg} className="img-fluid" style={{ width: "80%" }} alt={""} />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6  " style={{ marginTop: "6%" }} >
                            <form action="#" className=" p-3 contact-form">
                                <div className="  heading-section mb-5 ">
                                    <h3 className="title">Sign in to Valet Services</h3>
                                    <h6 className="">Enter your details below.</h6>
                                </div>
                                <div className="form-group">
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email address" />
                                </div>


                                <div className="form-group">
                                    <button type="button" className=" btn-lg btn-block" onClick={() => { SendEmail() }} style={{ backgroundColor: "#1089ff", color: "white", border: "solid 2px #1089ff" }}>Send Email</button>
                                </div>
                                {/* <div className="form-group text-center" >
                                    <h5 className="title">Don't have an account? <Link to="/Register">Get Started</Link></h5>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgetPassword