import React from 'react'
import Header from "../../layout/Header"
import Footer from "../../layout/Footer"
import Slidar from "../../components/Slider"
import { SliderData } from '../../utils/sliderData'

export default function Points() {
  return (
    <div>
            <Header />
            <Slidar data={SliderData.home}/>



            <section className="ftco-section">
            <div className="container-fluid mt-4">
            <div className=" text-center heading-section ">
          <h2 className="title">POINTS COLLECTION</h2>
        </div>
      <div className="row text-center mt-5">
<div className='col-md-12'>
<h3 style={{fontSize:"60px",fontWeight:"400"}}>You have earned</h3>
<span style={{fontSize:"50px",fontWeight:"300"}}><i style={{fontSize:"120px",fontWeight:"600", color:"rgb(3, 156, 213)"}}>120 </i >Points</span>
<h4 style={{color:"grey"}}>Subscribe more services and get points (discount for their next purchase)Min.points 500</h4>
</div>
            
            </div>  
 </div>
</section>

            <Footer />

        </div>
  )
}
