import React, { useEffect, useState } from 'react'
import Header3 from "../../layout/Header3"
import Footer from "../../layout/Footer"
import Slidar3 from "../slider3"
import "./payment.css"
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
import baseUrl from '../../../baseurl/BaseUrl';
import { TextField, Typography } from '@mui/material'
// import MuiPhoneNumber from 'material-ui-phone-number';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateForm, AmountData } from '../../../redux/actions/counterActions';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { InputAdornment } from '@mui/material';
import { SliderData } from '../../utils/sliderData'
import { DropzoneArea } from 'material-ui-dropzone'
import { Loader } from "react-overlay-loader";
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import S3FileUpload, { deleteFile } from "react-s3"
import config from '../../../s3bucket/s3_config';
import { GrAddCircle } from 'react-icons/gr'
import { AiOutlineMinusCircle, AiFillDelete } from 'react-icons/ai'
window.Buffer = window.Buffer || require("buffer").Buffer;




export default function ReservationForm() {
  const Token = localStorage.getItem("Token")
  const [countrycode, setCountrycode] = useState("+1")
  const [phonenumber, setPhonenumber] = useState("")
  const [allvalues, setAllvalues] = useState([])
  const [phoneError, setPhoneError] = useState(false)
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const location = useLocation()


  const [startdate, setstartdate] = useState(newdate);
  const [enddate, setenddate] = useState(newdate);


  // Modal 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);

  const [loading, setLoading] = React.useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploaddata, setUploaddata] = useState([]);


  // NEW WORK ADDITIONAL SERVICES 
  const store = useSelector(store => store)
  console.log("store reservation form", store)
  const [services, setservices] = useState([]);
  const [Ids, setIds] = useState([]);
  const [AmountObj, setAmountObj] = useState({})
  const [citytax, setCitytax] = useState(store.counter.amount.state.citytax)
  const [servicename, setService] = useState(store.counter.amount.state.servicename)
  const [discount, setDiscount] = useState(store.counter.amount.state.discount)


  console.log("store.counter.amount.state", store.counter.amount.state)
  const [totalamount, setTotalamount] = useState(store.counter.amount.state.discount_price > 0 ? store.counter.amount.state.discount_price : store.counter.amount.state.totalamout)
  console.log("totalamount", totalamount)
  const [subtotal, setSubtotal] = useState(store.counter.amount.state.subtotalamout)
  const [parking, setParking] = useState(store.counter.amount.state.parking)
  const [cardcharge, setCardcharge] = useState(store.counter.amount.state.credit_card_fee)
  const [transectionfee, setTransectionfee] = useState(store.counter.amount.state.transaction_fee)
  const [evamount, setEvamount] = useState(0);
  const [gasamount, setGasamount] = useState(0);
  const [addiev, setAddiev] = useState("");
  const [addigas, setAddigas] = useState("");
  const [totalrs, setTotalrs] = useState([])
  const [active1, setActive1] = useState(false);
  const [tableactive, setTableactive] = useState("");

  const [item, setitem] = useState([])
  const [addit, setAddit] = useState([])
  const [ModalData, setModalData] = useState({});

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [show1, setShow1] = useState(false);

  console.log("form data state", store.counter)
  console.log("form data amount", store.counter.amount.state)
  const Consubamount = (amount) => {
    return Number(amount).toFixed(2)
  }

  const Con = (amount) => {
    return Number(amount).toFixed(2)
  }
  const CheckArry = (e) => {

    console.log("check e", e)


    const data = addit.filter(item => item !== e)
    setAddit(data)
    console.log("arr", data)
    return data
    // [ 1, 2, 4, 5 ]



  }

  useEffect(() => {
    const filtered = location.state.services.filter((ss) => ss.id == location.state.selectedId)
    let filteredArr = []
    if (filtered.length > 0) {
      if (filtered[0].category_name === "Additional services") {
        const filtered2 = location.state.services.filter((ss) => ss.id !== location.state.selectedId)
        setservices(filtered2)

        filteredArr = filtered2
      } else {
        setservices(location.state.services)
      }
    } else {
      setservices(location.state.services)
    }
    if (location.state.selectedId !== null) {
      if (filtered.length > 0) {
        if (filtered[0].category_name !== "Additional services") {
          setIds(Ids => [...Ids, location.state.selectedId])
        }
      }
    }

    setAmountObj(store.counter.amount.state)




  }, [])



  const addServices2 = (e) => {

    console.log("check add 2", e)

    // setActive1(false)
    setShow1(false)
    let addi = e.price
    // setAddicharges(addi)
    // setAddicharges(e.price)

    const newState = services.map(obj => {
      console.log("add 2 e", e)
      // 👇️ if id equals 2, update country property
      if (obj.id === e.id) {
        setIds(Ids => [...Ids, obj.id]);
        let state = { ...AmountObj, subtotalamout: AmountObj.subtotalamout + obj.price, totalamout: AmountObj.totalamout + obj.price, additionalid: [...Ids, obj.id] }
        setAmountObj(state)
        dispatch(AmountData({ state }))
        return { ...obj, selectedId: e.id };
      }
      // if (addit.includes(e.servicename)) {
      //   console.log("check inclures", addit.includes(e.servicename))


      // } else {

      //   setAddit([...addit, e.servicename])
      // }
      if (active1 == false) {
        let ite = [...item, { name: e.servicename, price: parseFloat(evamount) + addi, id: e.id }]
        const newservicename = ite.map((ser, index) => {
          return ser.name

        })
        GetBooking(newservicename)
        setAddit(newservicename)
        setitem(ite)



      } else if (active1 == true) {

        let ite = [...item, { name: e.servicename, price: parseFloat(gasamount) + addi, id: e.id }]
        const newservicename = ite.map((ser, index) => {
          return ser.name

        })
        GetBooking(newservicename)
        setAddit(newservicename)
        setitem(ite)
        setActive1(false)


      }

      // const newservicename =  ite.map((ser,index)=>{
      //   return ser.name

      // })



      return obj;
    });
    setservices(newState);
  }

  const addServices = (e) => {
    setTableactive("")

    if (e.servicename === "evcharge") {
      console.log("toggle1", e)
      setAddiev(e.price)
      setShow1(true)

      setModalData(e)
    }
    else if (e.servicename == "gas service fee") {
      setActive1(true)
      setAddigas(e.price)
      setShow1(true)
      setModalData(e)
    }
    else {
      console.log("else")
      const newState = services.map(obj => {
        // 👇️ if id equals 2, update country property
        if (obj.id === e.id) {
          setIds(Ids => [...Ids, obj.id]);
          let state = { ...AmountObj, subtotalamout: AmountObj.subtotalamout + obj.price, totalamout: AmountObj.totalamout + obj.price, additionalid: [...Ids, obj.id] }
          console.log("state add item", state)
          // setTotalamount(AmountObj.totalamout + obj.price)
          setAmountObj(state)
          dispatch(AmountData({ state }))

          return { ...obj, selectedId: e.id };
        }

        return obj;
      });

      let ite = [...item, { name: e.servicename, price: e.price, id: e.id }]

      const newservicename = ite.map((ser, index) => {
        return ser.name

      })

      console.log("After processing, newservicename is", newservicename);
      console.log("Before GetBooking call", ite);
      GetBooking(newservicename);
      console.log("After GetBooking call");
      setAddit(newservicename)
      setservices(newState);
      setitem(ite)


    }


    console.log("check e", e.servicename)



  }

  const removeServices = (e) => {
    setTableactive("table-active")
    let sername = CheckArry(e.servicename)
    console.log("e.servicename", sername)
    console.log("remove e", e.servicename)
    if (e.servicename == "evcharge") {
      setEvamount(0)
      setAddiev("")
      GetBooking(sername, "evamount")

    }
    else if (e.servicename == "gas service fee") {
      setGasamount(0)
      setAddigas("")
      GetBooking(sername, "gasamount")

    }
    else {
      GetBooking(sername)

    }

    //  GetBooking(sername)
    const newState = services.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.id === e.id) {
        const newIds = Ids.filter((e) => e !== obj.id);
        setIds(newIds);
        let state = { ...AmountObj, subtotalamout: AmountObj.subtotalamout - obj.price, totalamout: AmountObj.totalamout - obj.price, additionalid: newIds }

        setAmountObj(state)
        dispatch(AmountData({ state }))
        return { ...obj, selectedId: "" };
      }

      setitem((pre) => pre.filter((previtem) => previtem.id !== e.id))
      console.log("newState", e.servicename)

      // 👇️ otherwise return object as is
      return obj;
    });
    console.log("newstate", newState)
    setservices(newState);
  }

  const titleCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }



  const GetBooking = (arr, type) => {

    console.log("getboooking", location.state.startdate, location.state.enddate, location.state.finalstime, location.state.finalendtime, store.counter.amount.state.serviceId)
    setLoader(true);
    setLoader(true);
    var requestOptions = {
      method: 'GET',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
    };
    if (store.counter.amount.state.discount_price == 0) {



      fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${store.counter.amount.state.serviceId}&startdate=${location.state.startdate}&enddate=${location.state.enddate}&starttime=${location.state.finalstime + ":00"}&endtime=${location.state.finalendtime + ":00"}&additional=${JSON.stringify(arr)}&gas=${type == "gasamount" ? 0 : parseFloat(gasamount) + addigas}&evcharge=${type == "evamount" ? 0 : parseFloat(evamount) + addiev}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);
          if (result.status) {
            console.log("payment", result.data)
            // setButtonstate(true)
            // setBookingdata(result.data)
            setTotalrs(result.data)
            setCitytax(result.data.citytax)
            setSubtotal(result.data.subtotalamout)
            setTotalamount(result.data.totalamout)
            setTransectionfee(result.data.transaction_fee)
            setParking(result.data.parking)
            setCardcharge(result.data.credit_card_fee)
            // dispatch(updateForm({ state: {gasamount:gasamount,evamount:evamount } }))

          } else {

            // setBookingdata("")
            Swal.fire({
              title: "Oops",
              text: result.message,
              icon: "error",
              confirmButtonColor: "#03bafe",
            });
          }
        }).catch(error => {
          setLoader(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });
    } else {
      console.log("store.counter.amount.state.appromo", store.counter.amount.state.appromo)
      fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${store.counter.amount.state.serviceId}&startdate=${location.state.startdate}&enddate=${location.state.enddate}&starttime=${location.state.finalstime + ":00"}&endtime=${location.state.finalendtime + ":00"}&additional=${JSON.stringify(arr)}&gas=${type == "gasamount" ? 0 : parseFloat(gasamount) + addigas}&evcharge=${type == "evamount" ? 0 : parseFloat(evamount) + addiev}&amount=${store.counter.amount.state.discount_price}&promo_code=${store.counter.amount.state.appromo}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);
          if (result.status) {
            console.log("payment promo", result.data)
            // setButtonstate(true)
            // setBookingdata(result.data)
            setTotalrs(result.data)
            setCitytax(result.data.citytax)
            setSubtotal(result.data.subtotalamout)
            setTotalamount(result.data.discount_price)
            setTransectionfee(result.data.transaction_fee)
            setParking(result.data.parking)
            setCardcharge(result.data.credit_card_fee)
            setDiscount(result.data.discount)
            // dispatch(updateForm({ state: {gasamount:gasamount,evamount:evamount } }))

          } else {

            // setBookingdata("")
            Swal.fire({
              title: "Oops",
              text: result.message,
              icon: "error",
              confirmButtonColor: "#03bafe",
            });
          }
        }).catch(error => {
          setLoader(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });


    }
  }



  const dateObj = new Date()
  var month = dateObj.getUTCMonth() + 1;
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  let newdate
  if (month < 10) {

    if (day < 10) {

      newdate = year + "-" + 0 + month + "-" + 0 + day;

    } else {
      newdate = year + "-" + 0 + month + "-" + day;
    }

  } else {
    if (day < 10) {
      newdate = year + "-" + month + "-" + 0 + day;

    } else {
      newdate = year + "-" + month + "-" + day;
    }
  }



  const current = new Date()

  // dropdown work 

  const [getplate, setGetPlate] = useState([])
  const [plateno, setPlateno] = useState("")
  console.log("getplate", getplate)
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [alternatephone, setAlternatephone] = useState("");
  const [email, setEmail] = useState("");
  const [flightno, setFlightno] = useState("");
  const [licenseplate, setLicenseplate] = useState("");
  const [phone, setPhone] = useState("");
  const [vehiclecolor, setVehiclecolor] = useState("");
  const [vehiclemake, setVehiclemake] = useState("");
  const [vehiclemodel, setVehiclemodel] = useState("");
  const Guestlogin = localStorage.getItem("guest")



  useEffect(() => {

    if (Token == null && Guestlogin == "") {
      Navigate("/Login")
    } else if (Guestlogin == "guest" && Token == null) {
      // GetCarNumber()
      // TokenCheck()

    }

    else {
      GetCarNumber()
      TokenCheck()

    }
  }, [])

  console.log("dropzone", fileList)


  // S3 COADE 

  const uploadFile = (file) => {
    console.log("file====>", file)

    return new Promise((resolve, reject) => {
      let finalarry = new Array()
      for (var i = 0; i < file.length; i++) {


        console.log("uploading==>", file[i])




        S3FileUpload.uploadFile(file[i], config)
          .then(data => {
            console.log("done ==> ", data.location)
            finalarry.push(data.location.toString())
            news()
          })
          .catch(err => {
            reject(err)
            console.log("error ==> ", err)

          })
      }

      const news = () => {
        if (file.length === finalarry.length) {
          setUploaddata(finalarry)
          resolve(finalarry)

        }
      }

    })
    // console.log("uploading....",file[i])

  }


  // upload imag video 

  // dropzone 2 start
  const onDropzoneAreaChange = (files) => {
    setFileList(files);
    console.log("Files:", files);
  };


  // dropzone 2 start
  const uploadFiles = () => {
    uploadFile(fileList)
    handleClose()

  };




  const TokenCheck = () => {
    // setLoader(true);
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + Token
      }
    };
    fetch(`${baseUrl.baseUrl}webapi/customerToken?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // setLoader(false);
        if (result.status == "False") {

          Navigate("/Login")
        }
      }).catch(error => {
        // setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }





  console.log("location date", location.state.startdate, location.state.startdate)

  const [error, setError] = useState("");
  const [starttime, setStarttime] = useState(location.state.finalstime);
  const [error2, setError2] = useState("");
  const [endtime, setEndtime] = useState(location.state.finalendtime);
  console.log("start end time ", starttime, endtime)

  const Checkregex = (e) => {
    console.log("check e", e)
    var reg = "^(([0-9]{1})|([0-1]{1}[0-9]{1})|([2]{1}[0-3]{1}))(([:]{1})?)(([0-5]{1}[0-9]?)?)$"
    if (e.match(reg)) {
      setError("Time format must be --:--")
      if (e.length == 3) {
        if (e[2] == ":") {
          setError("Time format must be --:--")


          setStarttime(e)


        }


      }




      else {
        setStarttime(e)


        setError("")


      }


    } else if (e == "") {

      setStarttime(e)


    }



  }




  const Checkregex1 = (e) => {
    console.log("check e", e)
    var reg = "^(([0-9]{1})|([0-1]{1}[0-9]{1})|([2]{1}[0-3]{1}))(([:]{1})?)(([0-5]{1}[0-9]?)?)$"
    if (e.match(reg)) {
      setError2("Time format must be --:--")
      if (e.length == 3) {
        if (e[2] == ":") {
          setError2("Time format must be --:--")

          setEndtime(e)

        }

      }

      else {
        setEndtime(e)
        setError2("")

      }

    } else if (e == "") {

      setEndtime(e)

    }
  }


  const validationSchema = yup.object({
    fnames: yup.string().required('First Name is required'),
    lnames: yup.string().required('Lasr Name is required'),
    email: yup.string().required('Email is required'),
    flightno: yup.string().required('flight Number is required'),

    vehicleColor: yup.string().required("VehicleColor is required"),
    vehicleModel: yup.string().required("VehicleModel is required"),
    vehicleMake: yup.string().required("VehicleMake is required"),
    licensePlate: yup.string().required("LicensePlate is required"),
    departure_airline: yup.string().required("Departure_airline is required"),
    // departure_airline_time: yup.string().required("Departure_airline_time is required"),
    // return_airline_time: yup.string().required("Return_airline_time is required"),
    return_airline: yup.string().required("Return_airline is required"),
    phonenumber: yup.string().required("Phone number is required"),
    // alphonenumber: yup.string().required("Alternate Phone number is required"),

  });



  const formik = useFormik({
    initialValues: {
      fnames: fname,
      lnames: lname,
      email: email,
      flightno: flightno,
      vehicleColor: vehiclecolor,
      vehicleModel: vehiclemodel,
      vehicleMake: vehiclemake,
      licensePlate: licenseplate,
      departure_airline: location.state.startdate,
      return_airline: location.state.enddate,
      phonenumber: phone,
      alphonenumber: alternatephone,
      // departure_airline_time: "",
      // return_airline_time: "",

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      dispatch(updateForm({ state: { ...values, phonenumber: values.phonenumber, countrycode: countrycode, services: location.state.services, selectedId: location.state.selectedId, S3images: uploaddata, departure_time: starttime, return_time: endtime, flight_no: flightno } }))
      dispatch(AmountData({ state: store.counter.amount.state, citytax: citytax, totalamount: totalamount, subtotal: subtotal, parking: parking, cardcharge: cardcharge, transectionfee: transectionfee, additionalids: Ids, gasamount: parseFloat(gasamount) + addigas, evcharge: parseFloat(evamount) + addiev }))
      // Navigate('/Payment', { state: { services: location.state.services, selectedId: location.state.selectedId } })
      Navigate('/Pdf', { state: { services: location.state.services, selectedId: location.state.selectedId } })
    },
  })




  const GetCarNumber = () => {
    setLoader(true);
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + Token
      }
    };
    setLoader(true)
    fetch(`${baseUrl.baseUrl}webapi/carDetails?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status) {
          let Uniquedata = result.data
          if (Uniquedata.length > 0) {
            let uniqueObjArray = [
              ...new Map(Uniquedata.map((item) => [item["licensePlate"], item])).values(),
            ];
            setGetPlate(uniqueObjArray)

          }

          console.log("Licencse plate", result.data)

        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }
  const Checkno = (event) => {


    const DataFilter = getplate.filter((v, i) => event.target.value === v.licensePlate);
    setPlateno(DataFilter)

    console.log("event", DataFilter)
    formik.values.fnames = DataFilter[0].name
    formik.values.lnames = DataFilter[0].lastname
    formik.values.email = DataFilter[0].email
    formik.values.flightno = DataFilter[0].flightNumber
    formik.values.phonenumber = DataFilter[0].phone
    formik.values.alphonenumber = DataFilter[0].alternatephone
    formik.values.licensePlate = DataFilter[0].licensePlate
    formik.values.vehicleColor = DataFilter[0].vehicleColor
    formik.values.vehicleMake = DataFilter[0].vehicleMake
    formik.values.vehicleModel = DataFilter[0].vehicleModel

    setFname(DataFilter[0].name)
    setLname(DataFilter[0].lastname)
    setPhone(DataFilter[0].phone)
    setAlternatephone(DataFilter[0].alternatephone)
    setVehiclecolor(DataFilter[0].vehicleColor)
    setVehiclemake(DataFilter[0].vehicleMake)
    setVehiclemodel(DataFilter[0].vehicleModel)
    setLicenseplate(DataFilter[0].licensePlate)
    setEmail(DataFilter[0].email)
    setFlightno(DataFilter[0].flightNumber)


  }

  //   let uniqueObjArray = [
  //     ...new Map(getplate.map((item) => [item["name"], item])).values(),
  // ];

  // console.log("uniqueObjArray",uniqueObjArray)


  const Deleteservice = () => {
    Navigate("/Reserve")
  }

  return (
    <div>

      <Header3 />
      <Slidar3 />
      {loader ? <Loader fullPage loading /> : null}

      <section className="ftco-section">
        <div className="container mt-4">
          <div className=" text-center heading-section ">
            <h4 className="title">BOOKING FORM</h4>
          </div>
          <div className="row text-center mt-3">
            <article className="">
              <div className='col-md-12 '>
                <div className='table-responsive-md'>
                  <table class="table  table-hover " style={{ borderCollapse: "collapse", textAlign: "left", border: "1px solid #ddd", fontSize: "1em", width: "100%" }}>
                    <thead>
                      <tr >
                        <th scope="col">Item</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Amount</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table-active">
                        <td><p className='servicenametable' style={{ textTransform: "capitalize", fontSize: "1em", fontWeight: "bold" }}><i onClick={() => Deleteservice()} className='fa fa-trash-o' style={{ color: "black", size: "24px" }} /> {servicename.toUpperCase()}</p></td>
                        <td>${Consubamount(subtotal)}</td>
                        <td><strong>${Consubamount(subtotal)}</strong></td>
                      </tr>
                      {services.map((e) => (
                        e.category_name === "Additional services" ?

                          <tr key={e.id} className={e.id === e.selectedId ? '' : 'table-active'}>

                            <td scope="row" >
                              {e.id === e.selectedId ?
                                <p className='servicenametable' style={{ textTransform: "capitalize", fontSize: "1em", fontWeight: "bold" }}><i onClick={() => removeServices(e)} className='fa fa-trash-o' style={{ color: "black", size: "24px" }} /> {titleCase(e.servicename)}</p>
                                :
                                <p className='servicenametable' style={{ textTransform: "capitalize", fontSize: "1em", fontWeight: "bold" }}><i onClick={() => addServices(e)} className='fa fa-plus-circle' style={{ color: "black", size: "24px" }} /> {titleCase(e.servicename)}</p>
                              }

                              {/* <em >Sun Sep 24, 2023</em><br />
                            <em >01:00 AM 02:00 AM</em> */}
                            </td>
                            <td>{`$ ${e.price} `}</td>
                            <td>
                              {/* {e.id === e.selectedId ?
                                <button title="Remove Additional Service" data-toggle="tooltip" data-placement="top" className="" onClick={() => removeServices(e)}><AiOutlineMinusCircle size={25} />{e.price} </button>
                                :
                                <button title="Add Additional Service" data-toggle="tooltip" data-placement="top" className="" onClick={() => addServices(e)}><GrAddCircle size={25} />not selected</button>
                              } */}

                              {e.id === e.selectedId ?
                                <strong title="Remove Additional Service" data-toggle="tooltip" data-placement="top" className="" onClick={() => removeServices(e)}>${e.price} </strong>
                                :
                                <p title="Add Additional Service" data-toggle="tooltip" data-placement="top" className="" onClick={() => addServices(e)}>Not selected</p>
                              }
                            </td>
                          </tr> : null))}


                    </tbody>

                    <tfoot>
                      <tr>
                        <td colspan="3"><hr /></td>
                      </tr>
                      <tr  >
                        {/* <hr /> */}
                        <td colspan="2"><strong>Sub-Total</strong> </td>
                        <td>${Consubamount(subtotal)}</td>

                      </tr>
                      <tr  >
                        <td colspan="2">{servicename.toUpperCase()}</td>
                        <td>${Consubamount(subtotal)}</td>
                      </tr>

                      <tr  >
                        <td colspan="2">LAX CITY TAX 10% (10%): </td>
                        <td>${citytax}</td>

                      </tr>
                      <tr  >
                        <td colspan="2">TRANSACTION FEE: </td>
                        <td>${transectionfee}</td>

                      </tr>

                      <tr  >
                        <td colspan="2">CREDIT CARD FEES  (3.5%):	 </td>
                        <td>{cardcharge}%</td>

                      </tr>
                      <tr  >
                        <td colspan="2">PARKING FEES: </td>
                        <td>${parking}</td>

                      </tr>
                      <tr  >
                        <td colspan="2">DISCOUNT: </td>
                        <td>${Con(discount)}</td>

                      </tr>

                      {item.map((e) => {
                        return (
                          <>
                            <tr style={{ overflow: "scroll" }}>
                              <td colspan="2">{e.name.toUpperCase()}:</td>
                              <td>${e.price}</td>

                            </tr>
                            {/* <hr /> */}
                          </>
                        )
                      })}
                      <tr  >
                        <td colspan="2"><strong>Total (USD):</strong> </td>
                        <td><strong>${Con(totalamount)}</strong></td>

                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>


              <div className='col-md-12'>

                {/* <article className="card"> */}

                {/* <div className="card-title">
              <h2>Payment</h2>
            </div> */}
                {/* <h4>Choose payment method below</h4> */}
                <div classname="row form-row">
                  <form onSubmit={formik.handleSubmit}>


                    <div className="row justify-content-center">

                      {getplate.length > 0 ?
                        <div className="col-md-8 mb-3">
                          {/* <label className='mb-2'>Select License Plate Number  </label> */}

                          <select onChange={Checkno} className="form-control" >

                            <option >Select License Plate Number  </option>

                            {getplate.map((v, i) => {


                              return <option value={v.licensePlate}>{v.licensePlate}</option>
                            })}

                          </select>


                        </div> : ""}
                      <div className='col-md-8 mb-3 ' >

                        <TextField
                          fullWidth
                          // id="outlined-required"
                          id="flightno"
                          name="flightno"
                          label="Flight Number"
                          value={formik.values.flightno ? formik.values.flightno : flightno}
                          onChange={formik.handleChange}
                          error={formik.touched.flightno && Boolean(formik.errors.flightno)}
                          helperText={formik.touched.flightno && formik.errors.flightno}
                        />

                      </div>


                    </div>
                    <div className='row justify-content-center'  >

                      <div className='col-md-8 mb-3' >

                        <TextField
                          fullWidth
                          id="fnames"
                          name="fnames"
                          label="First Name"
                          value={formik.values.fnames ? formik.values.fnames : fname}
                          onChange={formik.handleChange}
                          error={formik.touched.fnames && Boolean(formik.errors.fnames)}

                          helperText={formik.touched.fnames && formik.errors.fnames}


                        />
                      </div>
                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          id="lnames"
                          name="lnames"
                          label="Last Name"
                          value={formik.values.lnames ? formik.values.lnames : lname}
                          onChange={formik.handleChange}
                          error={formik.touched.lnames && Boolean(formik.errors.lnames)}

                          helperText={formik.touched.lnames && formik.errors.lnames}


                        />



                      </div>

                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src='/assets/images/usflag.jpg' style={{ width: "20px" }} />


                              </InputAdornment>
                            ),
                          }}
                          id="phonenumber"
                          name="phonenumber"
                          label="Phone Number"
                          value={formik.values.phonenumber ? formik.values.phonenumber : phone}
                          onChange={formik.handleChange}
                          error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}

                          helperText={formik.touched.phonenumber && formik.errors.phonenumber}


                        />

                      </div>
                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src='/assets/images/usflag.jpg' style={{ width: "20px" }} />


                              </InputAdornment>
                            ),
                          }}
                          id="alphonenumber"
                          name="alphonenumber"
                          label="Alternate Phone Number"
                          value={formik.values.alphonenumber ? formik.values.alphonenumber : alternatephone}
                          onChange={formik.handleChange}




                        />

                      </div>

                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          id="vehicleColor"
                          name="vehicleColor"
                          label="Vehicle Color"
                          value={formik.values.vehicleColor ? formik.values.vehicleColor : vehiclecolor}
                          onChange={formik.handleChange}
                          error={formik.touched.vehicleColor && Boolean(formik.errors.vehicleColor)}
                          helperText={formik.touched.vehicleColor && formik.errors.vehicleColor}
                        />



                      </div>

                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          id="vehicleMake"
                          name="vehicleMake"
                          label="vehicle Make"
                          value={formik.values.vehicleMake ? formik.values.vehicleMake : vehiclemake}
                          onChange={formik.handleChange}
                          error={formik.touched.vehicleMake && Boolean(formik.errors.vehicleMake)}
                          helperText={formik.touched.vehicleMake && formik.errors.vehicleMake}
                        />
                      </div>

                      <div className='col-md-8 mb-3' >

                        <TextField
                          fullWidth
                          id="vehicleModel"
                          name="vehicleModel"
                          label="Vehicle Model"
                          value={formik.values.vehicleModel ? formik.values.vehicleModel : vehiclemodel}
                          onChange={formik.handleChange}
                          error={formik.touched.vehicleModel && Boolean(formik.errors.vehicleModel)}
                          helperText={formik.touched.vehicleModel && formik.errors.vehicleModel}
                        />




                      </div>

                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          id="licensePlate"
                          name="licensePlate"
                          label="License Plate"
                          value={formik.values.licensePlate ? formik.values.licensePlate : licenseplate}
                          onChange={formik.handleChange}
                        // error={formik.touched.licensePlate && Boolean(formik.errors.licensePlate)}

                        // helperText={formik.touched.licensePlate && formik.errors.licensePlate}

                        />
                        {formik.touched.licensePlate && formik.errors.licensePlate ?
                          <div className="text-danger"> {formik.errors.licensePlate}</div> : null}
                      </div>

                      <div className='col-md-8 mb-3' >

                        <TextField
                          fullWidth
                          id="departure_airline"
                          name="departure_airline"
                          label="Departure Airline"
                          type="date"
                          disabled


                          value={formik.values.departure_airline}
                          onChange={formik.handleChange}
                          error={formik.touched.departure_airline && Boolean(formik.errors.departure_airline)}
                          helperText={formik.touched.departure_airline && formik.errors.departure_airline}
                          defaultValue={startdate}
                          inputProps={{ min: newdate }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />


                      </div>

                      <div className='col-md-8 mb-3' >

                        <TextField
                          fullWidth
                          id="return_airline"
                          name="return_airline"
                          label="Return Airline"
                          type="date"
                          disabled

                          value={formik.values.return_airline}
                          onChange={formik.handleChange}
                          error={formik.touched.return_airline && Boolean(formik.errors.return_airline)}
                          helperText={formik.touched.return_airline && formik.errors.return_airline}
                          defaultValue={startdate}
                          inputProps={{ min: newdate }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </div>

                      <div className='col-md-8 mb-3' >
                        <TextField
                          fullWidth
                          value={starttime}
                          label="Check In"
                          disabled
                          onChange={(e) => {
                            Checkregex(e.target.value)
                          }}
                        />
                        <div class="error-message">{error}</div>
                        {/* </div> */}




                      </div>

                      <div className='col-md-8 mb-3' >




                        <TextField
                          fullWidth
                          value={endtime}
                          disabled

                          label="Check Out"
                          onChange={(e) => {
                            Checkregex1(e.target.value)


                          }}
                        />
                        <div class="error-message">{error2}</div>
                        {/* </div>   */}

                      </div>


                      <div className='col-md-8 mb-3 ' >

                        <TextField
                          fullWidth
                          id="email"

                          name="email"
                          label="Email"
                          value={formik.values.email ? formik.values.email : email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />

                      </div>





                      {/* row end */}
                    </div>

                    <div className='row'>
                      <div className="col-md-12 mb-2 d-flex justify-content-evenly">
                        <button onClick={handleShow} className="btn btn-outline-primary " type="button"  >Upload Image and Video</button>
                        <button className="btn btn-outline-primary" type="submit"  >Continue</button>
                      </div>
                      {/* <div className='col-md-6 mb-2'>

                      </div> */}

                    </div>







                  </form>
                </div>
                <div className="card-actions ">

                  <div className="text-center">

                  </div>
                </div>
                {/* </article> */}
              </div>
            </article>
          </div>
        </div >
      </section >
      <Footer />


      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>Upload Your Car Images and Video
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-left'>
          <div>



            <DropzoneArea
              acceptedFiles={['image/jpeg,image/png,video/*']}

              filesLimit={7}
              maxFileSize={20971520} //5 MB
              showFileNames={true}
              dropzoneText={"Please upload your car pictures and Video"}
              onChange={onDropzoneAreaChange}
            />

            {/* <DropzoneArea
              acceptedFiles={['video/*']}
              filesLimit={1}
              maxFileSize={20971520} //20 MB
              showFileNames={true}
              dropzoneText={"Please Upload your car video"}
              onChange={onDropzoneAreaChange2}
            /> */}

          </div>



        </Modal.Body>

        <div className='row text-center'>
          <div className='col-md-12 mb-3 rounded'>



            <LoadingButton
              onClick={() => uploadFiles()}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"

              style={{ backgroundColor: "rgb(3, 186, 254)", color: "white" }}
            >
              Send
            </LoadingButton>
          </div>
        </div>



      </Modal>



      <Modal className='text-center' show={show1} size="md" >
        <Modal.Header >
          <Modal.Title> Amount</Modal.Title>
          <i onClick={toggle1} style={{ fontSize: "24px", marginLeft: "310px" }} className=" "></i>
        </Modal.Header>
        <Modal.Body className='text-left' style={{ backgroundColor: "white" }}>
          <form>

            {active1 == false ?
              <div className="col-md-12 col-sm-6" style={{ justifyContent: 'space-around', marginBottom: '15px' }}>
                <label>Evcharge</label>
                <input type="text" value={evamount} onChange={(e) => setEvamount(e.target.value)} className="form-control" placeholder='Enter evcharge amount' />

              </div> :

              active1 == true ?

                <div className="col-md-12 col-sm-6" style={{ justifyContent: 'space-around', marginBottom: '15px' }}>
                  <label>Gas Charge</label>

                  <input type="text" value={gasamount} onChange={(e) => setGasamount(e.target.value)} className="form-control" placeholder='Enter gas amount' />

                </div> : ""
            }
            {/* 
                        <div className="row text-center justify-content-end mt-2">
                            <button type="button" onClick={() => toggle1()} className="col-2 main-btn cancel mx-3">Ok</button>
                            {/* <button type="button" className="col-2 main-btn">Add</button> */}
            {/* </div>  */}

          </form>
        </Modal.Body>
        <Modal.Footer >




          <Button variant="" style={{ backgroundColor: "rgba(3, 156, 213)", color: "white" }} onClick={() => addServices2(ModalData)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

    </div >




  )
}
