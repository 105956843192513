import React, { useState } from 'react'
import { Loader } from "react-overlay-loader";
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import 'react-overlay-loader/styles.css'
import baseUrl from '../../../baseurl/BaseUrl'
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery'
import cardimg from "../valetpic.png"
const NewPassword = () => {

    const [newpassword, setnewpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");

    const [loader, setLoader] = useState(false);
    const Navigate = useNavigate()
    const uselocation = useLocation()


    const SubmitPassword = () => {

        if (newpassword === confirmpassword) {

            var formdata = new FormData();
            formdata.append("password", newpassword);
            formdata.append("adminid", uselocation.state.userid);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            setLoader(true)
            fetch(`${baseUrl.baseUrl}webapi/forgetConfirmation?role=customer`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)
                    console.log(result)

                    if (result['status'] === true) {
                        Swal.fire({
                            title: "success",
                            text: result.message,
                            icon: "success",
                            confirmButtonColor: "#03bafe",
                        })
                        Navigate("/login")
                    }
                    else {
                        Swal.fire({
                            title: "Oops",
                            text: result.message,
                            icon: "error",
                            confirmButtonColor: "#03bafe",
                        });
                    }


                }


                )
                .catch(error => {
                    setLoader(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error,
                        confirmButtonColor: "#03bafe",
                    })
                });
        }

        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Password did not Match",
                confirmButtonColor: "#03bafe",
            })
        }

    }

    return (
        <>
            {loader ? <Loader fullPage loading /> : null}
            <section className='abcd' style={{ backgroundImage: "url('/assets/images/register.jpeg')", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "cover", position: "absolute", width: "100%" }}>
                <div className="container ">
                    <div className="row " style={{ marginBlock: "10%" }}>
                        <div className="col-md-5 ">
                            <Card className='align-items-center d-flex justify-content-center' style={{ minHeight: "30rem", padding: "20px 30px 25px 20px" }}>
                                <img src='assets/images/newlogo.png' className="img-fluid" width="25%" alt={""} />
                                <Card.Body>
                                    <Card.Text>
                                        <h3 style={{ fontSize: "2.25rem" }}>Hi, Welcome Back</h3>
                                        <img src={cardimg} className="img-fluid" alt={""} />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6  " style={{ marginTop: "6%" }} >
                            <form action="#" className=" p-3 contact-form">
                                <div className="  heading-section mb-5 ">
                                    <h3 className="title">Sign in to Valet Services</h3>
                                    <h6 className="">Enter your details below.</h6>
                                </div>
                                <div className="form-group">
                                    <input type="password" value={newpassword} onChange={(e) => setnewpassword(e.target.value)} className="form-control" placeholder="New Password" />
                                </div>

                                <div className="form-group">
                                    <input type="password" value={confirmpassword} onChange={(e) => setconfirmpassword(e.target.value)} className="form-control" placeholder="confirmpassword" />
                                </div>


                                <div className="form-group">
                                    <button type="button" className=" btn-lg btn-block" onClick={() => { SubmitPassword() }} style={{ backgroundColor: "#1089ff", color: "white", border: "solid 2px #1089ff" }}>Confirm</button>
                                </div>
                                {/* <div className="form-group text-center" >
                                    <h5 className="title">Don't have an account? <Link to="/Register">Get Started</Link></h5>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewPassword