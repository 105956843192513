import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import Calendar from 'react-calendar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Header from "../../layout/Header"
import Header3 from "../../layout/Header3"
// import Header2 from "../../layout/Header2"
import Footer from "../../layout/Footer"
// import Slidar2 from "../../components/slider2"
import Slidar3 from "../../components/slider3"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import "./calender.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Lazy, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { Loader } from "react-overlay-loader";
import Swal from "sweetalert2";
import baseUrl from '../../../baseurl/BaseUrl'
import moment from 'moment'
import "./shortslider.css"
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';
// Import Swiper styles
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
// swiper core styles
import 'swiper/swiper.min.css'
import { AmountData } from '../../../redux/actions/counterActions';
import { useDispatch } from 'react-redux';
import { SliderData } from '../../utils/sliderData';
import usePlaceholder from 'react-bootstrap/esm/usePlaceholder';
import TimeSet from '../timeset/TimeSet';


export default function Reserve(prop) {
  // const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const location = useLocation();
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // const [values, setValues] = React.useState(new Date());
  // const [valu, setValu] = React.useState(new Date());
  const Guestlogin = localStorage.getItem("guest")

  const dispatch = useDispatch()

  // BOOKING STATE

  const dateObj = new Date()
  var month = dateObj.getUTCMonth() + 1;
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  let newdate
  if (month < 10) {

    if (day < 10) {

      newdate = year + "-" + 0 + month + "-" + 0 + day;

    } else {
      newdate = year + "-" + 0 + month + "-" + day;
    }

  } else {
    if (day < 10) {
      newdate = year + "-" + month + "-" + 0 + day;

    } else {
      newdate = year + "-" + month + "-" + day;
    }
  }


  const current = new Date()
  let hour = current.getHours();
  let minutes = current.getMinutes();


  if (hour < 10) {
    hour = "0" + hour

  }
  if (minutes < 10) {
    minutes = "0" + minutes

  }

  let finaltime = hour + ":" + minutes


  const [startdate, setstartdate] = useState(location.state == null ? newdate : location.state.startdate);
  const [enddate, setenddate] = useState(location.state == null ? newdate : location.state.enddate);
  const [servicename, setServicename] = useState(location.state == null ? "" : location.state.service);
  console.log("servicename", servicename)
  const [starttime, setStarttime] = useState(finaltime);
  const [endtime, setEndtime] = useState(finaltime);
  const [buttonstate, setButtonstate] = useState(false);


  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const User = JSON.parse(localStorage.getItem("UserData"))
  const Token = localStorage.getItem("Token")




  const Checkregex = (e) => {

    var reg = "^(([0-9]{1})|([0-1]{1}[0-9]{1})|([2]{1}[0-3]{1}))(([:]{1})?)(([0-5]{1}[0-9]?)?)$"
    if (e.match(reg)) {
      setError("Time format must be --:--")
      if (e.length == 3) {
        if (e[2] == ":") {
          setError("Time format must be --:--")


          setStarttime(e)


        }


      }




      else {
        setStarttime(e)


        setError("")


      }


    } else if (e == "") {

      setStarttime(e)


    }



  }



  const Checkregex1 = (e) => {

    var reg = "^(([0-9]{1})|([0-1]{1}[0-9]{1})|([2]{1}[0-3]{1}))(([:]{1})?)(([0-5]{1}[0-9]?)?)$"
    if (e.match(reg)) {
      setError2("Time format must be --:--")
      if (e.length == 3) {
        if (e[2] == ":") {
          setError2("Time format must be --:--")

          setEndtime(e)

        }

      }

      else {
        setEndtime(e)
        setError2("")

      }

    } else if (e == "") {

      setEndtime(e)

    }
  }
  const Navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [id, setid] = useState("");
  const Book = { serviceId: id, startdate, enddate, starttime, endtime, ...User }
  console.log("BOOK", Book)
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [service, setservice] = useState([])
  const [key, setKey] = useState('');
  const [modaldata, setmodaldata] = useState({});
  const [images, setimages] = useState([]);
  const [bookingdata, setBookingdata] = useState({});
  const [additionalid, setadditionalid] = useState([]);
  const [appromo, setAppromo] = useState("");
  const [promoinput, setPromoinput] = useState(false);
  const [discountprice, setDiscountprice] = useState("");
  const [promoid, setPromoid] = useState("");



  console.log("appromo", appromo, modaldata.servicename,)

  // NEWTIME STATES 
  const [shr, setShr] = useState("");
  const [smin, setSmin] = useState("");
  const [sam, setSam] = useState("");
  const [ehr, setEhr] = useState("");
  const [emin, setEmin] = useState("");
  const [eam, setEam] = useState("");
  const [finalstime, setFinalstime] = useState("");
  const [finalendtime, setFinalendtime] = useState("");
  const [fname, setFname] = useState("")
  const [profile, setProfile] = useState("")
  const [data2, setData2] = useState([])
  // const User = JSON.parse(localStorage.getItem("UserData"))
  // const Token = localStorage.getItem("Token")

  const convertTime12to24 = (time12h) => {

    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  console.log("initial value", shr, ehr, startdate, enddate)

  const Checkfair = (e, name) => {
    if (name == "shr") {

      setShr((prev1) => {
        const Updatevalue1 = e
        console.log("prev1", prev1)
        console.log("Updatevalue1", Updatevalue1)


        return Updatevalue1
      })
    }
    else if (name == "ehr") {
      setEhr((prev) => {
        const Updatevalue = e
        return Updatevalue

      })

    }
    else if (name == "startdate") {
      setstartdate((prev) => {
        const Updatevalue = e
        return Updatevalue

      })
    }
    else if (name == "enddate") {
      setenddate((prev) => {
        const Updatevalue = e
        return Updatevalue

      })
    }
    // if (startdate == "" || enddate == "" || ehr == "" || shr == "") {

    //   console.log("error")
    // } else {
    //   GetBooking()

    // }
  }

  useEffect(() => {
    console.log("runing use effect")
    if (startdate == "" || enddate == "" || ehr == "" || shr == "") {

      console.log("error")
    } else {
      GetBooking()

    }

  }, [startdate, enddate, shr, ehr])

  const Con = (amount) => {
    if (amount != undefined) {

      return Number(amount).toFixed(2)
    }
    else {
      return ""
    }

  }



  const handleClose = () => {
    setServicename("")
    setShow(false)
    setButtonstate(false)
    setmodaldata({})
    setBookingdata({})
    setstartdate(newdate)
    setenddate(newdate)
    setStarttime(finaltime)
    setEndtime(finaltime)
    setid("")
    setimages([])
    setDates([])
  };

  const handleShow = (u, v) => {

    if (Token == null && Guestlogin == null) {
      Navigate("/Login")

    } else {



      console.log("handle show id", u)
      if (servicename == "") {
        setServicename(u.servicename)
      }

      setShow(true)
      setmodaldata(u)
      setid(u.id)
      setimages(u.serviceimages)
      // setDates([u.availability_start, u.availability_end])
      setTimeout(() => {

        Detail(v)
      }, 100);

    }
  }



  useEffect(() => {
    if (Token != null) {
      GetServices2()
      console.log("token null nhe ha")
    }
  }, [])

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params.status == "true") {
      if (service.length > 0 && !show) {
        console.log("handle show", service.find((v, i) => v.id == servicename))
        const openmodal = service.find((v, i) => v.id == servicename)

        handleShow(openmodal, "booknow")
      }
      //  document.getElementById("uniqueid").click()
    }
    GetServices()
  }, [service.length])

  useEffect(() => {
    if (data && data[0] && data[0].name) {
      setKey(data[0].name)
    }
  }, [JSON.stringify(data)])


  const GetServices = () => {
    // setLoader(true);
    var requestOptions = {
      method: 'GET',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
    };
    setLoader(true)
    fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=superadmin`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status) {
          const updateCategory = result.category.filter(data => data.name != "Additional services")
          console.log("result category", updateCategory)
          setData(updateCategory)
          setservice(result.services)
          // setServicename()

        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }
  const GetServices2 = () => {
    setLoader(true);
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + Token
      }
    };
    fetch(`${baseUrl.baseUrl}webapi/profile?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {

        setLoader(false);
        if (result.status && result.status != "False") {

          setData2(result.data)
          setFname(result.data.fname)
          setProfile(result.data.profile)




        }
        // else {
        //     setLoader(false)
        //     Swal.fire({
        //         title: "opps",
        //         text: result.message,
        //         icon: "error"
        //     })



        // }
      }).catch(function (e) {
        setLoader(false)
        Swal.fire({
          title: "opps!",
          text: e.message,
          icon: "error"
        })
      })

  }



  const Detail = (v) => {


    if (v === "avalible") {


      document.getElementById("uncontrolled-tab-example-tab-Availability").click()
    }
    else if (v === "detail") {
      document.getElementById("uncontrolled-tab-example-tab-Details").click()
    }
    else if (v === "photos") {
      document.getElementById("uncontrolled-tab-example-tab-Photos").click()
    }
    else {
      document.getElementById("uncontrolled-tab-example-tab-Book").click()
    }
  }

  // BBOKING API

  // GETsERVICES



  const GetBooking = () => {
    let StartTime = convertTime12to24(shr)
    let EndTime = convertTime12to24(ehr)

    setFinalstime(StartTime)
    setFinalendtime(EndTime)



    setLoader(true);
    setLoading(true)

    var requestOptions = {
      method: 'GET',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
    };
    if (appromo == "") {


      fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${id}&startdate=${startdate}&enddate=${enddate}&starttime=${StartTime + ":00"}&endtime=${EndTime + ":00"}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false)
          if (result.status) {
            console.log("get total", result.data)
            setButtonstate(true)
            setBookingdata(result.data)
            setLoading(false)

          } else {

            setBookingdata("")
            setLoading(false)

            Swal.fire({
              title: "Oops",
              text: result.message,
              icon: "error",
              confirmButtonColor: "#03bafe",
            });
          }
        }).catch(error => {
          setLoader(false);
          setLoading(false)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });
    } else {

      console.log("promo else", bookingdata.totalamout, appromo)
      fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${id}&startdate=${startdate}&enddate=${enddate}&starttime=${StartTime + ":00"}&endtime=${EndTime + ":00"}&amount=${bookingdata.totalamout}&promo_code=${appromo}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false)
          if (result.status) {
            console.log("get total promo", result.data)
            setButtonstate(true)
            setPromoinput(false)
            setBookingdata(result.data)
            setBookingdata((prev) => { return { ...prev, promcode: appromo, } })
            setLoading(false)

          } else {

            setBookingdata("")
            setLoading(false)

            Swal.fire({
              title: "Oops",
              text: result.message,
              icon: "error",
              confirmButtonColor: "#03bafe",
            });
          }
        }).catch(error => {
          setLoader(false);
          setLoading(false)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });


    }
  }

  const DataMove = () => {
    dispatch(AmountData({ state: { ...bookingdata, ...Book, additionalid, appromo: appromo, servicename: modaldata.servicename } }))
    if (Token === null) {
      Navigate('/Login')
    } else {
      Navigate('/ReservationForm', { state: { services: service, selectedId: modaldata.category_name === "Additional services" ? modaldata.id : null, startdate, enddate, finalstime, finalendtime, } })
    }
  }

  const DataMoveasguest = () => {
    dispatch(AmountData({ state: { ...bookingdata, ...Book, additionalid, appromo: appromo, servicename: modaldata.servicename } }))

    Navigate('/ReservationForm', { state: { services: service, selectedId: modaldata.category_name === "Additional services" ? modaldata.id : null, startdate, enddate, finalstime, finalendtime, } })
    console.log("DataMoveasguest")
  }

  function htmlToText(html) {
    var temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
  }

  const data12 = [
    {
      value: 1,
      text: 'Up Arrow',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
      </svg>
    },
    {
      value: 2,
      text: 'Down Arrow',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
      </svg>
    },
    {
      value: 3,
      text: 'Left Arrow',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
      </svg>
    },
    {
      value: 4,
      text: 'Right Arrow',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
      </svg>
    }
  ];
  const navigate = useNavigate()

  const OnPromo = () => {
    setPromoinput(true)
  }


  const ApplyPromo = () => {
    console.log("apply promo code", appromo, bookingdata.totalamout)
    setLoader(true);

    const form = new FormData()
    form.append("promo_code", appromo);
    form.append("amount", bookingdata.totalamout);

    var requestOptions = {
      method: 'POST',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
      body: form,
    };

    fetch(`${baseUrl.baseUrl}webapi/applypromocode`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false)
        if (result.status) {
          // setBookingdata((prev) => ({
          //   ...prev,
          //   totalamout: result.data.discount_price,
          //   promoid: result.data.promo_id ? result.data.promo_id : null,
          // }));

          setBookingdata((prev) => { return { ...prev, totalamout: result.data.discount_price, promoid: result.data.promo_id } })
          console.log("promo response api", result)
          setPromoinput(false)
          setDiscountprice(result.data.discount_price)



        } else {


          setLoading(false)

          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });


  }

  return (
    <div>
      {/* <Header2 /> */}
      {

        Token == null && Guestlogin == null ?
          <div className='bluediv'  >
            <div className="bs-example px-3 py-3">
              <div className="clearfix fss">
                <Button onClick={() => navigate("/Register")} className='  float-right ml-2 ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                  Register Now
                </Button>{' '}
                <Button onClick={() => navigate("/Login")} className='float-right ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                  Sign In
                </Button>{' '}
              </div>
            </div>
          </div>
          :
          Token == null && Guestlogin == "guest" ?
            <div className='bluediv' style={{ height: "64px" }} >
              <div className="bs-example px-3 py-3">
                <div className="clearfix fss">
                  <h3 className='float-right  mx-5 ' >
                    {/* <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" /> */}
                    {/* <img src={profile ? profile: '/assets/images/person_1.jpg'} className='img-fluid' width="60%" style={{ border: "5px solid rgb(3 156 213)" }} alt={""}/> */}

                  </h3>
                  <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome as Guest</h3>

                </div>

              </div>
            </div> :
            <div className='bluediv' style={{ height: "64px" }} >
              <div className="bs-example px-3 py-3">

                <div className="clearfix fss">

                  <h3 className='float-right  mx-5 ' >
                    <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" />
                    {/* <img src={profile ? profile: '/assets/images/person_1.jpg'} className='img-fluid' width="60%" style={{ border: "5px solid rgb(3 156 213)" }} alt={""}/> */}

                  </h3>
                  <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome {fname}</h3>
                </div>
              </div>
            </div>}


      <Header3 />
      {/* <Slidar data={SliderData.reservation} /> */}
      {/* <Slidar2 data={SliderData.reservation} /> */}
      <Slidar3 />
      {loader ? <Loader fullPage loading /> : null}
      <div className='container-fluid mt-4'>
        <div className='row mx-3 '>
          {/* ahmed change md-3 to md-1 for responsiveness */}
          <div className='col-md-1'>
            {/* <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={{ start: 'Start', end: 'End' }}
            >
              <DateRangePicker
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 3 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider> */}

            {/* <div className='calendar-container'>
              <Calendar
                onChange={setDate} value={date}
              />
            </div>
            <p className='text-center'>
              <span className='bold'>Selected Date:</span>{' '}
              {date.toDateString()}
            </p> */}
          </div>
          <div className='col-md-9'>
            <Tabs
              variant="pills"
              id="uncontrolled-tab-example"
              className="mb-3"
              defaultChecked
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              {data.map((v, i) => (



                <Tab eventKey={v.name}
                  // className='capi'
                  title={v.name}
                  key={i}
                >
                  {service.map((u, i) => (
                    u.category_name === key ? <div key={i}>
                      <div className='row'>
                        <div className='col-md-3'>
                          {/* {u.category_name != "Additional services"?
                        <> */}
                          <h3 className='text-dark' onClick={() => handleShow(u)}>Available</h3>
                          <ul style={{ fontSize: "17px", lineHeight: "35px" }}>
                            <li style={{ color: "black", cursor: "pointer" }}>
                              <h6 onClick={() => handleShow(u, "booknow")}> <i className='fa fa-check-square ' style={{ color: "rgb(75 247 21)" }} /> Available </h6></li>
                            <li style={{ color: "black", cursor: "pointer" }}><h6 onClick={() => handleShow(u, "detail")}> <i className='fa fa-info-circle' /> Details</h6></li>
                            <li style={{ color: "black", cursor: "pointer" }}><h6 onClick={() => handleShow(u, "photos")}> <i className='fa fa-image' /> Photos</h6></li>
                            {/* <li>
                          <Button onClick={() => handleShow(u)} className='bookbtn text-white'>
                                Book Now
                            </Button>
                          </li> */}
                          </ul>
                          <ul>
                            <li>
                              <Button className='mt-3' onClick={() => handleShow(u)} style={{ width: "130px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                                Book Now
                              </Button>{' '}
                            </li>
                          </ul>



                        </div>
                        <div className='col-md-6'>

                          <p className='capi' style={{ fontSize: "25px", fontWeight: 500 }}>{u.servicename}</p>
                          <p style={{ fontSize: "20px", fontWeight: 500 }}>${u.price}</p>

                          {htmlToText(u.Description.replace(/\s(.)/g, function (a) {
                            return a.toUpperCase();
                          }).replace(/\s/g, ' ').replace(/^(.)/, function (b) {
                            return b.toLowerCase();
                          }))}
                        </div>
                        <div className='col-md-2 mt-4'>
                          {u.serviceimages.length > 0 ?
                            <img src={u.serviceimages[0].profile} style={{ height: "200px", width: "200px" }} alt={""} />
                            : null}
                        </div>
                      </div>
                      <hr />
                    </div> : null
                  ))}
                </Tab>
              ))}
            </Tabs>
          </div>
          {/* <hr /> */}
        </div>
      </div>
      <Footer />

      {/* ADD MODAL  */}
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "rgba(3, 156, 213)" }}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "white" }}>
          <Tabs
            defaultActiveKey="Parking Services"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Details" title="Details">
              <div className='col-md-12'>
                <p style={{ fontSize: "25px" }}>{modaldata.servicename}</p>
                <p style={{ fontSize: "20px" }}>${modaldata.price}</p>
                {/* <p>{modaldata.Description}</p> */}
                {htmlToText(modaldata.Description)}
              </div>
            </Tab>

            {/* <Tab eventKey="Availability" title="Availability">
              <div className='calendar-container'>
                <p>DateRangePicker</p>
                {dates.length>0?(
                <Calendar
                  // onChange={setDates}
                  value={dates.map(d => moment(d, "YYYY-MM-DD").toDate())}
                  selectRange={true}
                minDate={new Date()}
                 style={{ width: "1000px" }}
                />):null}
              </div>
              <p className='text-center'>
              <span className='bold'>Selected Date:</span>{' '}
              {dates.toDateString()}
            </p>

              {dates.length > 0 ? (
                <p>
                  <span>Start:</span>{' '} {dates[0]}
                  &nbsp; to &nbsp;
                  <span>End:</span> {dates[1]}
                </p>
              ) : (
                <p>
                  <span>Default selected date:</span>{' '} {dates}
                </p>
              )}
            </Tab> */}
            <Tab eventKey="Photos" title="Photos" >
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                lazy={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Lazy, Pagination, Navigation]}
                className="mySwiper"
              >
                {images.map((v, i) => (
                  <SwiperSlide className='swiper-slidess'>
                    <img
                      src={v.profile}
                      className="swiper-lazy"
                      style={{ maxWidth: "100%", height: "auto" }}
                      alt={""}
                    />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Tab>
            <Tab eventKey="Book" title="Book Now" >
              <p style={{ fontSize: "25px" }}>{modaldata.servicename}</p>
              <span className="badge bg-success">Avalible</span>
              <div className='row'>
                <p>Select Date</p>
                <div className='col-md-6  mb-4'>
                  <TextField id="standard-basi" label="Availablity Start Date" variant="outlined"
                    fullWidth
                    defaultValue={startdate}
                    inputProps={{ min: newdate }}
                    type='date'
                    value={startdate}
                    onChange={(e) => {
                      Checkfair(e.target.value, "startdate")
                    }} />
                </div>
                <div className='col-md-6'>
                  <TextField id="standard-basic" label="Availablity End Date" variant="outlined"
                    fullWidth
                    inputProps={{ min: newdate }}
                    defaultValue={enddate}
                    type='date'
                    value={enddate}
                    onChange={(e) => {
                      Checkfair(e.target.value, "enddate")
                    }}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                {/* <p> Time</p> */}
                <div className='col-md-6'>
                  <label>Check In</label>
                  <i id="clock" className='fa fa-clock-o' style={{ position: "relative", left: "75%", top: "56%", zIndex: "1", fontSize: "25px" }} />
                  <div class="input-group" style={{ width: "100%" }}>
                    <select class="form-control" value={shr} onChange={(e) => Checkfair(e.target.value, "shr")} id="hr">
                      <option value="" >--:-- --</option>
                      <option value="04:00 AM">04:00 AM </option>
                      <option value="04:30 AM ">04:30 AM </option>
                      <option value="05:00 AM">05:00 AM </option>
                      <option value="05:30 AM">05:30 AM </option>
                      <option value="06:00 AM">06:00 AM </option>
                      <option value="06:30 AM">06:30 AM </option>
                      <option value="07:00 AM">07:00 AM </option>
                      <option value="07:30 AM"> 07:30 AM </option>
                      <option value="08:00 AM">08:00 AM</option>
                      <option value="08:30 AM">08:30 AM</option>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="09:30 AM">09:30 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="10:30 AM">10:30 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="11:30 AM">11:30 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="12:30 PM">12:30 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="01:30 PM">01:30 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="02:30 PM">02:30 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="03:30 PM">03:30 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="04:30 PM">04:30 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="05:30 PM">05:30 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="06:30 PM">06:30 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="07:30 PM">07:30 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="08:30 PM">08:30 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                      <option value="09:30 PM">09:30 PM</option>
                      <option value="10:00 PM">10:00 PM</option>
                      <option value="10:30 PM">10:30 PM</option>
                      <option value="11:00 PM">11:00 PM</option>
                      <option value="11:30 PM">11:30 PM</option>
                      <option value="12:00 PM">12:00 AM</option>
                      <option value="12:30 AM">12:30 AM</option>
                      <option value="01:00 AM">01:00 AM</option>
                      <option value="01:30 AM">01:30 AM</option>
                      <option value="02:00 AM">02:00 AM</option>
                      <option value="02:30 AM">02:30 AM</option>
                      <option value="03:00 AM">03:00 AM</option>
                      <option value="03:30 AM">03:30 AM</option>



                    </select>

                  </div>

                  {/* <div class="">
                    <label>End Time</label>
                    <input className='form-control'
                      type="text"
                      value={endtime}
                      placeholder="--:--"
                      onChange={(e) => {
                        
                        Checkregex1(e.target.value)

                        // setStarttime(e.target.value+":00" )

                      }}
                    />
                    <div class="error-message">{error2}</div>
                  </div> */}

                  {/* <input type="time"
                    className='form-control'
                    value={endtime}
                    onChange={(e) => {

                      setEndtime(e.target.value + ":00")

                    }} /> */}
                  {/* <TextField id="standard-ba"
                    label="Availablity End Time"
                    variant="outlined"
                    fullWidth
                    defaultValue={endtime}
                    type='time'
                    value={endtime}
                    onChange={(e) => {
                      
                      setEndtime(e.target.value + ":00")
                    }} /> */}


                  {/* <div class="">
                    <label>Start Time</label>
                    <input className='form-control'
                      type="text"
                      value={starttime}
                      placeholder="--:--"
                      onChange={(e) => {
                        Checkregex(e.target.value)

                        // setStarttime(e.target.value+":00" )

                      }}
                    />
                    <div class="error-message">{error}</div>
                  </div> */}

                  {/* <input type="time" 
                  value={starttime} 
                className='form-control'

                   onChange={(e) => {
                    
                      setStarttime(e.target.value+":00" )
                     
                    }}
                     /> */}
                  {/* <TextField id="standard-bas"
                    label="Availablity start Time"
                    variant="outlined"
                    fullWidth
                    defaultValue={starttime}
                    type='time'
                    value={starttime}
                    onChange={(e) => {
                 
                      setStarttime(e.target.value + ":00")
                    }} /> */}
                </div>
                <div className='col-md-6'>
                  <label>Check Out</label>
                  <i id="clock1" className='fa fa-clock-o' style={{ position: "relative", left: "72%", top: "55%", zIndex: "1", fontSize: "25px" }} />
                  <div class="input-group" style={{ width: "100%" }}>
                    <select class="form-control" value={ehr} onChange={(e) => Checkfair(e.target.value, "ehr")} id="hr">
                      <option value="" >--:-- --</option>
                      <option value="04:00 AM">04:00 AM </option>
                      <option value="04:30 AM ">04:30 AM </option>
                      <option value="05:00 AM">05:00 AM </option>
                      <option value="05:30 AM">05:30 AM </option>
                      <option value="06:00 AM">06:00 AM </option>
                      <option value="06:30 AM">06:30 AM </option>
                      <option value="07:00 AM">07:00 AM </option>
                      <option value="07:30 AM"> 07:30 AM </option>
                      <option value="08:00 AM">08:00 AM</option>
                      <option value="08:30 AM">08:30 AM</option>
                      <option value="09:00 AM">09:00 AM</option>
                      <option value="09:30 AM">09:30 AM</option>
                      <option value="10:00 AM">10:00 AM</option>
                      <option value="10:30 AM">10:30 AM</option>
                      <option value="11:00 AM">11:00 AM</option>
                      <option value="11:30 AM">11:30 AM</option>
                      <option value="12:00 PM">12:00 PM</option>
                      <option value="12:30 PM">12:30 PM</option>
                      <option value="01:00 PM">01:00 PM</option>
                      <option value="01:30 PM">01:30 PM</option>
                      <option value="02:00 PM">02:00 PM</option>
                      <option value="02:30 PM">02:30 PM</option>
                      <option value="03:00 PM">03:00 PM</option>
                      <option value="03:30 PM">03:30 PM</option>
                      <option value="04:00 PM">04:00 PM</option>
                      <option value="04:30 PM">04:30 PM</option>
                      <option value="05:00 PM">05:00 PM</option>
                      <option value="05:30 PM">05:30 PM</option>
                      <option value="06:00 PM">06:00 PM</option>
                      <option value="06:30 PM">06:30 PM</option>
                      <option value="07:00 PM">07:00 PM</option>
                      <option value="07:30 PM">07:30 PM</option>
                      <option value="08:00 PM">08:00 PM</option>
                      <option value="08:30 PM">08:30 PM</option>
                      <option value="09:00 PM">09:00 PM</option>
                      <option value="09:30 PM">09:30 PM</option>
                      <option value="10:00 PM">10:00 PM</option>
                      <option value="10:30 PM">10:30 PM</option>
                      <option value="11:00 PM">11:00 PM</option>
                      <option value="11:30 PM">11:30 PM</option>
                      <option value="12:00 PM">12:00 AM</option>
                      <option value="12:30 AM">12:30 AM</option>
                      <option value="01:00 AM">01:00 AM</option>
                      <option value="01:30 AM">01:30 AM</option>
                      <option value="02:00 AM">02:00 AM</option>
                      <option value="02:30 AM">02:30 AM</option>
                      <option value="03:00 AM">03:00 AM</option>
                      <option value="03:30 AM">03:30 AM</option>



                    </select>

                  </div>

                  {/* <div class="">
                    <label>End Time</label>
                    <input className='form-control'
                      type="text"
                      value={endtime}
                      placeholder="--:--"
                      onChange={(e) => {
                    
                        Checkregex1(e.target.value)

                        // setStarttime(e.target.value+":00" )

                      }}
                    />
                    <div class="error-message">{error2}</div>
                  </div> */}

                  {/* <input type="time"
                    className='form-control'
                    value={endtime}
                    onChange={(e) => {

                      setEndtime(e.target.value + ":00")

                    }} /> */}
                  {/* <TextField id="standard-ba"
                    label="Availablity End Time"
                    variant="outlined"
                    fullWidth
                    defaultValue={endtime}
                    type='time'
                    value={endtime}
                    onChange={(e) => {
                      
                      setEndtime(e.target.value + ":00")
                    }} /> */}
                </div>


              </div>
              {promoinput === true ?
                <div className='row mt-3'>
                  <div className='col-md-12'>
                    <div class="form-inline">

                      <div class="form-group mx-1 ">
                        {/* <label for="inputPassword2" class="sr-only">Password</label> */}
                        <input value={appromo} onChange={(e) => setAppromo(e.target.value)} type="text" class="form-control" id="inputPassword2" placeholder="Enter Promo/Voucher " />
                      </div>
                      <Button style={{ backgroundColor: "#32A9E0 ", color: "white" }} onClick={() => GetBooking()}>Apply</Button>
                    </div>
                  </div>
                </div> : ""}

              <div className='row mt-4'>

                <div className='col-md-12'>


                  <h3>Your Total Amount is ${Con(bookingdata.discount_price > 0 ? bookingdata.discount_price : bookingdata.totalamout)}</h3>



                </div>
              </div>
              <div className="row">
                <div className='col-md-12 text-end'>

                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "rgba(3, 156, 213)" }}>
          <div className='row w-100'>
            <div className='col-md-6 mt-2'>
              {promoinput == false && buttonstate == true ?
                <strong style={{ cursor: "pointer" }} onClick={() => OnPromo()}>Apply Promo or Voucher </strong> : ""}</div>

            <div className='col-md-6 d-flex justify-content-end'>
              {Guestlogin == "guest" ?
                <LoadingButton
                  onClick={() => DataMoveasguest()}
                  // onClick={GetBooking}
                  endIcon={<SendIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant=""
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  Continue As Guest
                </LoadingButton> :

                buttonstate === true ? <Button variant="" style={{ backgroundColor: "white", color: "black" }} disabled={bookingdata.totalamout ? false : true} onClick={() => DataMove()}>
                  Continue
                </Button> : ""}

            </div>
          </div>




          {/* {buttonstate === true ? <Button variant="" style={{ backgroundColor: "white", color: "black" }} disabled={bookingdata.totalamout ? false : true} onClick={() => DataMove()}>
            Continue
          </Button> : ""} */}
        </Modal.Footer>
      </Modal>
    </div>
  )
}