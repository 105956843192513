import React, { useState, useEffect } from "react";
import baseUrl from '../../baseurl/BaseUrl'
import Swal from "sweetalert2";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Rating from '@mui/material/Rating';
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
// swiper core styles
import 'swiper/swiper.min.css'
import "./services.css";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import { Scrollbars } from 'react-custom-scrollbars-2';

export default function ServicelogSlider() {

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([])
  const [servicedata, setservice] = useState([])

  useEffect(() => {

    GetServices()
  }, [])

  const GetServices = () => {
    // setLoader(true);
    var requestOptions = {
      method: 'GET',
      // headers: {
      //     Authorization: "Bearer " + Token
      // }
    };
    fetch(`${baseUrl.baseUrl}webapi/servicecreation`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        if (result.status) {
          setData(result.category)
          setservice(result.services)

        } else {
          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        setLoader(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }



  return (
    <>
      <section className="ftco-section">
        <div className="row">
          <div className="col-md-12">
          <div className="row ml-5 ">
            <div className="col-md-12 mt-5  ">
              {/* <span className="subheading">Services</span> */}
              <h2 className="mb-3 servicelogt" style={{ color: "#FFFFFF", }} >ADDITIONAL SERVICES</h2>
            </div>
          </div>
         
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            // autoplay={{
            //     delay: 2500,
            //     disableOnInteraction: false,
            // }}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
              },
            }}
            modules={[Pagination, Autoplay, Navigation]}
            className="mySwiper"
          >


            {/* my slider work */}

            {servicedata.map((data) => {

              return (
                <>
                  <SwiperSlide className="swiper-slides">

                    <div className="item" style={{padding:"20px"}}>



                      <div className="testimony-wrap rounded text-center  py-4  shadow p-3 mb-5 bgmycss  rounded " style={{padding:"20px"}}>
                        {/* <Scrollbars style={{ height: "35vh" }}> */}

                        <div className="row">
                          <div className="col-md-12" style={{minHeight:"200px"}}>

                            {/* <p style={{ fontSize: "20px" }}> */}
                            <p  className="servicelogtitle">{data.servicename}</p >
                            <p className="servicedescp text-start" dangerouslySetInnerHTML={{ __html: data.Description }}></p>


                            {/* <p>Reservation Date :  2022-11-08</p> */}

                          </div>
                        </div>

                        {/* </Scrollbars> */}
                      </div>

                    </div>

                  </SwiperSlide>

                </>
              )

            })
            }




            {/* old work */}
            {/*                         
                        <SwiperSlide className="swiper-slides">
                            <div className="item">
                                <div className="testimony-wrap rounded text-center  py-4  shadow p-3 mb-5 bgmycss  rounded  ">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <p style={{ color: "white", fontSize: "20px" }}><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />lax curbside valet parking</p >
                                            <p>Reservation Time : 7:25:00 PM  </p>

                                            <p>Reservation Date :  2022-11-08</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="swiper-slides">
                            <div className="item">
                                <div className="testimony-wrap rounded text-center  py-4  shadow p-3 mb-5 bgmycss  rounded  ">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <p style={{ color: "white", fontSize: "20px" }}><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />lax curbside valet parking</p >
                                            <p>Reservation Time : 7:25:00 PM  </p>

                                            <p>Reservation Date :  2022-11-08</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="swiper-slides">
                            <div className="item">
                                <div className="testimony-wrap rounded text-center  py-4  shadow p-3 mb-5 bgmycss  rounded  ">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <p style={{ color: "white", fontSize: "20px" }}><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />lax curbside valet parking</p >
                                            <p>Reservation Time : 7:25:00 PM  </p>

                                            <p>Reservation Date :  2022-11-08</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="swiper-slides">
                            <div className="item">
                                <div className="testimony-wrap rounded text-center  py-4  shadow p-3 mb-5 bgmycss  rounded  ">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <p style={{ color: "white", fontSize: "20px" }}><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />lax curbside valet parking</p >
                                            <p>Reservation Time : 7:25:00 PM  </p>

                                            <p>Reservation Date :  2022-11-08</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="swiper-slides">
                            <div className="item">
                                <div className="testimony-wrap rounded text-center  py-4  shadow p-3 mb-5 bgmycss  rounded  ">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <p style={{ color: "white", fontSize: "20px" }}><img src="/assets/images/person_1.jpg" alt="..." className="rounded-circle" width="60px" height="60px" />lax curbside valet parking</p >
                                            <p>Reservation Time : 7:25:00 PM  </p>

                                            <p>Reservation Date :  2022-11-08</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide> */}

            {/* old work */}

            {/* <SwiperSlide className="swiper-slides">
              <div className="item">
                <div className="testimony-wrap rounded text-center py-4 pb-5 shadow p-3 mb-5 bg-white rounded">
                  <span style={{ color: "black" }}>Contrary to popular belief
                  </span><br />

                  <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />

                  <div className="row">
                    <div className="col-md-12">

                      <p style={{ color: "black", fontSize: "20px" }}><img src="/assets/images/Image 46.png" width="70px" height="70px" alt="..." className="rounded-circle" />Name Of Service</p >
                    </div>
                  </div>

                </div>
              </div></SwiperSlide>

            <SwiperSlide className="swiper-slides">
              <div className="item">
                <div className="testimony-wrap text-center rounded  py-4 pb-5 shadow p-3 mb-5 bg-white rounded">
                  <span style={{ color: "black" }}>Contrary to popular belief
                  </span><br />

                  <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />

                  <div className="row">
                    <div className="col-md-12">

                      <p style={{ color: "black", fontSize: "20px" }}><img src="/assets/images/Image 46.png" width="70px" height="70px" alt="..." className="rounded-circle" />Name Of Service</p >
                    </div>
                  </div>

                </div>
              </div></SwiperSlide>
            <SwiperSlide className="swiper-slides">
              <div className="item">
                <div className="testimony-wrap text-center rounded text-center  py-4 pb-5 shadow p-3 mb-5 bg-white rounded">
                  <span style={{ color: "black" }}>Contrary to popular belief
                  </span><br />

                  <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />

                  <div className="row">
                    <div className="col-md-12">

                      <p style={{ color: "black", fontSize: "20px" }}><img src="/assets/images/Image 46.png" width="70px" height="70px" alt="..." className="rounded-circle" />Name Of Service</p >
                    </div>
                  </div>

                </div>
              </div></SwiperSlide>

            <SwiperSlide className="swiper-slides">
              <div className="item">
                <div className="testimony-wrap text-center rounded  py-4 pb-5 shadow p-3 mb-5 bg-white rounded">
                  <span style={{ color: "black" }}>Contrary to popular belief
                  </span><br />

                  <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />

                  <div className="row">
                    <div className="col-md-12">

                      <p style={{ color: "black", fontSize: "20px" }}><img src="/assets/images/Image 46.png" width="70px" height="70px" alt="..." className="rounded-circle" />Name Of Service</p >
                    </div>
                  </div>

                </div>
              </div></SwiperSlide>

            <SwiperSlide className="swiper-slides">
              <div className="item ">
                <div className="testimony-wrap text-center rounded  py-4 pb-5 shadow p-3 mb-5 bg-white rounded">
                  <span style={{ color: "black" }}>Contrary to popular belief
                  </span><br />

                  <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />

                  <div className="row">
                    <div className="col-md-12">

                      <p style={{ color: "black", fontSize: "20px" }}><img src="/assets/images/Image 46.png" width="70px" height="70px" alt="..." className="rounded-circle" />Name Of Service</p >
                    </div>
                  </div>

                </div>
              </div></SwiperSlide> */}

          </Swiper>
          </div>
        </div>
      </section>


    </>
  )
}