//Action Types
export const FORM = "FORM";
export const AMOUNT = "AMOUNT";
export const auth = "AUTH";

//Action Creator
export const updateForm = (data) => {
    console.log("data redux", data)
    return {

        type: FORM,
        payload: data
    }
};

export const AmountData = (data) => ({
    type: AMOUNT,
    payload: data
});