import React from "react";
import "./sidbar2.css"
import { Link } from 'react-router-dom';

export default function RightSidbar2() {
  const logout = () => {
    localStorage.clear()
  }
	const Token = localStorage.getItem("Token")

  return (
    <div className="row">
      <div className="col-md-12">
      <div className="modal fade drawer right-align modalcss" id="exampleModalRight" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"   >
      <div className="modal-dialog" role="document" >
        {/* <div className="modal-content" style={{ backgroundImage: "url('/assets/images/sidbarbg.png')" }}> */}
        <div className="modal-content" >

          {/* <img src="/assets/images /Group 603.png"/> */}
          <div className="modal-header" >
            {/* <h5 className="modal-title" id="exampleModalLabel">Right Align Modal title</h5> */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true" className="text-white">×</span>
            </button>
          </div>
          <div className="mt-4"  style={{overFlowY:"scroll"}}>
            <div className="drawer">
              <ul className="mt-4">
                <li ><Link to="/Register" className="lis" >Registratio</Link></li>
            {Token == null ?   <li ><Link className="lis" to="/Login">Login</Link></li>: ""}  

               {Token != null ? <li ><Link className="lis" to="/Profile">Profile</Link></li>:"" } 
                {/* <li ><Link className="lis" to="#">Notification</Link></li>git */}
                {/* <li ><Link className="lis" to="/Setting">Setting</Link></li> */}
                {/* <li ><Link className="lis" to="#">Rating & Review</Link></li> */}
                <li ><Link className="lis" to="/Login" onClick={() => logout()}>Logout</Link></li>


              </ul>
            </div>
          </div>
          {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div> */}
        </div>
      </div>
    </div>
      </div>
    </div>
    
    
  )
}