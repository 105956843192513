import React, { useEffect, useState } from 'react'
import Header3 from "../../layout/Header3"
import Footer from "../../layout/Footer"
import Slidar3 from "../slider3"
import "./payment.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import baseUrl from '../../../baseurl/BaseUrl';
import { useSelector } from 'react-redux';
import { Loader } from "react-overlay-loader";
import StripeCheckout from 'react-stripe-checkout';
import { SliderData } from '../../utils/sliderData'
import { updateForm, AmountData } from '../../../redux/actions/counterActions';
import { useDispatch } from 'react-redux';
import { height } from '@mui/system'
// import { SliderData } from '../../utils/sliderData';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



export default function Payment() {
  const location = useLocation();
  // pdf key 
  const [services, setservices] = useState([]);
  const [Ids, setIds] = useState([]);
  const [item, setitem] = useState([])
  const [addit, setAddit] = useState([])
  const [totalrs, setTotalrs] = useState([])






  const Con = (amount) => {
    return Number(amount).toFixed(2)
  }

  const Consubamount = (amount) => {
    return Number(amount).toFixed(2)
  }
  const Token = localStorage.getItem("Token")
  const [loader, setLoader] = useState(false);
  const store = useSelector(store => store)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [AmountObj, setAmountObj] = useState({})
  const [citytax, setCitytax] = useState(store.counter.amount.citytax)
  const [totalamount, setTotalamount] = useState(store.counter.amount.totalamount)
  const [subtotal, setSubtotal] = useState(store.counter.amount.subtotal)
  const [parking, setParking] = useState(store.counter.amount.parking)
  const [cardcharge, setCardcharge] = useState(store.counter.amount.cardcharge)
  const [transectionfee, setTransectionfee] = useState(store.counter.amount.transectionfee)
  const [discount, setDiscount] = useState(store.counter.amount.state.discount)

  const [pdfcontent, setPdfcontent] = useState(store.counter.form.pdfcontent)
  console.log("payment location", location.state.pdfcontent, pdfcontent, store.counter.form.pdfcontent)

  //modal work 
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [show, setShow] = useState(false);

  const [active1, setActive1] = useState(false);
  const [evamount, setEvamount] = useState(store.counter.amount.evcharge);
  const [gasamount, setGasamount] = useState(store.counter.amount.gasamount);
  const [paymentid, setPaymentid] = useState("");

  const [ModalData, setModalData] = useState({});
  const [addiev, setAddiev] = useState("");
  const [addigas, setAddigas] = useState("");


  const Guestlogin = localStorage.getItem("guest")


  // const reduxFormData1 = store.counter.form.state
  const reduxFormData2 = store.counter.form
  console.log("form data counter", store.counter.form.state)
  console.log("form data amount", store.counter.amount.state)
  console.log("form data check", store.counter.amount.state)


  useEffect(() => {
    const filtered = location.state.services.filter((ss) => ss.id == location.state.selectedId)
    console.log(" filtered", filtered)

    let filteredArr = []
    if (filtered.length > 0) {
      if (filtered[0].category_name === "Additional services") {
        const filtered2 = location.state.services.filter((ss) => ss.id !== location.state.selectedId)
        setservices(filtered2)
        console.log("filtered2", filtered2)
        filteredArr = filtered2
      } else {
        setservices(location.state.services)
      }
    } else {
      setservices(location.state.services)
    }
    if (location.state.selectedId !== null) {
      if (filtered.length > 0) {
        if (filtered[0].category_name !== "Additional services") {
          setIds(Ids => [...Ids, location.state.selectedId])
        }
      }
    }

    setAmountObj(store.counter.amount.state)
    // setFormObj(store.counter.form.state)

    // if (filteredArr.length === 0) {
    //   const newState = location.state.services.map(obj => {
    //     // 👇️ if id equals 2, update country property
    //     if (obj.id === location.state.selectedId) {
    //       return { ...obj, selectedId: location.state.selectedId };
    //       // }
    //     }
    //     // 👇️ otherwise return object as is
    //     return obj;
    //   });
    //   setservices(newState);
    // }

  }, [])

  const onToken = (token) => {
    PaymentExecute(token.id, "stripe")
  }







  const handlePayPal = () => {
    // dispatch(updateForm({ ...reduxFormData2, method: "paypal", totalamount: totalamount, evamount: evamount, gasamount: gasamount, parking: parking, cardcharge: cardcharge, transectionfee: transectionfee, }))
    setLoader(true);
    const formData = new FormData();
    // formData.append("amount", store.counter.amount.state.totalamout);
    formData.append("amount", (Con(totalamount)));
    formData.append("return_url", `${window.location.origin}/PaymentResponse`);
    formData.append("cancel_url", `${window.location.origin}/PaymentFailed`);


    var requestOptions = {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + Token
      },
      body: formData,
    };
    fetch(`${baseUrl.baseUrl}webapi/paypalPayCreation?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setLoader(false);
        console.log("pdfcontent", pdfcontent)
        dispatch(updateForm({ ...reduxFormData2, method: "paypal", totalamount: totalamount, evamount: evamount, gasamount: gasamount, parking: parking, cardcharge: cardcharge, transectionfee: transectionfee }))
        if (result.status === true) {
          setTimeout(() => {

            window.location.href = result.data
          }, 3000);
        } else if (result.message === "Unauthorized") {
          return window.location.href = "https://valet-userside.vercel.app/login"
        } else {

          Swal.fire({
            title: "Oops",
            text: result.message,
            icon: "error",
            confirmButtonColor: "#03bafe",
          });
        }
      }).catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: "#03bafe",
        })
      });
  }

  const paymentStripe = () => {
    let a = document.getElementById('paymentmethod')
    a.children[0].click()
  }

  // STRIPE 
  const PaymentExecute = (token, method) => {
    setLoader(true);
    const formData = new FormData();
    if (store.counter.amount.state.promo_id) {

      formData.append("promo_code", store.counter.amount.state.promo_id);
    }
    formData.append("waiver_form", location.state.pdfcontent);

    formData.append("startdate", store.counter.amount.state.startdate);
    formData.append("enddate", store.counter.amount.state.enddate);
    formData.append("starttime", store.counter.form.state.departure_time + ":00");
    formData.append("endtime", store.counter.form.state.return_time + ":00");
    formData.append("totalamout", totalamount);
    formData.append("name", store.counter.form.state.fnames);
    formData.append("lastname", store.counter.form.state.lnames);
    formData.append("countrycode", store.counter.form.state.countrycode);
    formData.append("phone", store.counter.form.state.phonenumber);

    if (store.counter.form.state.alphonenumber != "") {

      formData.append("alternatephone", store.counter.form.state.alphonenumber);
    }
    formData.append("vehicleColor", store.counter.form.state.vehicleColor);
    formData.append("vehicleMake", store.counter.form.state.vehicleMake);
    formData.append("vehicleModel", store.counter.form.state.vehicleModel);
    formData.append("licensePlate", store.counter.form.state.licensePlate);
    formData.append("flighNumber", store.counter.form.state.flightno);
    formData.append("departure_airline", store.counter.form.state.departure_airline);
    formData.append("departure_airline_time", store.counter.form.state.departure_time + ":00");
    formData.append("return_airline", store.counter.form.state.return_airline);
    formData.append("return_airline_time", store.counter.form.state.return_time + ":00");
    formData.append("customerpay", token);
    formData.append("customer", store.counter.amount.state.id);
    formData.append("service", store.counter.amount.state.serviceId);
    formData.append("email", store.counter.form.state.email);
    formData.append("paytype", method);
    formData.append("additional", JSON.stringify(store.counter.amount.additionalids));
    formData.append("media", JSON.stringify(store.counter.form.state.S3images));
    formData.append("evcharge", evamount);
    formData.append("gas", gasamount);
    formData.append("parking", parking);
    formData.append("transaction_fee", transectionfee);
    formData.append("credit_card_fee", cardcharge)


    var requestOptions;

    if (Token !== null && Guestlogin == null) {
      requestOptions = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + Token
        },
        body: formData,
      };
    } else if (Token == null && Guestlogin == "guest") {
      requestOptions = {
        method: 'POST',
        // headers: {
        //   Authorization: "Bearer " + Token
        // },
        body: formData,
      };
    }

    if (Token !== null && Guestlogin === null) {
      fetch(`${baseUrl.baseUrl}webapi/customerbooking?role=customer`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);
          if (result.status === false) {
            navigate("/PaymentFailed")
          } else {
            dispatch(updateForm({ ...reduxFormData2, payslip: result.data.payslip }))

            Swal.fire({
              title: "success",
              text: result.message,
              icon: "success",
              confirmButtonColor: "#03bafe",
            }).then(() => {
              // window.location.href = "/"
              // navigate("/PaymentResponse?status=true")

              navigate('/PaymentResponse?status=true', { state: { totalamount: totalamount } });
            })

          }
        }).catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });
    } else if (Token == null && Guestlogin === "guest") {
      fetch(`${baseUrl.baseUrl}webapi/guest_customerbooking`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);
          if (result.status === false) {
            navigate("/PaymentFailed")
          } else {
            dispatch(updateForm({ ...reduxFormData2, payslip: result.data.payslip }))

            Swal.fire({
              title: "success",
              text: result.message,
              icon: "success",
              confirmButtonColor: "#03bafe",
            }).then(() => {
              // window.location.href = "/"
              // navigate("/PaymentResponse?status=true")

              navigate('/PaymentResponse?status=true', { state: { totalamount: totalamount } });
            })

          }
        }).catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });


    }
  }


  // PAY WITH CASH

  const PaymentwithCah = (token, method) => {
    setLoader(true);
    const formData = new FormData();
    if (store.counter.amount.state.promo_id) {
      console.log("store.counter.amount.state.promo_id", store.counter.amount.state.promo_id, location.state.pdfcontent)

      formData.append("promo_code", store.counter.amount.state.promo_id);
    }
    formData.append("waiver_form", location.state.pdfcontent);

    formData.append("startdate", store.counter.amount.state.startdate);
    formData.append("enddate", store.counter.amount.state.enddate);
    formData.append("starttime", store.counter.form.state.departure_time + ":00");
    formData.append("endtime", store.counter.form.state.return_time + ":00");
    formData.append("totalamout", totalamount);
    formData.append("name", store.counter.form.state.fnames);
    formData.append("lastname", store.counter.form.state.lnames);
    formData.append("countrycode", store.counter.form.state.countrycode);
    formData.append("phone", store.counter.form.state.phonenumber);
    if (store.counter.form.state.alphonenumber != "") {

      formData.append("alternatephone", store.counter.form.state.alphonenumber);
    }
    formData.append("vehicleColor", store.counter.form.state.vehicleColor);
    formData.append("vehicleMake", store.counter.form.state.vehicleMake);
    formData.append("vehicleModel", store.counter.form.state.vehicleModel);
    formData.append("licensePlate", store.counter.form.state.licensePlate);
    formData.append("flighNumber", store.counter.form.state.flightno);
    formData.append("departure_airline", store.counter.form.state.departure_airline);
    formData.append("departure_airline_time", store.counter.form.state.departure_time + ":00");
    formData.append("return_airline", store.counter.form.state.return_airline);
    formData.append("return_airline_time", store.counter.form.state.return_time + ":00");
    formData.append("customerpay", token);
    formData.append("customer", store.counter.amount.state.id);
    formData.append("service", store.counter.amount.state.serviceId);
    formData.append("email", store.counter.form.state.email);
    formData.append("paytype", method);
    formData.append("additional", JSON.stringify(store.counter.amount.additionalids));
    formData.append("media", JSON.stringify(store.counter.form.state.S3images));
    formData.append("evcharge", evamount);
    formData.append("gas", gasamount);
    formData.append("parking", parking);
    formData.append("transaction_fee", transectionfee);
    formData.append("credit_card_fee", cardcharge);


    var requestOptions;

    if (Token !== null && Guestlogin == null) {
      requestOptions = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + Token
        },
        body: formData,
      };
    } else if (Token == null && Guestlogin == "guest") {
      requestOptions = {
        method: 'POST',
        // headers: {
        //   Authorization: "Bearer " + Token
        // },
        body: formData,
      };
    }
    if (Token !== null && Guestlogin === null) {


      fetch(`${baseUrl.baseUrl}webapi/customerbooking?role=customer`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);
          if (result.status === false) {
            navigate("/PaymentFailed")
          } else {
            dispatch(updateForm({ ...reduxFormData2, payslip: result.data.payslip }))

            console.log("pay with cash", result.data)
            Swal.fire({
              title: "success",
              text: result.message,
              icon: "success",
              confirmButtonColor: "#03bafe",
            }).then(() => {
              // window.location.href = "/"
              // navigate("/PaymentResponse?status=true")

              navigate('/PaymentResponse?status=true', { state: { totalamount: totalamount } });
            })

          }
        }).catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });
    } else if (Token == null && Guestlogin === "guest") {

      fetch(`${baseUrl.baseUrl}webapi/guest_customerbooking`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);
          if (result.status === false) {
            navigate("/PaymentFailed")
          } else {
            dispatch(updateForm({ ...reduxFormData2, payslip: result.data.payslip }))

            console.log("pay with cash", result.data)

            Swal.fire({
              title: "success",
              text: result.message,
              icon: "success",
              confirmButtonColor: "#03bafe",
            }).then(() => {
              // window.location.href = "/"
              // navigate("/PaymentResponse?status=true")

              navigate('/PaymentResponse?status=true', { state: { totalamount: totalamount } });
            })

          }
        }).catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: "#03bafe",
          })
        });

    }
  }








  return (
    <div>
      {loader ? <Loader fullPage loading /> : null}
      <Header3 />
      <Slidar3 />
      <section className="ftco-section">
        <div className="container mt-4">
          <div className="row text-center ">
            <div className='col-md-12'>
              <div className=" text-center heading-section ">
                <h2 className="title">PAYMENT METHOD</h2>
              </div>
              <div className="row text-center justify-content-center">

                <div className='col-md-10'>
                  <div className="card paymentMethodCard px-4" style={{padding:"25px 30px 25px 30px"}}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p>
                            <b>PACKAGES</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>
                            <b>TOTAL</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p><strong>SUBTOTAL AMOUNT </strong></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>   <strong>${Consubamount(subtotal)} </strong></p>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p>PARKING </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>${parking} </p>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p>CITY TAX </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">


                          <p>${citytax}</p>
                          {/* <p>${totalrs.length > 0 ? totalrs.citytax : store.counter.amount.state.citytax } </p> */}

                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p>TRANSACTION FEE </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>${transectionfee}</p>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}



                    {/* 
                    {item.map((e) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="text-left">
                                <p>{e.name.toUpperCase()}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="text-right">
                                <p>${e.price}</p>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )
                    })


                    } */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p>CREDIT CARD FEES </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>{cardcharge}%</p>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <p>DISCOUNT </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>${Con(discount)}</p>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}


                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-left">
                          <strong>TOTAL AMOUNT</strong>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-right">
                          <p>
                            <b>${Con(totalamount)}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                  </div>
                </div>

                <div className='col-md-10'>
                  <article className="">
                    <div className="card-body">
                      <div className='row justify-content-center'>

                        <h4 className='mb-5'>Choose Payment Method Below</h4>
                        <div className='col-md-4 ' >
                          <article className=" hov " style={{ marginBottom: "75px" }}>
                            <div className="card-body">
                              <div className="type" onClick={() => paymentStripe()}>
                                <div className="logo paystripe">
                                  <i className="fa fa-credit-card" style={{ fontSize: "75px" }} />
                                </div>
                                <div className="text" >
                                  <p>Pay with Credit Card</p>
                                  <div id='paymentmethod' className='d-none'>
                                    <StripeCheckout
                                      token={onToken}
                                      stripeKey="pk_test_hWK9CCVSwmaNFT7tlIbxHo5s00GntIdMdu"
                                      name='payment'
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>
                          </article>
                        </div>
                        {/* <div className='col-md-4' >
                          <article className=" hov " >
                            <div className="card-body">

                              <div className="type" onClick={() => handlePayPal()}>
                                <div className="logo paypal">
                                  <i className="fa fa-paypal" />
                                </div>
                                <div className="text"  >
                                  <p>Pay with PayPal</p>
                                </div>
                              </div>

                            </div>
                          </article>

                        </div> */}

                        <div className='col-md-4' >
                          <article className=" hov" style={{ marginBottom: "75px" }}>
                            <div className="card-body">

                              <div className="type" onClick={() => PaymentwithCah("-", "cashcounter")}>
                                <div className="logo paypal">
                                  <i className="fa 	fa fa-money" style={{ fontSize: "75px" }} />
                                </div>
                                <div className="text"  >
                                  <p>Pay with Cash</p>
                                </div>
                              </div>

                            </div>
                          </article>

                        </div>

                      </div>



                    </div>
                  </article>
                </div>

              </div>




            </div>
          </div>

        </div>
      </section>
      <Footer />



    </div>
  )
}
