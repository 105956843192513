import React from "react";
import "./toggle.css";

export default function ReserveParking() {

      return (
            <section style={{ backgroundImage: "url('/assets/images/Group 603.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover", marginTop: "30px" }}>
                  <div className="text-center">
                        <h2 className="text-center" style={{ backgroundColor: "rgba(3, 156, 213)", color: "white" }}>Reserved Your Parking Now</h2>
                  </div>
                  <div className="container mt-4">
                        <img src="/assets/images/Group 29.png" width="90%" alt=""/>
                        <div className="row d-flex mb-5 mt-3 contact-info">
                              <div className="col-md-4">
                                    <input type="text" className="form-control rounded input-lg" placeholder=" Name" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-4">
                                    <input type="number" className="form-control rounded input-lg" placeholder=" Phone Number" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-4">
                                    <input type="email" className="form-control rounded input-lg" placeholder="Email" style={{ height: "135%" }} />
                              </div>
                        </div>
                        <div className="row d-flex mb-5 contact-info">
                              <div className="col-md-3">
                                    <input type="text" className="form-control rounded input-lg" placeholder="Vehcle Color" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-3">
                                    <input type="text" className="form-control rounded input-lg" placeholder="Make" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-3">
                                    <input type="text" className="form-control rounded input-lg" placeholder="Modal" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-3">
                                    <input type="text" className="form-control rounded input-lg" placeholder="License Plate" style={{ height: "135%" }} />
                              </div>
                        </div>
                        <div className="row d-flex mb-5 contact-info">
                              <div className="col-md-4">
                                    <input type="text" className="form-control rounded input-lg" placeholder="Departing Airlines" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-4">
                                    <input type="text" className="form-control rounded input-lg" placeholder="Return Airlines" style={{ height: "135%" }} />
                              </div>
                              <div className="col-md-4">
                                    <input type="text" className="form-control rounded input-lg" placeholder="Pick car from Home or Terminal" style={{ height: "135%" }} />
                              </div>
                        </div>
                        <div className="content">
                              <label className="switch">
                                    <input type="checkbox" />
                                    <span className="toggle round"></span>
                              </label>
                        </div>
                  </div>
            </section>
      )
}