import React, { useState, useEffect } from 'react'
import { Loader } from 'react-overlay-loader'
// import Header from '../../layout/Header2'
import Header3 from '../../layout/Header3'
// import Slidar from '../slider2'
import Slidar3 from '../slider3'
import $ from 'jquery'

import './contactUs.css'
import baseUrl from '../../../baseurl/BaseUrl'
import Swal from "sweetalert2";
import Footer from "../../layout/Footer"
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

export default function ContactUs() {
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false);


  const [fname, setFname] = useState("")
  const [profile, setProfile] = useState("")


  const Token = localStorage.getItem("Token")
  useEffect(() => {
    GetServices2()

  }, [])


  // GETsERVICES
  const GetServices2 = () => {
    setLoader(true);
    var requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + Token
      }
    };
    fetch(`${baseUrl.baseUrl}webapi/profile?role=customer`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("result in home3", result)
        setLoader(false);
        if (result.status && result.status != "False") {
          console.log("result.data", result.data)
          setFname(result.data.fname)
          setProfile(result.data.profile)




        }
        // else {
        //     setLoader(false)
        //     Swal.fire({
        //         title: "opps",
        //         text: result.message,
        //         icon: "error"
        //     })



        // }
      }).catch(function (e) {
        setLoader(false)
        Swal.fire({
          title: "opps!",
          text: e.message,
          icon: "error"
        })
      })

  }




  $(".modal-backdrop").hide()
  $("body").removeClass("modal-open")

  return (
    <div>
      {loader == true ? <Loader fullPage loading /> : null}
      {

        Token == null ?

          <div className='bluediv'  >
            <div className="bs-example px-3 py-3">



              <div className="clearfix fss">
                <Button onClick={() => navigate("/Register")} className='  float-right ml-2 ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                  Register Now
                </Button>{' '}
                <Button onClick={() => navigate("/Login")} className='float-right ' variant="outline-dark" style={{ width: "151px", color: "#32A9E0", fontWeight: "400px", borderRadius: "219px", background: " #FFFFFF", boxShadow: "0px 14px 50px rgba(0, 0, 0, 0.15);" }} size="sm">
                  Sign In
                </Button>{' '}
              </div>


            </div>
          </div>

          :
          <div className='bluediv' style={{ height: "64px" }} >
            <div className="bs-example px-3 py-3">



              <div className="clearfix fss">

                <h3 className='float-right  mx-5 ' >
                  <img className="rounded-circle" id="profileid" src={profile ? profile : '/assets/images/person_1.jpg'} alt="" width="40px" height="40px" />
                </h3>
                <h3 className='float-right ml-2 welcomename' style={{ color: "#FFFFFF" }} >Welcome {fname}</h3>




              </div>


            </div>
          </div>

      }
      <Header3 />
      <Slidar3 />

      <section className="ftco-section">
        <div className="container mt-4">
          <div className=" text-center heading-section mb-5 ">
            <h2 className="title">Contact Us</h2>
          </div>
          <div className="row ">
            <div className='col-md-6 text-center'>
              <img src={'/assets/images/contactimg.jpg'} className='img-fluid' style={{ height: "400px", width: "100%" }} alt={""} />
              {/* <h5 className='mt-2'>{ data.fname +" "+  data.lname}</h5>  */}
              {/* <h6>{fname+" "+  lname}</h6> */}
              {/* <h6>{data.email? data.email:""}</h6> */}

            </div>
            {/* <div className='col-md-3' >

            </div> */}
            <div className='col-md-6 '>
              <form action="#" className=" p-3 contact-form">

                <div className="row">
                  <div className="col-md-12 " >
                    <div className='d-flex '>
                      <p className='mx-2' style={{ fontSize: "20px" }}>Phone Number :</p><h5 ><a href="tel:+1 (310) 216-6110" style={{ fontSize: "22px", color: "black" }}>+1 (310) 216-6110</a></h5>
                    </div>
                    <div className='d-flex '>

                      <p className='mx-2' style={{ fontSize: "20px" }}>Phone Number :</p><h5 ><a href="tel:+1 (800) 328-2538" style={{ fontSize: "22px", color: "black" }}>+1 (800) 328-2538</a></h5>
                    </div>
                    <div className='d-flex '>
                      <p className='mx-2' style={{ fontSize: "20px" }}>Text Number : </p><h5 > <a style={{ fontSize: "23px", color: "black" }} href="sms:+1(310 800-1075)">+1(310 800-1075)</a></h5>
                    </div>
                    <div className='d-flex '>
                      <p className='mx-2' style={{ fontSize: "20px" }}>Email : </p><h5 > <a style={{ fontSize: "23px", color: "black" }} href="mailto:Contact@laxairportvaletservices.com">Contact@laxairportvaletservices.com</a></h5></div>

                  </div>


                </div>




              </form>
            </div>

          </div>



        </div>
      </section>
      <div style={{ width: "100%" }}>
        <iframe style={{ width: "100%", height: "400px", frameborder: "0" }} src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=8921%20south%20Sepulveda%20blvd,%20Los%20Angeles,California%2090045+(valet%20services)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe>
      </div>
      <Footer />
    </div>
  )
}
