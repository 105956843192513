
import rootReducer from "./reducers/rootReducers";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: 'root',
  whiteList: ["counter", "form", "amount", "pdfcontent"],
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)